import { useTheme } from 'react-native-paper';
import DropDownPaper from "react-native-paper-dropdown";


/**
 * Creates a button object
 *
 * @mode {string} Type: 'text' | 'outlined' | 'contained' | 'elevated' | 'contained-tonal'
 * @buttonColor {string} 
 */
function DropDown({ 
  label,
  mode = 'outlined',
  visible = true,
  multiSelect = false,
  value, 
  setValue,
  list,
  showDropDown,
  onDismiss,
 }) {
  const theme = useTheme();

  return (
    <DropDownPaper
      label={label}
      mode={mode}
      visible={visible}
      showDropDown={showDropDown}
      onDismiss={onDismiss}
      value={value}
      setValue={setValue}
      list={list}
      multiSelect={multiSelect}
      inputProps={{
        style: {
          marginBottom: 10
        }
      }}
      dropDownItemTextStyle={{
        color: theme.colors.onSurface,
      }}
      dropDownItemSelectedTextStyle={{
        color: theme.colors.primary,
      }}
    />
  );
}

export default DropDown;