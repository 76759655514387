import { createContext, useEffect, useState } from 'react';
import { getData, storeData } from './async-storage';
import { Platform } from 'react-native';
import * as Notifications from 'expo-notifications';
import Moment from 'react-moment';
import moment from 'moment'
import * as Sentry from 'sentry-expo';

export const NavigationContext = createContext({
  reportScreenRecords: [],
  setReportScreenRecords: (records) => {},
  getReportScreenRecords: (date, record_id) => {},
  updateReportScreenRecords: (date, record_id, record) => {},
});

function NavigationContextProvider({ children }) {
  const [reportScreenRecords, setReportScreenRecords] = useState([]);

  async function getReportScreenRecords(date, record_id) {
    //console.log(reportScreenRecords)
    const record = reportScreenRecords.filter(r => r[0] == date)[0][1].filter(r => r.record_id == record_id)
    return record
  }

  async function updateReportScreenRecords(date, record_id, record) {
    //In reportScreenRecords replace the filtered record by the record by parameter
    const newRecords = reportScreenRecords.map(r => {
      if (r[0] == date) {
        return [r[0], r[1].map(rr => rr.record_id == record_id ? record : rr)]
      } else {
        return r
      }
    })

    setReportScreenRecords(newRecords)
  }


  const value = {
    reportScreenRecords: reportScreenRecords,
    setReportScreenRecords: setReportScreenRecords,
    getReportScreenRecords: getReportScreenRecords,
    updateReportScreenRecords: updateReportScreenRecords
  };

  return <NavigationContext.Provider value={value}>{children}</NavigationContext.Provider>;
}

export default NavigationContextProvider;
