import { useContext, useEffect, useState, useCallback } from 'react';
import { View, Platform } from 'react-native';
import { MD3DarkTheme, MD3LightTheme, Provider as PaperProvider, useTheme } from 'react-native-paper';

import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as Linking from 'expo-linking';
import { RootSiblingParent } from 'react-native-root-siblings';

import { Ionicons } from '@expo/vector-icons';
import { StatusBar } from 'expo-status-bar';
import { en, registerTranslation } from 'react-native-paper-dates';

import { Amplify } from 'aws-amplify';
import IconButton from './components/ui/IconButton';
import { Colors } from './constants/styles';
import ChangePasswordScreen from './screens/ChangePasswordScreen';
import CodeScreen from './screens/CodeScreen';
import CompanySettingsScreen from './screens/CompanySettingsScreen';
import SubscriptionScreen from './screens/SubscriptionScreen';
import ForgotPasswordConfirmScreen from './screens/ForgotPasswordConfirmScreen';
import ForgotPasswordScreen from './screens/ForgotPasswordScreen';
import HomeScreen from './screens/HomeScreen';
import MoreScreen from './screens/MoreScreen';
import OnboardingScreen from './screens/OnboardingScreen';
import ProfileScreen from './screens/ProfileScreen';
import SelfieScreen from './screens/SelfieScreen';
import ReportScreen from './screens/report/ReportScreen';
import ReportDetailScreen from './screens/report/ReportDetailScreen';
import ReportEditScreen from './screens/report/ReportEditScreen';
import SignInScreen from './screens/SignInScreen';
import SignUpScreen from './screens/SignUpScreen';
import DepartmentListScreen from './screens/department/DepartmentListScreen';
import DepartmentScreen from './screens/department/DepartmentScreen';
import KioskListScreen from './screens/kiosk/KioskListScreen';
import KioskScreen from './screens/kiosk/KioskScreen';
import EmployeeListScreen from './screens/employee/EmployeeListScreen';
import EmployeeScreen from './screens/employee/EmployeeScreen';
import LocationListScreen from './screens/location/LocationListScreen';
import LocationScreen from './screens/location/LocationScreen';
import PositionListScreen from './screens/position/PositionListScreen';
import PositionScreen from './screens/position/PositionScreen';
import ShiftListScreen from './screens/shift/ShiftListScreen';
import ShiftScreen from './screens/shift/ShiftScreen';
import NotificationScreen from './screens/NotificationScreen';
import RequestScreen from  './screens/RequestScreen';
import SplashScreen from './screens/SplashScreen';
import WelcomeScreen from './screens/WelcomeScreen';


import AuthContextProvider, { AuthContext } from './store/auth-context';
import CompanyContextProvider from './store/company-context';
import UserContextProvider from './store/user-context';
import NavigationContextProvider from './store/navigation-context';
import { ThemeContext } from './store/theme-context';

import { getData, storeData } from './store/async-storage';
import Constants from 'expo-constants';

import * as Sentry from 'sentry-expo';

Sentry.init({
  dsn: 'https://88534151fc66629b5b7aec9ea4c75d60@o4505459667042304.ingest.sentry.io/4505761570684928',
  enableInExpoDevelopment: true,
  debug: false, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
});

registerTranslation('en', en)

// Assuming you have two redirect URIs, and the first is for localhost and second is for production
const [
  localRedirectSignIn,
  productionRedirectSignIn,
  webRedirectSignIn,
] = Constants.expoConfig.extra.amplify.Auth.oauth.redirectSignIn.split(",");

const [
  localRedirectSignOut,
  productionRedirectSignOut,
  webRedirectSignOut,
] = Constants.expoConfig.extra.amplify.Auth.oauth.redirectSignOut.split(",");

const updatedConfig = {
  ...Constants.expoConfig.extra.amplify,
  Auth: {
    ...Constants.expoConfig.extra.amplify.Auth,
    oauth: {
      ...Constants.expoConfig.extra.amplify.Auth.oauth,
      redirectSignIn: Platform.OS === 'web' ? webRedirectSignIn : Constants.expoConfig.extra.environment === 'production' ? productionRedirectSignIn : localRedirectSignIn,
      redirectSignOut: Platform.OS === 'web' ? webRedirectSignOut : Constants.expoConfig.extra.environment === 'production' ? productionRedirectSignOut : localRedirectSignOut,
    }
  }
}

//console.log(updatedConfig)

try {
  Amplify.configure({ ...updatedConfig });
} catch (error) {
  Sentry.Native.captureException(error);
}


const lightTheme = {
  ...MD3LightTheme, 
  colors: {
    ...MD3LightTheme.colors,
    primary: Colors.cyan['800'],
    secondary: Colors.pink['600'],
    tertiary: Colors.cyan['800'],
    onTertiary: Colors.emerald['400'],
    background: '#FAF9F6',
    surfaceVariant: 'white',
    success: Colors.emerald['500'],
    error: Colors.red['700']
  },
};

const darkTheme = {
  mode: 'adaptive',
  ...MD3DarkTheme,
  colors: {
    ...MD3DarkTheme.colors,
    tertiary: 'rgba(49, 48, 51, 1)',
    onTertiary: 'rgb(243, 183, 190)',
    surface: 'rgba(49, 48, 51, 1)', //color between backgroudn and surfaceVariant
    success: Colors.emerald['800'],
    error: Colors.red['800']
  }
};

function UnauthenticatedStack() {
  const theme = useTheme();
  const authCtx = useContext(AuthContext);

  const Stack = createNativeStackNavigator();

  return (
    <Stack.Navigator
      screenOptions={{
        headerStyle: { backgroundColor: theme.colors.tertiary },
        headerTintColor: 'white',
        contentStyle: { backgroundColor: '#0891b2' },
        animation: 'none',
      }}
    >
      {
        authCtx.authenticationStatus === authCtx.authenticationStatusOptions.USER_NOT_CONFIRMED ?
          <Stack.Screen name="Code" component={CodeScreen} options={{ headerLargeTitleShadowVisible: false, headerTitle: "" }} />
          : authCtx.authenticationStatus === authCtx.authenticationStatusOptions.ONBOARDING ?
            <Stack.Screen name="Onboarding" component={OnboardingScreen} options={{ headerShown: false }} />
            :
            <>
              <Stack.Screen name="Welcome" component={WelcomeScreen} options={{ headerShown: false }} />
              <Stack.Screen name="SignIn" component={SignInScreen} options={{ headerShown: false }} />
              <Stack.Screen name="SignUp" component={SignUpScreen} options={{ headerShown: false }} />
              <Stack.Screen name="ForgotPassword" component={ForgotPasswordScreen} options={{ headerLargeTitleShadowVisible: false, headerTitle: "" }} />
              <Stack.Screen name="ChangePassword" component={ChangePasswordScreen} options={{ headerLargeTitleShadowVisible: false, headerTitle: "" }} />
              <Stack.Screen name="ForgotPasswordConfirm" component={ForgotPasswordConfirmScreen} options={{ headerLargeTitleShadowVisible: false, headerTitle: "" }} />
            </>
      }
    </Stack.Navigator>
  );
}

function MoreStack() {
  const theme = useTheme();
  const authCtx = useContext(AuthContext);

  const Stack = createNativeStackNavigator();

  return (
    <Stack.Navigator
      screenOptions={{
        headerStyle: { backgroundColor: theme.colors.tertiary },
        headerTintColor: 'white',
      }}
    >
      <Stack.Screen name="More" component={MoreScreen}
        options={{
          headerRight: ({ tintColor }) => (
            <IconButton
              icon="exit"
              color={tintColor}
              size={24}
              onPress={authCtx.signOut}
            />
          ),
        }}
      />
      <Stack.Screen name="DepartmentList" component={DepartmentListScreen} 
        options={{
          title: "Departments",
        }}
      />
      <Stack.Screen
        name="Department"
        component={DepartmentScreen}
        options={{
          title: "",
          headerLargeTitleShadowVisible: false,           
        }}
      />
      <Stack.Screen name="PositionList" component={PositionListScreen} 
        options={{
          title: "Roles",
        }}
      />
      <Stack.Screen
        name="Position"
        component={PositionScreen}
        options={{
          title: "",
          headerLargeTitleShadowVisible: false,  
        }}
      />
      <Stack.Screen name="EmployeeList" component={EmployeeListScreen} 
        options={{
          title: "Employees",
        }}
      />
      <Stack.Screen
        name="Employee"
        component={EmployeeScreen}
        options={{
          title: "",
          headerLargeTitleShadowVisible: false,  
        }}
        
      />
      <Stack.Screen name="ShiftList" component={ShiftListScreen} 
        options={{
          title: "Shifts",
        }}
      />
      <Stack.Screen
        name="Shift"
        component={ShiftScreen}
        options={{
          title: "",
          headerLargeTitleShadowVisible: false,  
        }}
      />
      <Stack.Screen name="LocationList" component={LocationListScreen} 
        options={{
          title: "Locations",
        }}
      />
      <Stack.Screen
        name="Location"
        component={LocationScreen}
        options={{
          title: "",
          headerLargeTitleShadowVisible: false,  
        }}
      
      />
      <Stack.Screen name="KioskList" component={KioskListScreen} 
        options={{
          title: "Kiosks",
        }}
      />
      <Stack.Screen
        name="Kiosk"
        component={KioskScreen}
        options={{
          title: "",
          headerLargeTitleShadowVisible: false,  
        }}
      
      />
      

      <Stack.Screen
        name="CompanySettings"
        component={CompanySettingsScreen}
        options={{
          title: "",
          headerLargeTitleShadowVisible: false,
        }}
      />
      <Stack.Screen
        name="Subscription"
        component={SubscriptionScreen}
        options={{
          title: "",
          headerLargeTitleShadowVisible: false,
        }}
      />
    </Stack.Navigator>
  )
}

function ReportStack() {
  const theme = useTheme();
  const Stack = createNativeStackNavigator();

  return (
    <Stack.Navigator
      screenOptions={{
        headerStyle: { backgroundColor: theme.colors.tertiary },
        headerTintColor: 'white',
      }}
    >
      <Stack.Screen
        name="Report"
        component={ReportScreen}
        options={{
          headerShown: false
        }}
      />
      <Stack.Screen
        name="ReportDetail"
        component={ReportDetailScreen}
        options={{
          title: "",
          headerLargeTitleShadowVisible: false,
        }}
      />
      <Stack.Screen
        name="ReportEdit"
        component={ReportEditScreen}
        options={{
          title: "",
          headerLargeTitleShadowVisible: false,
        }}
      />
    </Stack.Navigator>
  )
}

function ProfileStack() {
  const theme = useTheme();
  const Stack = createNativeStackNavigator();

  return (
    <Stack.Navigator
      screenOptions={{
        headerStyle: { backgroundColor: theme.colors.tertiary },
        headerTintColor: 'white',
      }}
    >
      <Stack.Screen
        name="ProfileDetail"
        component={ProfileScreen}
        options={{
          headerShown: false
        }}
      />
       <Stack.Screen
        name="Selfie"
        component={SelfieScreen}
        navi
        options={{
        }}
      />
    </Stack.Navigator>
  )
}

function HomeStack() {
  const theme = useTheme();
  const Stack = createNativeStackNavigator();

  return (
    <Stack.Navigator
      screenOptions={{
        headerStyle: { backgroundColor: theme.colors.tertiary },
        headerTintColor: 'white',
      }}
    >
      <Stack.Screen
        name="Home"
        component={HomeScreen}
        options={{
          headerShown: false
        }}
      />
      <Stack.Screen
        name="Message"
        component={MessageStack}
        options={{
          title: "",
          headerLargeTitleShadowVisible: false,
        }}

      />
    </Stack.Navigator>
  )
}

function MessageStack() {
  const theme = useTheme();
  const Stack = createMaterialTopTabNavigator();

  return (
    <Stack.Navigator
      screenOptions={{
        headerTintColor: 'white',
        tabBarActiveTintColor: theme.colors.primary,
        tabBarInactiveTintColor: theme.colors.onSurface,
        tabBarStyle: {
          backgroundColor: theme.dark ? theme.colors.surface : 'white',
        }
      }}
    >
      <Stack.Screen
        name="Notifications"
        component={NotificationScreen}
        options={{
          headerShown: false
        }}
      />
      <Stack.Screen
        name="Requests"
        component={RequestScreen}
        options={{
          headerShown: false
        }}
      />
    </Stack.Navigator>
  )
}

function AuthenticatedStack() {
  const theme = useTheme();
  const authCtx = useContext(AuthContext);

  const Tab = createBottomTabNavigator();

  return (
    <Tab.Navigator
      screenOptions={{
        headerStyle: { backgroundColor: theme.colors.primary },
        headerTintColor: 'white',
        tabBarActiveTintColor: theme.colors.primary,
        tabBarInactiveTintColor: theme.colors.onSurface,
        tabBarShowLabel: false,
        height: 100,
        tabBarStyle: {
          bottom: 0,
          left: 0,
          right: 0,
          elevation: 0,
          backgroundColor: theme.dark ? theme.colors.surface : 'white',
          borderTopColor: "transparent",
        }
      }}
    >
      <Tab.Screen
        name="HomeStack"
        component={HomeStack}
        options={{
          headerShown: false,
          tabBarIcon: ({ color, size, focused }) => (
            <View style={{ alignItems: 'center', justifyContent: 'center' }}>
              <Ionicons
                name="home"
                color={color}
                size={size} />
           
            </View>
          )
        }}
      />

      <Tab.Screen
        name="ReportStack"
        component={ReportStack}
        options={{
          headerShown: false,
          tabBarIcon: ({ color, size, focused }) => (
            <View style={{ alignItems: 'center', justifyContent: 'center' }}>
              <Ionicons
                name={ "bar-chart" }
                color={color}
                size={size} 
                />
            </View>
          ),
        }}
      />

      <Tab.Screen
        name="Profile"
        component={ProfileStack}
        options={{
          headerShown: false,
          tabBarIcon: ({ color, size, focused }) => (
            <View style={{ alignItems: 'center', justifyContent: 'center' }}>
              <Ionicons
                name="person"
                color={color}
                size={size} />
           
            </View>
          )
        }}
      />
      <Tab.Screen
        name="MoreScreen"
        component={MoreStack}
        options={{
          headerShown: false,
          tabBarIcon: ({ color, size, focused }) => (
            <View style={{ alignItems: 'center', justifyContent: 'center' }}>
              <Ionicons
                name="menu"
                color={color}
                size={size} />
           
            </View>
          ),
          headerRight: ({ tintColor }) => (
            <IconButton
              icon="exit"
              color={tintColor}
              size={24}
              onPress={authCtx.signOut}
            />
          ),
        }}
      />
    </Tab.Navigator>
  );
}

function Navigation() {
  const theme = useTheme();
  const authCtx = useContext(AuthContext);
  const prefix = Linking.createURL('/');

  const linking = {
    prefixes: [prefix],
    config: {
      screens: {
        ForgotPasswordConfirm: "forgotpassword"
      }
    }
  }

  return (
    <NavigationContainer 
      linking={linking}
      theme={theme}
    >      
      {authCtx.authenticationStatus !== authCtx.authenticationStatusOptions.AUTHENTICATED && <UnauthenticatedStack />}
      {authCtx.authenticationStatus === authCtx.authenticationStatusOptions.AUTHENTICATED && <AuthenticatedStack />}
      
    </NavigationContainer>
  );
}

export default function App() {
  const [theme, setTheme] = useState("light");
  let currentTheme = theme === "dark" ? darkTheme : lightTheme

  const updateTheme = () => {
    newTheme = theme === "dark" ? "light" : "dark";
    
    setTheme(newTheme)
    storeData("theme", newTheme)
  }

  const fetchTheme = async () => {
    let theme = await getData("theme")
    if(theme) {
      setTheme(theme)
    }
  }

  useEffect(() => {
    fetchTheme();
  }, [])

  return (
    <>
      <StatusBar style="light" />

      <ThemeContext.Provider value={{ theme, updateTheme }}>
        <AuthContextProvider>
          <CompanyContextProvider>
            <UserContextProvider>
              <NavigationContextProvider>
                <PaperProvider theme={currentTheme}>
                  <RootSiblingParent>
                    {
                      Platform.OS != "ios" ?
                        <SplashScreen />
                        :
                        <></>
                    }
                    <Navigation />
                  </RootSiblingParent>
                </PaperProvider>
              </NavigationContextProvider>
            </UserContextProvider>
          </CompanyContextProvider>
        </AuthContextProvider>
      </ThemeContext.Provider>
    </>
  )
}