import { useContext, useEffect, useState } from 'react';
import { Alert } from 'react-native';
import { API, Auth } from "aws-amplify";

import OnboardingContent from '../components/Auth/OnboardingContent';
import { AuthContext } from '../store/auth-context';
import { CompanyContext } from '../store/company-context';


function OnboardingScreen() {
  const authCtx = useContext(AuthContext);
  const companyCtx = useContext(CompanyContext);


  useEffect(() => {
    authCtx.setShowSplashScreen(false)
  }, [])

  async function submitHandler({ name, surname, companyName, timeZone }) {
    try {
      const access_token = (await Auth.currentSession()).getAccessToken().getJwtToken()
      companyCtx.postOnboarding(companyName, timeZone, authCtx.user.attributes.sub, authCtx.user.attributes.email, name, surname, access_token)
        .then(() => {
          authCtx.fetchAuthUser()
        })
    } catch (error) {
      console.log(error)
      Alert.alert(
        'Authentication failed',
        'Could not create user, please check your input and try again later.'
      );
    }
  }

  return <OnboardingContent onSubmit={submitHandler} loading={companyCtx.loading} />;
}

export default OnboardingScreen;