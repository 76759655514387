import { useState } from 'react';
import { StyleSheet, View, Platform, KeyboardAvoidingView } from 'react-native';

import Button from '../ui/Button';
import Input from '../ui/Input';

function CodeForm({ onSubmit, credentialsInvalid, loading }) {
  const [enteredCode, setEnteredCode] = useState('');

  const {
    code: codeIsInvalid,
  } = credentialsInvalid;

  function updateInputValueHandler(inputType, enteredValue) {
    switch (inputType) {
      case 'code':
        setEnteredCode(enteredValue);
        break;
    }
  }

  function submitHandler() {
    onSubmit({
      code: enteredCode
    });
  }

  return (
    <KeyboardAvoidingView 
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        style={styles.container}>
      <View>
        <Input
          label="Code"
          onChangeText={updateInputValueHandler.bind(this, 'code')}
          value={enteredCode}
          keyboardType="default"
          isInvalid={codeIsInvalid}
        />
        <View style={styles.buttons}>
          <Button 
          onPress={submitHandler}
          loading={loading}
          >
            {'Confirm'}
          </Button>
        </View>
      </View>
    </KeyboardAvoidingView>
  );
}

export default CodeForm;

const styles = StyleSheet.create({
  container: {
    paddingTop: 25
  },
  buttons: {
    marginTop: 12,
  },
});
