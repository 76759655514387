import { useState } from 'react';
import { KeyboardAvoidingView, Platform, ScrollView, Text, View } from 'react-native';
import * as Animatable from 'react-native-animatable';
import { useTheme } from 'react-native-paper';

function FormContent({ children, title, titleAnimation, titleAnimationDelay = 0, formAnimation, titleContainerStyle }) {
    const theme = useTheme();
    const [hasScrolled, setHasScrolled] = useState(false);

    const onScroll = ({ nativeEvent }) => {
        const currentScrollPosition =
          Math.floor(nativeEvent?.contentOffset?.y) ?? 0;
    
        setHasScrolled(currentScrollPosition >= 100);
      };

    return (
        <ScrollView 
            bounces={false}
            // onScroll={onScroll} 
            // scrollEventThrottle={5}
            style={{
                backgroundColor: theme.colors.background,
                flex: 1,
            }}
        >
            <View
                style={{
                    backgroundColor: theme.colors.tertiary,
                    flex: 1,
                }}
            >
            <Animatable.View animation={titleAnimation} delay={titleAnimationDelay} style={{
                marginTop: '8%',
                marginBottom: '8%',
                paddingStart: '5%',
                ...titleContainerStyle,
            }}>
                <Text style={{
                    fontSize: 28,
                    fontWeight: 'bold',
                    color: theme.dark ? theme.colors.onSurface : 'white'
                }}>{title}</Text>
            </Animatable.View>

            <Animatable.View animation={formAnimation} style={{
                flex: 1,
                backgroundColor: theme.colors.background,
                borderTopRightRadius: 25,
                borderTopLeftRadius: 25,
                paddingStart: '5%',
                paddingEnd: '5%',
                paddingBottom: 25,
            }}>
                <KeyboardAvoidingView
                    behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
                    style={{
                        flex: 1,
                        paddingTop: 25,
                    }}
                    animation="fadeInUp">
                    {children}
                </KeyboardAvoidingView>

            </Animatable.View>
            </View>
        </ScrollView>
    )
}

export default FormContent;