import { useState } from 'react';
import { Alert, StyleSheet, Text, View } from 'react-native';

import { useTheme } from 'react-native-paper';
import ForgotPasswordForm from './ForgotPasswordForm';

import * as Animatable from 'react-native-animatable';


function ForgotPasswordContent({ isConfirmPassword, onSubmit }) {
  const theme = useTheme();

  const [credentialsInvalid, setCredentialsInvalid] = useState({
    email: false,
    password: false,
    confirmPassword: false,
  });

  function submitHandler(credentials) {
    let { email, password, confirmPassword } = credentials;

    email = email.trim();

    const emailIsValid = email.includes('@');
    const passwordIsValid = password.length > 6;
    const passwordsAreEqual = password === confirmPassword;
   
    if (
      (!isConfirmPassword && (!emailIsValid)) ||
      (isConfirmPassword && (!passwordIsValid || !passwordsAreEqual))
    ) {
      Alert.alert('Invalid input', 'Please check your entered credentials.');
      setCredentialsInvalid({
        email: !emailIsValid,
        password: !passwordIsValid,
        confirmPassword: !passwordIsValid || !passwordsAreEqual,
      });
      return;
    }
    onSubmit({ email, password });
  }

  return (
    <View style={{
      backgroundColor: theme.colors.tertiary,
      ...styles.authContent}}>
      <Animatable.View animation="fadeInLeft" delay={500} style={styles.containerHeader}>
        <Text style={styles.message}>Forgot Password</Text>
      </Animatable.View>

    <Animatable.View animation="fadeInUp" style={{ backgroundColor: theme.colors.background, ...styles.containerForm }}>
        <ForgotPasswordForm
          isConfirmPassword={isConfirmPassword}
          onSubmit={submitHandler}
          credentialsInvalid={credentialsInvalid}
        />
      </Animatable.View>
    </View>
  );
}

export default ForgotPasswordContent;

const styles = StyleSheet.create({
  authContent: {
    flex: 1,
  },
  containerHeader: {
    marginTop: '14%',
    marginBottom: '8%',
    paddingStart: '5%'
  },
  message: {
    fontSize: 28,
    fontWeight: 'bold',
    color: '#FFF'
  },
  containerForm: {
    flex:1,
    borderTopRightRadius: 25,
    borderTopLeftRadius: 25,
    paddingStart: '5%',
    paddingEnd: '5%'
  },
});
