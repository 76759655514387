import axios from 'axios';
import { useContext, useEffect, } from 'react';
import { useNavigation, useIsFocused } from '@react-navigation/native';

import { List,  FAB, Portal,useTheme } from 'react-native-paper';
import EmptyList from '../../components/ui/EmptyList'

import { ScrollView } from 'react-native';
import { AuthContext } from '../../store/auth-context';
import { CompanyContext } from '../../store/company-context';

import {  SIZES, FONTS } from '../../constants/theme'


function LocationListScreen() {
    const theme = useTheme();
    const isFocused = useIsFocused();
    const authCtx = useContext(AuthContext);
    const companyCtx = useContext(CompanyContext);
    const navigation = useNavigation()

    useEffect(() => {
      const companyId = authCtx.user.attributes['custom:company_id']
      
      companyCtx.fetchCompany(companyId)
      companyCtx.fetchLocations(companyId)      
    }, [])

  return (
    <>
      {companyCtx.locations?.length > 0 ?
        <ScrollView
          style={{
            flex: 1,
            backgroundColor: theme.colors.background
          }}>
          {
            companyCtx.locations?.map((item, index) => {
              return (
                <List.Item
                  key={`location-${index}`}
                  title={item.location_name}
                  description={item.address}
                  right={props => <List.Icon {...props} icon="pencil" />}
                  onPress={() => { navigation.navigate("Location", { edit: item }) }}
                  titleStyle={{ ...FONTS.h3 }}
                />
              )
            })
          }
        </ScrollView>
        :
        <EmptyList />
      }
      <Portal>
        <FAB.Group
          visible={isFocused}
          icon={'plus'}
          actions={[]}
          onStateChange={() => { }}
          onPress={() => {
            navigation.navigate("Location")
          }}
          fabStyle={{
            marginBottom: SIZES.padding * 3,
            backgroundColor: theme.colors.primary
          }}
        />
      </Portal>
    </>
  );

}

export default LocationListScreen;