import { useNavigation } from '@react-navigation/native';
import { useContext, useEffect, useState } from 'react';

import { Checkbox, useTheme } from 'react-native-paper';


import { ScrollView } from 'react-native';

import Button from '../components/ui/Button';
import FormContent from '../components/ui/FormContent';

import { AuthContext } from '../store/auth-context';
import { CompanyContext } from '../store/company-context';

function CompanySettingsScreen({ route }) {
    const theme = useTheme();

    const companyCtx = useContext(CompanyContext);
    const authCtx = useContext(AuthContext);
    const navigation = useNavigation()

    const [recordPhoto, setRecordPhoto] = useState(companyCtx.company !== null ? companyCtx.company.record_photo : false);
    const [recordEditable, setRecordEditable] = useState(companyCtx.company !== null ? companyCtx.company.record_editable : false);

    useEffect(() => {
        const companyId = authCtx.user.attributes['custom:company_id']

        companyCtx.fetchCompany(companyId).then((company) => {
            setRecordPhoto(company.record_photo)
            setRecordEditable(company.record_editable)
        })
    }, [])

    function submitHandler() {
        companyCtx.putCompany(companyCtx.company.company_id, recordPhoto, recordEditable)
            .then(() => {
                navigation.goBack()
            })
    }

    return (
        <FormContent
            title={"Company"}
        >
            <ScrollView
                bounces={false}
            >
                <Checkbox.Item
                    label={'Photo on clock-in/out'}
                    status={recordPhoto ? 'checked' : 'unchecked'}
                    color={theme.colors.secondary}
                    onPress={() => { setRecordPhoto(!recordPhoto) }}
                />
                <Checkbox.Item
                    label={'Employees can edit clock-in/out'}
                    status={recordEditable ? 'checked' : 'unchecked'}
                    color={theme.colors.secondary}
                    onPress={() => { setRecordEditable(!recordEditable) }}
                />

                <Button
                    onPress={submitHandler}
                    loading={companyCtx.loading}
                >
                    {'Save'}
                </Button>

            </ScrollView>
        </FormContent>
    );

}

export default CompanySettingsScreen;
