import { useContext, useEffect,  } from 'react';
import { useNavigation, useIsFocused } from '@react-navigation/native';

import { List, FAB, Portal,useTheme } from 'react-native-paper';

import EmptyList from '../../components/ui/EmptyList'

import { ScrollView, View, Text } from 'react-native';
import { AuthContext } from '../../store/auth-context';
import { CompanyContext } from '../../store/company-context';

import { SIZES, FONTS } from '../../constants/theme'


function PositionListScreen() {
    const theme = useTheme();
    const isFocused = useIsFocused();
    const authCtx = useContext(AuthContext);
    const companyCtx = useContext(CompanyContext);
    const navigation = useNavigation()
    
    useEffect(() => {
      const companyId = authCtx.user.attributes['custom:company_id']
      
      companyCtx.fetchCompany(companyId)
      companyCtx.fetchPositions(companyId)      
      
    }, [])

    return (
      <>
      {companyCtx.positions?.length > 0 ?
        <ScrollView 
        style={{
            flex: 1,
            backgroundColor: theme.colors.background
        }}>
          {companyCtx.positions?.map((item, index) => {
                return (
                  <List.Item
                    key={`position-${index}`}
                    title={item.position_name}
                    description={item.position_permissions.map(p => p.charAt(0).toUpperCase() + p.slice(1)).join(', ')}
                    right={props => <List.Icon {...props} icon="pencil" />}
                    onPress={() => { navigation.navigate("Position", { edit: item }) }}
                    titleStyle={{ ...FONTS.h3 }}
                    descriptionStyle={{ ...FONTS.body4 }}
                  />
                )
              })
            }
      </ScrollView>
      :
        <EmptyList/>
        }

      <Portal>
        <FAB.Group
          visible={isFocused}
          icon={'plus'}
          actions={[]}
          onStateChange={()=>{}}
          onPress={() => {
            navigation.navigate("Position")
          }}
          fabStyle={{
            marginBottom: SIZES.padding * 3,
            backgroundColor: theme.colors.primary
          }}
        />
      </Portal>
      </>
    );
  
}

export default PositionListScreen;