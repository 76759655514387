import { useContext, useEffect, useState, useCallback } from 'react';
import { Image, StyleSheet, Text, TouchableOpacity, View, Modal, Alert } from 'react-native';
import { useTheme } from 'react-native-paper';
import LottieView from "lottie-react-native"
import * as ExpoSplashScreen from 'expo-splash-screen';

import { AuthContext } from '../store/auth-context';

import * as Sentry from 'sentry-expo';

function SplashScreen() {
  const authCtx = useContext(AuthContext);
  const theme = useTheme();

  const [hasAnimationPlayedOnce, setHasAnimationPlayedOnce] = useState(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  const handleAnimationFinish = () => {
    setHasAnimationPlayedOnce(true)
  }

  useEffect(() => {
    //console.log('showSplashScreen', authCtx.showSplashScreen)
    try {
        setShowSplashScreen(authCtx.showSplashScreen)  
    } catch (error) {
      Sentry.Native.captureException(error)
    }
  }, [authCtx.showSplashScreen]) 

  const isModalVisible = !(!showSplashScreen && hasAnimationPlayedOnce)

  return (
    <Modal
      visible={isModalVisible}
      style={{ flex: 1, alignItems: 'center', backgroundColor: '#164e63', }}
    >
      <LottieView
        source={require('../assets/splash.json')}
        autoPlay
        loop={false}
        resizeMode="center"
        onAnimationFinish={() => handleAnimationFinish()}
        style={{
          backgroundColor: '#164e63',
        }}
      />

    </Modal>
  );
}

export default SplashScreen;