export const groupBy = (list, index) => {
    var result = list.reduce(function (r, a) {
      r[a[index]] = r[a[index]] || [];
      r[a[index]].push(a);
      return r;
    }, Object.create(null));

    var k = []

    Object.keys(result).forEach(x =>
      k.push([x, result[x]])
    )

    return k
  }