import { useState, useContext, useEffect } from 'react';
import { KeyboardAvoidingView, Platform, ScrollView, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';

import * as Animatable from 'react-native-animatable';
import moment from 'moment'

import { useTheme, List, Avatar } from 'react-native-paper';

import EmptyList from '../components/ui/EmptyList'

import { AuthContext } from '../store/auth-context'
import { UserContext } from '../store/user-context'
import { COLORS, FONTS } from '../constants/theme';

function NotificationScreen() {
  const theme = useTheme();

  const authCtx = useContext(AuthContext);
  const userCtx = useContext(UserContext);

  useEffect(() => {
    const companyId = authCtx.user.attributes['custom:company_id']
    const username = authCtx.user?.attributes.sub

    const messageIds = userCtx.messages.filter(m => m.seen == false && m.type === 'notification').map(m => m.message_id)
    console.log(messageIds)
    if(messageIds.length > 0)
      userCtx.putMessagesSeen(companyId, messageIds, true)
      .then(() => {
        userCtx.messages.map(m => m.seen = true)
      })
  }, [])

  return (
    <>
      {userCtx.messages?.length > 0 ?
        <ScrollView
          bounces={false}
          style={{
            flex: 1,
            backgroundColor: theme.colors.background
          }}>

          {
            userCtx.messages.filter(m => m.type === 'notification').sort((a, b) => a.datetime < b.datetime ? 1 : -1).map((notification, index) => {
              return (
                <List.Item
                  key={`item-${index}`}
                  title={notification.message.title + ' - ' + 
                  moment.duration(moment.utc().diff(moment.utc(notification.datetime))).format("D[d], H[h] m[m]")}
                  description={
                    notification.message.body 
                  }
                  titleStyle={{ ...FONTS.h3 }}
                  descriptionStyle={{ ...FONTS.h4 }}
                  descriptionNumberOfLines={2}
                  style={{
                    backgroundColor: notification.seen ? theme.colors.background :  theme.colors.surface
                  }}
                  left={props => <Avatar.Icon
                    size={40}
                    style={{
                      borderColor: 'white',
                      borderWidth: 0,
                      marginRight: 7,
                      alignSelf: 'center',
                      marginLeft: 20
                    }}
                    icon="account"
                  />}
                />
              )
            })
          }
        </ScrollView>
        :
        <EmptyList
          source={require('../assets/empty-box.json')}
          title="No notifications"
        />
      }
    </>
  );
}

export default NotificationScreen;