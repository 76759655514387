import { API } from 'aws-amplify';
import { createContext, useEffect, useState } from 'react';
import { getData, storeData } from './async-storage';
import { Platform } from 'react-native';
import * as Notifications from 'expo-notifications';
import Moment from 'react-moment';
import moment from 'moment'
import * as Sentry from 'sentry-expo';

export const UserContext = createContext({
  user : null,
  notifications: [],
  clockReminder: null,
  messages: null,
  loading: false,
  fetchMessages: (companyId, username) => {},
  setMessages: ([]) => {},
  getMessages: (companyId, username) => {},
  putMessagesSeen: (companyId, messageIds, seen) => {},
  putMessagesStatus: (companyId, messageIds, message_status) => {},
  putMessageRequest: (messageId, taskToken, message_status) => {},
  updateClockReminder: () => {},
  scheduleNotifications: (user) => {},
  setLoading: (isLoading) => {},
});

function UserContextProvider({ children }) {
  const [messages, setMessages] = useState(null)
  const [loading, setLoading] = useState(false);

  const [clockReminder, setClockReminder] = useState(true);
  const [user, setUser] = useState(null);
  
  const days = {
    sunday: 1,
    monday: 2,
    tuesday: 3,
    wednesday: 4,
    thursday: 5,
    friday: 6,
    saturday: 7
}

  useEffect(() => {
    try {
      fetchClockReminder()
    } catch (error) {
      Sentry.Native.captureException(error) 
    }
  }, [])

  useEffect(() => {
    try {
      if (user === null)
        return;

      console.log('CLOCK REMINDER CHANGED TO ' + clockReminder)

      scheduleNotifications(user)
    } catch (error) {
      Sentry.Native.captureException(error)
    }
  }, [clockReminder])


  async function fetchMessages(companyId, username) {
    const messages = await getMessages(companyId, username)
    setMessages(messages)

    console.log('User Context: fetchMessage(' + companyId + ',' + username + ')')
    
    return messages
  }

  async function getMessages(companyId, username) {
    setLoading(true)

    console.log('User Context: getMessages(' + companyId + ')')

    return API.get("message", "/message",
      {
        queryStringParameters:
        {
          'company_id': companyId,
          'employee_id': username,
          'date_start': null,
          'date_end': null,
        }
      })
      .then((messages) => {
        return messages
      }).finally(() => {
        setLoading(false)
      })
  }

  async function putMessagesSeen(companyId, messageIds, seen) {
    setLoading(true)

    console.log('User Context: putMessagesSeen(' + companyId + ',' + JSON.stringify(messageIds) + ',' + seen + ')')

    return API.put("message", "/message/seen",
      {
        body: {
          company_id: companyId,
          message_ids: messageIds,
          seen: seen
        }
      })
      .then((messages) => {
        return messages
      }).finally(() => {
        setLoading(false)
      })
  }

  async function putMessagesStatus(companyId, messageIds, messageStatus) {
    setLoading(true)

    console.log('User Context: putMessagesStatus(' + companyId + ',' + JSON.stringify(messageIds) + ',' + messageStatus + ')')

    return API.put("message", "/message/status",
      {
        body: {
          company_id: companyId,
          message_ids: messageIds,
          message_status: messageStatus
        }
      })
      .then((messages) => {
        return messages
      }).finally(() => {
        setLoading(false)
      })
  }

  async function putMessageRequest(messageId, taskToken, messageStatus) {
    setLoading(true)

    console.log('User Context: putMessageRequest(' + messageId + ',' + taskToken + ','  + messageStatus + ')')

    return API.put("message", "/message/request",
      {
        body: {
          message_id: messageId,
          task_token: taskToken,
          message_status: messageStatus        
        }
      })
      .then((messages) => {
        return messages
      }).finally(() => {
        setLoading(false)
      })
  }

  async function fetchClockReminder() {
    const store = await getData('clockReminder')

    if (store !== null) 
      setClockReminder(store)
  }

  async function updateClockReminder() {
    storeData('clockReminder', !clockReminder)
    setClockReminder(!clockReminder)
  }
 
  async function scheduleNotifications(user) {
    if (Platform.OS === 'web') //Web does not support Notifications
        return;

    Notifications.cancelAllScheduledNotificationsAsync()

    if (clockReminder && user.shift !== undefined)
    {
      user.shift.shift_days.forEach(day => {
        const fromDate = moment(user.shift.shift_time.from)
        const toDate = moment(user.shift.shift_time.to)
        const fromTime = moment(user.shift.shift_time.from).format("HH:mm")
        const toTime = moment(user.shift.shift_time.to).format("HH:mm")

        console.log(day + ' - ' + fromTime + ' - ' + toTime)

        Notifications.scheduleNotificationAsync({
          content: {
            title: "Shift starting now! 💼",
            body: 'Your shift goes from ' + fromTime + ' to ' + toTime,
          },
          trigger: { 
            hour: fromDate.hours(),
            minute: fromDate.minutes(),
            weekday: days[day],
            repeats: true,
           },
        });

        Notifications.scheduleNotificationAsync({
          content: {
            title: "Shift ending now! 😴",
            body: 'Your shift goes from ' + fromTime + ' to ' + toTime,
          },
          trigger: { 
            hour: toDate.hours(),
            minute: toDate.minutes(),
            weekday: days[day],
            repeats: true,
           },
        });
      })
    }
  }

  const value = {
    loading,
    messages,
    user: user,
    clockReminder: clockReminder,
    setMessages: setMessages,
    fetchMessages: fetchMessages,
    getMessages: getMessages,
    putMessagesSeen: putMessagesSeen,
    putMessagesStatus: putMessagesStatus,
    putMessageRequest: putMessageRequest,
    setUser: setUser,
    updateClockReminder: updateClockReminder,
    scheduleNotifications: scheduleNotifications,
    setLoading: setLoading,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

export default UserContextProvider;
