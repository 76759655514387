import { useNavigation } from '@react-navigation/native';
import { useContext, useEffect, useState } from 'react';

import { Checkbox, useTheme, Card, Avatar, Text } from 'react-native-paper';


import { ScrollView, View, Linking } from 'react-native';

import Button from '../components/ui/Button';
import FormContent from '../components/ui/FormContent';

import { COLORS, FONTS, SIZES } from '../constants/theme';

import { AuthContext } from '../store/auth-context';
import { CompanyContext } from '../store/company-context';
import Constants from 'expo-constants';
import InputSpinner from "react-native-input-spinner";


function SubscriptionScreen({ route }) {
    const theme = useTheme();

    const companyCtx = useContext(CompanyContext);
    const authCtx = useContext(AuthContext);
    const navigation = useNavigation()

    const [quantity, setQuantity] = useState(1);
    const [subscription, setSubscription] = useState(null)

    const plans = [
        {
            name: 'Business',
            description: 'Includes our advanced features for your business',
            features: ["Basic plan", "Photo restriction", "Facial Recognition", "Kiosks"],
            price: '€4.50',
            plan: 'business',
            quantity: {
                min: 1,
                initial: 1,
                max: 999
            }

        },
        {
            name: 'Enterprise',
            description: 'Explore the capability of Machine Learning and Artificial Intelligence',
            features: ["Business plan", "Advanced analytics", "Machine learning", "Forecast capacity"],
            price: '€6',
            plan: 'enterprise',
            quantity: {
                min: 10,
                initial: 10,
                max: 999
            }
        }
    ]

    useEffect(() => {
        companyCtx.getStripeSubscriptions(companyCtx.company.stripe_customer_id)
            .then((subscriptions) => {
                console.log(subscriptions)
                setSubscription(subscriptions)
            })
    }, [])

    function createSession(plan) {
        const priceId = Constants.expoConfig.extra.stripe.prices[plan]['en'] //TODO: Include Portuguese language and price
        const trialPeriodDays = Constants.expoConfig.extra.stripe.trialPeriodDays[plan]['en']
        const stripeCustomerId = companyCtx.company.stripe_customer_id

        companyCtx.postStripeCheckoutSession(companyCtx.company.company_id, stripeCustomerId, priceId, quantity, trialPeriodDays)
        .then((session) => {
            Linking.openURL(session['url']);
        })
    }

    function billingPortal() {
        const stripeCustomerId = companyCtx.company.stripe_customer_id

        companyCtx.postStripeBillingPortal(stripeCustomerId)
        .then((session) => {
            Linking.openURL(session['url']);
        })
    }

    function renderManageSubscription() {
        return (
            <Button
                helperText="You'll be redirected to Stripe.com. Our subscriptions and payments are managed by Stripe."
                onPress={() => { billingPortal() }}
            > {'Manage subscription'}
            </Button>
        )
    }

    function renderCurrentPlan() {
        let plan = {
            name: 'Basic',
            description: 'Includes our essential features',
            features: [],
            price: 'FREE',
            plan: 'basic',
        }

        if(subscription && subscription.data.length > 0){
            plan = plans.filter((plan) => Constants.expoConfig.extra.stripe.prices[plan.plan]['en'] == subscription.data[0].plan.id)[0]
        }

        return (
            <>
                <Text style={{ color: theme.colors.onBackground, ...FONTS.h4 }}>Your plan is</Text>
                <Text style={{ color: theme.colors.onBackground, fontWeight: 'bold', ...FONTS.h2 }}>{plan.name.toUpperCase()}</Text>
                <Text style={{ marginTop: SIZES.base, color: theme.colors.onBackground, ...FONTS.h4 }}>{plan.description}</Text>
                {plan.features && plan.features.length > 0 &&
                    <View
                        style={{
                            flex: 1,
                            marginTop: SIZES.base,
                        }}
                    >
                        
                            <Text style={{ color: theme.colors.onBackground, fontWeight: 'bold', ...FONTS.h3 }}>This includes:</Text>
                            {
                                plan.features.map((feature, index) => {
                                    return (
                                        <Text style={{ color: theme.colors.onBackground, ...FONTS.h4 }}>- {feature}</Text>
                                    )
                                })
                            }
                        
                    </View>
                }
            </>
        )
    }

    function renderPricingTable() {
        return (
            <View>
                <Text style={{ marginTop: SIZES.padding, color: theme.colors.onBackground, ...FONTS.h3 }}>Pick your plan:</Text>
                <View
                    style={{
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                    }}
                >
                    {
                        plans.map((plan, index) => {
                           
                            return (
                                <View
                                    key={ index }>
                                    <View
                                        style={{
                                            flex: 1,
                                            backgroundColor: theme.colors.surface,
                                            padding: SIZES.base * 2,
                                            borderRadius: SIZES.radius,
                                            marginTop: SIZES.base
                                        }}
                                    >
                                        <Text style={{ color: theme.colors.onBackground, fontWeight: 'bold', ...FONTS.h2 }}>{plan.name.toUpperCase()}</Text>
                                        <Text style={{ marginTop: SIZES.base, color: theme.colors.onBackground, ...FONTS.h4 }}>{plan.description}</Text>

                                        <View
                                            style={{
                                                flexDirection: 'row',
                                                justifyContent: 'flex-start',
                                                alignItems: 'baseline',
                                                marginTop: SIZES.base
                                            }}
                                        >
                                            <Text style={{ color: theme.colors.onBackground, ...FONTS.h1 }}>{plan.price}</Text>
                                            <Text style={{ marginLeft: SIZES.base, color: theme.colors.onBackground, ...FONTS.h4 }}>per employee</Text>
                                        </View>

                                        <View
                                            style={{
                                                flex: 1,
                                                marginTop: SIZES.base,
                                            }}
                                        >
                                            <Text style={{ color: theme.colors.onBackground, fontWeight: 'bold', ...FONTS.h3 }}>This includes:</Text>
                                            {
                                                plan.features.map((feature, index) => {
                                                    return (
                                                        <Text key={ index } style={{ color: theme.colors.onBackground, ...FONTS.h4 }}>- {feature}</Text>
                                                    )
                                                })
                                            }
                                        </View>

                                        <View
                                            style={{
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                                alignContent: 'center',
                                                alignItems: 'center',
                                                marginTop: SIZES.base,
                                            }}
                                        >
                                            <Text style={{ color: theme.colors.onBackground, ...FONTS.h4 }}>How many employees</Text>
                                            <Text>
                                                <InputSpinner
                                                    value={plan.quantity.initial}
                                                    color={theme.colors.primary}
                                                    rounded={false}
                                                    showBorder={true}
                                                    min={plan.quantity.min}
                                                    max={plan.quantity.max}
                                                    onChange={(num) => {
                                                        setQuantity(num)
                                                    }}
                                                    style={{
                                                        flex: 1,
                                                        minWidth: 150
                                                    }}
                                                    textColor={theme.colors.onBackground}
                                                />
                                            </Text>
                                        </View>
                                    </View>
                                    <Button
                                        style={{
                                            marginBottom: SIZES.padding
                                        }}
                                        helperText="You'll be redirected to Stripe.com. Our subscriptions and payments are managed by Stripe."
                                        onPress={() => { createSession(plan.plan) }}
                                    > {'Start Trial'}
                                    </Button>
                                </View>
                            )
                        })
                    }
                </View>
            </View>
        )
    }

    return (
        <FormContent
            title={"Subscription"}
        >
            <ScrollView
                bounces={false}
            >
                {renderCurrentPlan()}
                
                {subscription && subscription.data.length == 0 ?
                    
                    renderPricingTable()
                    :
                    renderManageSubscription()
                }
            </ScrollView>
        </FormContent>
    );

}

export default SubscriptionScreen;
