import { useContext, useEffect, useState } from 'react';
import { useTheme, FAB, Provider, Portal, AnimatedFAB, Avatar,ActivityIndicator } from 'react-native-paper';
import { StyleSheet, View, Text, KeyboardAvoidingView, Platform, ScrollView, Dimensions, Vibration } from 'react-native';
import Modal from "react-native-modal";

import IconButton from './IconButton';
import Button from './Button';
import { Buffer } from "buffer";

import { Camera, CameraType } from 'expo-camera';
import * as FaceDetector from 'expo-face-detector';

import { UserContext } from '../../store/user-context';

import { SIZES, FONTS } from '../../constants/theme'
import { Colors } from '../../constants/styles';
import { AnimatedCircularProgress } from 'react-native-circular-progress';
import MaskedView from "@react-native-masked-view/masked-view"

const { width: windowWidth } = Dimensions.get("window")
const PREVIEW_SIZE = 325
const PREVIEW_RECT = {
  minX: (windowWidth - PREVIEW_SIZE) / 2,
  minY: 50,
  width: PREVIEW_SIZE,
    height: PREVIEW_SIZE
}

const instructionsText = {
    initialPrompt: "Position your face in the circle",
    performActions: "Keep the device still and perform the following actions:",
    tooClose: "You're too close. Hold the device further."
}

const detections = {
    TURN_HEAD_LEFT: { instruction: "Turn head left", android : { minAngle: 340 }, ios : { minAngle: -30 } },
    TURN_HEAD_RIGHT: { instruction: "Turn head right", android : { minAngle: 20 }, ios : { minAngle: 30 } },
}

const detectionsList = [
    "TURN_HEAD_LEFT",
    "TURN_HEAD_RIGHT"
  ]

  const initialState = {
    faceDetected: false,
    faceTooBig: false,
    detectionsList,
    currentDetectionIndex: 0,
    progressFill: 0,
    processComplete: false
  }

function SelfieModal({ isVisible, setIsModalVisible, enableCamera, hasCameraPermission, handlePhotosTaken, cameraRef, loadingStatus }) {
    const theme = useTheme();
    const userCtx = useContext(UserContext);

    const [state, setState] = useState(initialState)
    const [photos, setPhotos] = useState({})

    useEffect(() => {
        if (state.processComplete) {
            setTimeout(() => {    
                handlePhotosTaken(photos)
                closeModal()
            }, 500)
        }
    }, [state.processComplete])

    function closeModal()
    {
        setIsModalVisible(false); 
        setPhotos({})
        setState(initialState)
    }

    async function takePicture(angle) {
        if(angle in photos)
            return;

        var imageSrc = null; 

        const options = { quality: 0.1, base64: true, skipProcessing: true };
        const data = await cameraRef.current.takePictureAsync(options)
        imageSrc = data.base64;

        setPhotos(prevPhoto => ({ ...prevPhoto, [angle]: imageSrc }))
        Vibration.vibrate();
    }

    async function handleFacesDetected(faceResult) {
        console.log(faceResult)

        // 1. There is only a single face in the detection results.
        if (faceResult.faces.length !== 1) {
            setState(detection({ type: "FACE_DETECTED", payload: false }))
            setPhotos({})

            return
        }

        console.log(faceResult)

        const face = faceResult.faces[0]

        const faceRect = {
            minX: face.bounds.origin.x,
            minY: face.bounds.origin.y,
            width: face.bounds.size.width,
            height: face.bounds.size.height
        }

        // 2. The face is fully contained within the camera preview.
        const edgeOffset = 100
        const faceRectSmaller = {
            width: faceRect.width - edgeOffset,
            height: faceRect.height - edgeOffset,
            minY: faceRect.minY + edgeOffset / 2,
            minX: faceRect.minX + edgeOffset / 2
        }

        const previewContainsFace = contains({
            outside: PREVIEW_RECT,
            inside: faceRectSmaller
        })

        if (!previewContainsFace) {
            setState(detection({ type: "FACE_DETECTED", payload: false }))
            setPhotos({})

            return
        }

        if(!state.faceDetected){
            // 3. The face is not as big as the camera preview.
            const faceMaxSize = PREVIEW_SIZE - 100
            if (faceRect.width >= faceMaxSize && faceRect.height >= faceMaxSize) {
                setState(detection({ type: "FACE_TOO_BIG", payload: true }))
                return
            }

            if (state.faceTooBig) {
                setState(detection({ type: "FACE_TOO_BIG", payload: false }))
            }

            if (!state.faceDetected) {
                await takePicture('front')

                setState(detection({ type: "FACE_DETECTED", payload: true }))
                
            }
        }

        const detectionAction = state.detectionsList[state.currentDetectionIndex]

        switch (detectionAction) {
            case "TURN_HEAD_LEFT":
                // Negative angle is the when the face turns left
                if (face.yawAngle <= detections.TURN_HEAD_LEFT[Platform.OS].minAngle &&  ((Platform.OS == "android" && face.yawAngle > 180) || Platform.OS == "ios")) {
                    await takePicture('left')

                    setState(detection({ type: "NEXT_DETECTION", payload: null }))
                }
                return
            case "TURN_HEAD_RIGHT":
                // Positive angle is the when the face turns right
                if (face.yawAngle >= detections.TURN_HEAD_RIGHT[Platform.OS].minAngle && ((Platform.OS == "android" && face.yawAngle < 180) || Platform.OS == "ios" )) {
                    await takePicture('right')

                    setState(detection({ type: "NEXT_DETECTION", payload: null }))
                }
                return
        }
        
        setFacesDetected(faceResult.faces)
    }

    function detection(action) {
        switch (action.type) {
            case "FACE_DETECTED":
                if (action.payload) {
                    return {
                        ...state,
                        faceDetected: action.payload,
                        faceTooBig: !action.payload,
                        progressFill: 100 / (state.detectionsList.length + 1)
                    }
                } else {
                    // Reset
                    return initialState
                }
            case "FACE_TOO_BIG":
                return { ...state, faceTooBig: action.payload, progressFill: initialState.progressFill }
            case "NEXT_DETECTION":
                // Next detection index
                const nextDetectionIndex = state.currentDetectionIndex + 1

                // Skip 0 index
                const progressMultiplier = nextDetectionIndex + 1

                const newProgressFill =
                    (100 / (state.detectionsList.length + 1)) * progressMultiplier

                if (nextDetectionIndex === state.detectionsList.length) {
                    // Passed
                    return { ...state, processComplete: true, progressFill: newProgressFill }
                }

                // Next detection
                return {
                    ...state,
                    currentDetectionIndex: nextDetectionIndex,
                    progressFill: newProgressFill
                }
            default:
                throw new Error("Unexpected action type.")
        }
    }

    function contains({ outside, inside }) {
        const outsideMaxX = outside.minX + outside.width
        const insideMaxX = inside.minX + inside.width

        const outsideMaxY = outside.minY + outside.height
        const insideMaxY = inside.minY + inside.height

        if (inside.minX < outside.minX) {
            return false
        }
        if (insideMaxX > outsideMaxX) {
            return false
        }
        if (inside.minY < outside.minY) {
            return false
        }
        if (insideMaxY > outsideMaxY) {
            return false
        }

        return true
    }

    return (
        <Modal
            isVisible={isVisible}
            onBackdropPress={() => { closeModal() }}
            animationIn={"zoomIn"}
            animationOut={"zoomOut"}
            hideModalContentWhileAnimating={true}
            style={{
                margin: 0
            }}
            //useNativeDriver={true}
        >
            <View
                style={{
                    flex: enableCamera ? 1 : 0.5,
                    minHeight: 50,
                    backgroundColor: theme.colors.background,
                }}
            >
                {/* HEADER */}
                <View
                    style={{
                        height: 65,
                        backgroundColor: theme.colors.tertiary,
                        alignItems: 'center',
                    }}>
                    <View
                        style={{
                            flex: 1,
                            width: '100%',
                            alignItems: 'flex-end',
                            paddingHorizontal: SIZES.base,
                            paddingTop: SIZES.base,
                        }}
                    >
                        <IconButton
                            icon="close-circle-outline"
                            size={30}
                            color={'white'}
                            onPress={() => { closeModal() }}
                        />
                    </View>
                </View>

                {/* BODY */}
                <View
                    style={{
                        flex: 1,
                    }}>
                    {enableCamera ?
                        <>
                            <MaskedView
                                style={StyleSheet.absoluteFill}
                                maskElement={<View style={{
                                    borderRadius: PREVIEW_SIZE / 2,
                                    height: PREVIEW_SIZE,
                                    width: PREVIEW_SIZE,
                                    marginTop: PREVIEW_RECT.minY * 1.5,
                                    alignSelf: "center",
                                    backgroundColor: "white"
                                }} />}
                            >

                                {hasCameraPermission ?
                                    <Camera
                                        onFacesDetected={handleFacesDetected}
                                        faceDetectorSettings={{
                                            mode: FaceDetector.FaceDetectorMode.fast,
                                            detectLandmarks: FaceDetector.FaceDetectorLandmarks.none,
                                            runClassifications: FaceDetector.FaceDetectorClassifications.all,
                                            minDetectionInterval: 300,
                                            tracking: false,
                                        }}
                                        type={
                                            Camera.Constants.Type.front
                                        }
                                        playSoundOnCapture={true}
                                        useCamera2Api={false}
                                        ref={cameraRef}
                                        style={StyleSheet.absoluteFill}
                                    >
                                        <AnimatedCircularProgress
                                            style={{
                                                width: PREVIEW_SIZE,
                                                height: PREVIEW_SIZE,
                                                marginTop: PREVIEW_RECT.minY * 1.5,
                                                marginLeft: PREVIEW_RECT.minX
                                            }}
                                            size={PREVIEW_SIZE}
                                            width={5}
                                            backgroundWidth={7}
                                            fill={state.progressFill}
                                            tintColor="#3485FF"
                                            backgroundColor="#e8e8e8"
                                        />
                                    </Camera>
                                    :
                                    <Text>
                                        {'You need permission to use the camera'}
                                    </Text>
                                }

                            </MaskedView>
                            <View style={{
                                flex: 1,
                                alignItems: "center",
                                justifyContent: 'space-around',
                                marginTop: PREVIEW_RECT.minY * 1.5 + PREVIEW_SIZE
                            }}>
                                <Text style={{
                                    textAlign: "center",
                                    color: theme.colors.onBackground, 
                                    ...FONTS.h2
                                }}>

                                    {!state.faceDetected &&
                                        !state.faceTooBig &&
                                        instructionsText.initialPrompt}

                                    {state.faceTooBig && instructionsText.tooClose}

                                    {state.faceDetected &&
                                        !state.faceTooBig &&
                                        instructionsText.performActions}
                                </Text>
                                <Text style={{
                                    textAlign: "center",
                                    fontWeight: "bold",
                                    color: theme.colors.onBackground, 
                                    ...FONTS.h1
                                }}>
                                    {state.faceDetected &&
                                        !state.faceTooBig &&
                                        detections[state.detectionsList[state.currentDetectionIndex]]
                                            .instruction}

                                            {/* {'TURN YOUR HEAD'} */}
                                </Text>
                                <Text style={{ color: theme.colors.onBackground, marginTop: SIZES.base, ...FONTS.body4 }}>
                                    {loadingStatus}
                                    {/* {'TURN YOUR HEAD'} */}
                                </Text>
                            </View>
                        </>
                        :
                        <></>
                    }

                </View>

                {/* FOOTER */}
                {/* <View
                    style={{
                        paddingRight: SIZES.padding,
                        paddingLeft: SIZES.padding,
                        paddingBottom: SIZES.base,
                    }}>

                    <View
                        style={{
                            bottom: '5%',
                        }}
                    >
                        <Button
                            customStyle={{
                                backgroundColor: theme.colors.success
                            }}
                            customLabelStyle={{
                                color: theme.colors.onSurface
                            }}
                            loading={userCtx.loading}
                            onPress={() => {
                               handlePhotoTaken()
                            }}>
                            {'TAKE PICTURE'}
                        </Button>
                        <Text style={{ alignSelf: 'center', color: theme.colors.onBackground, marginTop: SIZES.base, ...FONTS.body5 }}>
                            {loadingStatus}
                        </Text>
                    </View>
                </View> */}
            </View>
        </Modal>
    )
}

export default SelfieModal;