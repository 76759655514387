import { useState } from 'react';
import { StyleSheet, View, Text, KeyboardAvoidingView, Platform, Linking } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { SocialIcon } from 'react-native-elements'
import { Divider, useTheme } from 'react-native-paper';

import Button from '../ui/Button';
import Input from '../ui/Input';

import { COLORS, FONTS, SIZES } from '../../constants/theme';


function AuthForm({ isLogin, onSubmit, onFederatedSignIn, credentialsInvalid, loading }) {
  const theme = useTheme();
  const navigation = useNavigation();
  const route = useRoute();

  const [enteredEmail, setEnteredEmail] = useState('');
  const [enteredPassword, setEnteredPassword] = useState('');
  const [enteredConfirmPassword, setEnteredConfirmPassword] = useState('');

  const isJoining = route.params?.isJoining;

  const {
    email: emailIsInvalid,
    password: passwordIsInvalid,
    confirmPassword: passwordsDontMatch,
  } = credentialsInvalid;

  function updateInputValueHandler(inputType, enteredValue) {
    switch (inputType) {
      case 'email':
        setEnteredEmail(enteredValue);
        break;
      case 'password':
        setEnteredPassword(enteredValue);
        break;
      case 'confirmPassword':
        setEnteredConfirmPassword(enteredValue);
        break;
    }
  }

  function switchAuthModeHandler(isJoining) {
    if (isLogin) {
      navigation.replace('SignUp');
    } else {
      navigation.replace('SignIn', { isJoining: isJoining});
    }
  }

  function forgotPasswordHandler() {
    navigation.navigate('ForgotPassword');
  }

  function submitHandler() {
    onSubmit({
      email: enteredEmail,
      password: enteredPassword,
      confirmPassword: enteredConfirmPassword,
    });
  }

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      style={styles.container}
      animation="fadeInUp">
      <View
        style={{
          flex: 2,
        }}
      >
        <Input
          label="Email"
          onChangeText={updateInputValueHandler.bind(this, 'email')}
          value={enteredEmail}
          isInvalid={emailIsInvalid}
        />
        <Input
          label={isJoining ? "Temporary Password" : "Password"}
          onChangeText={updateInputValueHandler.bind(this, 'password')}
          secureTextEntry
          value={enteredPassword}
          isInvalid={passwordIsInvalid}
        />
        {!isLogin && (
          <Input
            label="Confirm Password"
            onChangeText={updateInputValueHandler.bind(
              this,
              'confirmPassword'
            )}
            secureTextEntry
            value={enteredConfirmPassword}
            isInvalid={passwordsDontMatch}
          />
        )}
        <Button
          onPress={submitHandler}
          loading={loading}
        >
          {isLogin ? isJoining ? 'Join' : 'Login' : 'Create Account'}
        </Button>

        {isLogin && (
          <>
            <Button onPress={forgotPasswordHandler} mode="text">
              {'Forgot Password?'}
            </Button>
          </>
        )}

        {!isJoining &&
          <>
            <Divider style={styles.divider} />

            {Platform.OS !== 'ios' && 
              <SocialIcon
                title={isLogin ? 'Sign in with Google' : 'Sign up with Google'}
                button
                light
                type='google'
                onPress={onFederatedSignIn}
              />
            }
          </>
        }
      </View>
    
      <View
        style={{
          flexShrink: 1,
          paddingBottom: SIZES.padding,
          justifyContent: 'flex-end'
        }}
      >
        {!isLogin &&
          <>
            <Text style={{ alignSelf: 'center', color: theme.colors.onBackground }} >{'Trying to join as an Employee?'}</Text>
            <Button onPress={() => switchAuthModeHandler(true)} mode="outlined">
              {'Join as an Employee'}
            </Button>
          </>
        }

        {!isLogin &&
          <View
            style={{
              marginTop: SIZES.base + 5,
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Text style={{ color: theme.colors.onBackground }} >{'Already have an account? '}</Text>
            <Text style={{ fontWeight: 'bold', color: theme.colors.primary }} onPress={ () => switchAuthModeHandler(false)}>Login</Text>
          </View>
        }

        {isLogin &&
          <>
            <Text style={{ alignSelf: 'center', marginTop: SIZES.base + 5, color: theme.colors.onBackground }} >{"Trying to create an organization?"}</Text>
            <Button onPress={switchAuthModeHandler} mode="outlined">
              {'Create an Organization'}
            </Button>
          </>
        }
      
      </View>

    </KeyboardAvoidingView>
  );
}

export default AuthForm;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 25
  },
  divider:{
    marginTop: 25,
    marginBottom: 20,
    flexDirection: 'row', 
    alignItems: 'center'
  },
});
