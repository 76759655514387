import { useContext, useEffect, useState } from 'react';

import { useNavigation } from '@react-navigation/native';

import Toast from 'react-native-root-toast';

import AuthContent from '../components/Auth/AuthContent';
import { AuthContext } from '../store/auth-context';

function SignInScreen() {
  const authCtx = useContext(AuthContext);
  const navigation = useNavigation();

  useEffect(() => {
    authCtx.setShowSplashScreen(false)
  },[])

  async function loginHandler({ email, password }) {
    authCtx.signIn(
      email,
      password
    ).then((user) => {
      if (user.challengeParam != null && user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        console.log('login page auth challenge')
        navigation.navigate('ChangePassword', { user: user })
      }
    })
      .catch((error) => {
        Toast.show(error.message, {
          duration: Toast.durations.LONG,
          position: Toast.positions.BOTTOM,
        });
      })
  }

  async function federatedHandler(){
      authCtx.federatedSignIn('Google');
  }
    
  return <AuthContent 
    isLogin 
    loading={authCtx.loading}
    onAuthenticate={loginHandler} 
    onFederatedSignIn={federatedHandler}
  />;
}

export default SignInScreen;