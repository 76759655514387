import { useState, useEffect } from 'react';
import { StyleSheet, View, Text, KeyboardAvoidingView, Platform } from 'react-native';
import MomentTZ from 'moment-timezone';

import { Divider } from 'react-native-paper';

import Input from '../ui/Input';
import Button from '../ui/Button';
import DropDown from '../ui/DropDown';

import { useTheme } from 'react-native-paper';

import * as Animatable from 'react-native-animatable'
import Moment from 'react-moment';

function OnboardingContent({ loading, onSubmit }) {
    const theme = useTheme();

    const [enteredName, setEnteredName] = useState('');
    const [enteredSurname, setEnteredSurname] = useState('');
    const [enteredCompanyName, setEnteredCompanyName] = useState('');
    const [timezones, setTimezones] = useState([]);
    const [showTimezoneDropDown, setTimezoneShowDropDown] = useState(false);
    const [timezone, setTimezone] = useState(MomentTZ.tz.guess());

    var momentTimezone = MomentTZ.tz.names();
    var countries = MomentTZ.tz.countries();

    useEffect(() => {
        var timeZonesOrdered = [];

        console.log(countries)
        const zones = new Set()

        for(const country of countries)
        {
            MomentTZ.tz.zonesForCountry(country).reduce((set, zone) => set.add(zone), zones)
        }

        const timezones = [...zones].sort()

        for (var i in timezones) {
            var gmt = MomentTZ.tz(timezones[i]).format('Z')

            timeZonesOrdered.push(
                {
                    "timeZone": timezones[i],
                    "gmt": gmt,
                    "timeZoneFormat": "(GMT" + gmt + ") " + timezones[i]
                }
            )
        }

        console.log(momentTimezone.length)
        console.log(timeZonesOrdered.length)

        

        setTimezones(timeZonesOrdered)
    }, []);

    function submitHandler() {
        onSubmit({
          name: enteredName,
          surname: enteredSurname,
          companyName: enteredCompanyName,
          timeZone: timezone
        });
      }

    function updateInputValueHandler(inputType, enteredValue) {
        switch (inputType) {
            case 'name':
                setEnteredName(enteredValue);
                break;
            case 'surname':
                setEnteredSurname(enteredValue);
                break;
            case 'companyName':
                setEnteredCompanyName(enteredValue);
                break;
        }
    }

    return (
        <View
        style={{
            backgroundColor: theme.colors.tertiary,
            ...styles.authContent}}>
            <Animatable.View animation="fadeInLeft" delay={500} style={{ ...styles.containerHeader}}>
                <Text style={styles.message}>Onboarding</Text>
            </Animatable.View>

            <Animatable.View animation="fadeInUp" style={{ backgroundColor: theme.colors.background, ...styles.containerForm }}>
                <KeyboardAvoidingView
                          behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
                >
                    {/* TODO: Add validations here and other inputs */}
                <Input
                    label="Name"
                    placeholder="Type your name"
                    onChangeText={updateInputValueHandler.bind(this, 'name')}
                    value={enteredName}
                    keyboardType="text"
                // isInvalid={emailIsInvalid}  
                />
                <Input
                    label="Surname"
                    placeholder="Type your surname"
                    onChangeText={updateInputValueHandler.bind(this, 'surname')}
                    value={enteredSurname}
                    keyboardType="text"
                // isInvalid={emailIsInvalid}
                />

                <Divider style={styles.divider}/>
                
                {/* TODO: Build a component for Dropdown */}
                <Input
                    label="Company name"
                    placeholder="Type your company's name"
                    onChangeText={updateInputValueHandler.bind(this, 'companyName')}
                    value={enteredCompanyName}
                    keyboardType="text"
                // isInvalid={emailIsInvalid}
                />

                <DropDown
                    label={"Timezone"}
                    mode={"outlined"}
                    visible={showTimezoneDropDown}
                    showDropDown={() => setTimezoneShowDropDown(true)}
                    onDismiss={() => setTimezoneShowDropDown(false)}
                    value={timezone}
                    setValue={setTimezone}
                    list={timezones.map(tz => {
                        return {
                            label: tz.timeZoneFormat,
                            value: tz.timeZone
                        }
                    })} 
                    inputProps={{
                        style: {
                            marginBottom: 10
                        }
                    }} 
                />

                <Button loading={loading} onPress={submitHandler}>
                    {'Complete'}
                </Button>
                </KeyboardAvoidingView>
            </Animatable.View>
        </View>
    );
}

export default OnboardingContent;

const styles = StyleSheet.create({
    authContent: {
        flex: 1,
    },
    containerHeader: {
        marginTop: '26%',
        marginBottom: '8%',
        paddingStart: '5%'
    },
    message: {
        fontSize: 28,
        fontWeight: 'bold',
        color: '#FFF'
    },
    containerForm: {
        flex: 1,
        paddingTop: 25,
        borderTopRightRadius: 25,
        borderTopLeftRadius: 25,
        paddingStart: '5%',
        paddingEnd: '5%'
    },
    divider: {
        marginTop: 40,
        marginBottom: 25,
        flexDirection: 'row',
        alignItems: 'center'
    },
    timezoneLabel: {
        fontSize: 20,
        marginTop: 28,
      },
    dropdownButtonStyle: {
        borderRadius: 4,
        marginTop: 8,
        height: 40,
        marginBottom: 12,
        width: '100%',
        borderWidth: 1,
    }
});
