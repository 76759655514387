import { useContext, useEffect,  useState } from 'react';
import { useTheme } from 'react-native-paper';
import { StyleSheet, Text, View, Image, ImageBackground, TouchableOpacity,FlatList, ScrollView } from 'react-native';
import { Ionicons } from '@expo/vector-icons'

import { COLORS, SIZES, FONTS } from '../../constants/theme'

function CardHeader({ icon, title, subtitle }) {
    const theme = useTheme();

    return (
        <View
            style={{
                flexDirection: 'row',
            }}
        >
            {/* TODO: Replace it for an image */}
             <Ionicons 
                name={icon}
                size={SIZES.icon}
                color={theme.colors.primary}
                marginTop={5}
              /> 
            <View style={{ marginLeft: SIZES.base }}>
                <Text style={{  color: theme.colors.onSurfaceVariant, ...FONTS.h3 }}>{title}</Text>
                <Text style={{  color: theme.colors.onSurfaceVariant, ...FONTS.body4 }}>{subtitle}</Text>
            </View>
        </View>
    )
}

export default CardHeader;