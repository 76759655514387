
import { useContext, useEffect, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { useTheme } from 'react-native-paper';

import { AuthContext } from '../store/auth-context';

import Button from '../components/ui/Button';
import LottieView from "lottie-react-native"

import * as Animatable from 'react-native-animatable';
import { COLORS, FONTS, SIZES } from '../constants/theme';
import * as Sentry from 'sentry-expo';


function WelcomeScreen() {
  const theme = useTheme();
  const navigation = useNavigation();
  const authCtx = useContext(AuthContext);


  useEffect(() => {
    try {
      authCtx.setShowSplashScreen(false)
    } catch (error) {
      console.log(error)
      Sentry.Native.captureException(error)
    }
  }, [])

  return (
    <View style={{
      flex: 1,
      backgroundColor: theme.colors.tertiary,
    }}>
      <View
        style={{
          flex: 2,
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        <LottieView
          source={require('../assets/splash.json')}
          autoPlay
          loop={true}
          resizeMode="center"
        />
      </View>
      <Animatable.View
        animation={'fadeInUp'}
        style={{
          flexShrink: 1,
          backgroundColor: theme.colors.background,
          borderTopLeftRadius: 25,
          borderTopRightRadius: 25,
          padding: SIZES.padding,
          justifyContent: 'flex-end'
        }}>
        <Button
          onPress={() => navigation.navigate('SignUp')}
        >
          {'Create an Organization'}
        </Button>
        <Button onPress={() => navigation.navigate('SignIn', { isJoining: true })}>
          {'Join as an Employee'}
        </Button>

        <View>
          <Text
            style={{
              color: theme.colors.onBackground,
              alignSelf: 'center',
              marginTop: SIZES.padding
            }}
          >Already using UClock?</Text>
          <Button
            onPress={() => navigation.navigate('SignIn')}
            mode="outlined">
            {'Login'}
          </Button>
          {/* </View> */}
        </View>
      </Animatable.View>
    </View>
  );
}

export default WelcomeScreen;
