import { useContext, useEffect, useState, useRef, createRef, forwardRef } from 'react';
import { useNavigation } from '@react-navigation/native';
import {Platform, StyleSheet, View, ScrollView, SafeAreaView} from 'react-native';

import { List, MD3Colors, useTheme, Text } from 'react-native-paper';
import Slider from '@react-native-community/slider';

import Button from '../../components/ui/Button';
import DialogPopup from '../../components/ui/DialogPopup';
import Input from '../../components/ui/Input';
import FormContent from '../../components/ui/FormContent';
import IconButton from '../../components/ui/IconButton';

import MapView, {  Marker, Circle, PROVIDER_GOOGLE } from 'react-native-maps';
import { GooglePlacesAutocomplete } from 'react-native-google-places-autocomplete';

import Constants from 'expo-constants';

import { AuthContext } from '../../store/auth-context';
import { CompanyContext } from '../../store/company-context';
import { COLORS, SIZES, FONTS } from '../../constants/theme'

function LocationScreen({ route }) {
    const theme = useTheme();
    const autocompleteRef = useRef();

    const companyCtx = useContext(CompanyContext);
    const navigation = useNavigation()

    const [formlsInvalid, setFormlsInvalid] = useState({
        name: false,
      });
    
    const edit = route.params?.edit;
    const [name, setName] = useState(edit !== undefined ? edit.location_name : '');
    const [address, setAddress] = useState(edit !== undefined ? edit.address : '');
    const [latitude, setLatitude] = useState(edit !== undefined ? parseFloat(edit.latitude) : 51.507351);
    const [longitude, setLongitude] = useState(edit !== undefined ? parseFloat(edit.longitude) : -0.127758);
    const [radius, setRadius] = useState(edit !== undefined ? parseFloat(edit.radius) : 50)

    const [dialogVisible, setDialogVisible] = useState(false);

    useEffect(() => {
        autocompleteRef.current?.setAddressText(address);
      }, []);

    useEffect(() => {
        if (edit !== undefined)
            navigation.setOptions({
                headerRight: () => (
                <IconButton
                    icon="trash"
                    color={COLORS.white}
                    size={24}
                    onPress={() => { setDialogVisible(true) }}
                />
                ),
            });
      }, [navigation]);

    function deleteSubmit()
    {
        companyCtx.deleteLocation(companyCtx.company.company_id, edit.location_id).then(() => {
            navigation.goBack()
        })   
    }

    function submitHandler()
    {
        const nameIsValid = name.trim().length > 0;

        if (!nameIsValid) 
        {
            setFormlsInvalid({ name: !nameIsValid })

            return;
        }

        if (!!edit) {
            companyCtx.putLocation(companyCtx.company.company_id, edit.location_id, name, address, radius, latitude, longitude).then(() => {
                navigation.goBack()
            })
        } else {
            companyCtx.postLocation(companyCtx.company.company_id, name, address, radius, latitude, longitude).then(() => {
                navigation.goBack()
            })
        }
    }

    function updateInputValueHandler(inputType, enteredValue) {
        switch (inputType) {
          case 'name':
            setName(enteredValue);
            break;
        }
      }

    return (
        <FormContent
            title={"Location"}
        >
            <>
                <Input
                    label="Name"
                    onChangeText={updateInputValueHandler.bind(this, 'name')}
                    value={name}
                    error={formlsInvalid.name}
                />
                <View style={{ 
                    zIndex: 10,
                    flexShrink: 0,
                    flexGrow: 0,
                    height: 70,
                    overflow: 'visible',
                }}>
                    <GooglePlacesAutocomplete
                        placeholder='Search'
                        fetchDetails={true}
                        ref={autocompleteRef}
                        onPress={(data, details = null) => {
                            setLatitude(details.geometry.location.lat);
                            setLongitude(details.geometry.location.lng);
                            setAddress(data.description)
                        }
                        }
                        onFail={(data, details) => {
                            console.log(data, details);
                        }}
                        query={{
                            key: Constants.expoConfig.extra.google.credentials.places_api[Platform.OS],
                            language: 'en',
                        }}
                        textInputProps={{
                            InputComp: Input,
                            customStyle: {
                                width: '100%',
                                textAlign: 'auto'

                            },
                            label: "Address",
                            multiline: false
                        }}
                        styles={{
                            listView: {
                                position: 'absolute',
                                top: 60,
                                borderRadius: 5,
                            }
                        }}
                    >
                    </GooglePlacesAutocomplete>
                </View>
                <MapView
                    region={{
                        latitude: latitude,
                        longitude: longitude,
                        latitudeDelta: 0.003,
                        longitudeDelta: 0.003,
                    }}
                    zoomControlEnabled={true}
                    scrollEnabled={false}
                    rotateEnabled={false}
                    zoomTapEnabled={false}
                    showsIndoors={false}
                    latitude={latitude}
                    longitude={longitude}
                    provider={PROVIDER_GOOGLE}
                    showsUserLocation
                    style={{
                        zIndex: -4,
                        width: '100%',
                        height: 300,
                        borderRadius: SIZES.radius
                    }}

                >

                    <Marker
                        title={name}
                        coordinate={{
                            latitude: latitude,
                            longitude: longitude,
                        }}
                    ></Marker>
                    <Circle
                        center={{
                            latitude: latitude,
                            longitude: longitude,
                        }}
                        radius={radius}
                        fillColor={theme.colors.primary + "90"}
                    >
                    </Circle>
                </MapView>
                <Text style={{ ...FONTS.body3, marginTop: SIZES.base *2 }}>
                    Radius
                </Text>
                <Text style={{ ...FONTS.body3, marginTop: SIZES.base, alignSelf: 'center' }}>
                    {radius} meters
                </Text>
                <Slider
                    style={{width: '100%',  marginTop: SIZES.base, marginBottom: SIZES.base}}
                    minimumValue={20}
                    maximumValue={200}
                    step={10}
                    minimumTrackTintColor={theme.colors.primary}
                    value={ radius }
                    onValueChange={value => { setRadius(value) } } 
                />   
                <Button
                    onPress={submitHandler}
                    loading={companyCtx.loading}
                >
                    {'Save'}
                </Button>
                <DialogPopup
                    visible={dialogVisible}
                    onConfirm={deleteSubmit}
                    onDismiss={() => { setDialogVisible(false) }}
                    confirmText={'Delete'}
                    onCancel={() => { setDialogVisible(false) }}
                    cancelText={'Cancel'}
                    title={'Delete location?'}
                >
                </DialogPopup>
            </>
        </FormContent>
    );

}

export default LocationScreen;
