import { useContext, useEffect, useState } from 'react';
import { useNavigation } from '@react-navigation/native';

import { useTheme, Text } from 'react-native-paper';

import Button from '../../components/ui/Button';
import DialogPopup from '../../components/ui/DialogPopup';
import Input from '../../components/ui/Input';
import FormContent from '../../components/ui/FormContent';
import IconButton from '../../components/ui/IconButton';
import DropDown from '../../components/ui/DropDown';

import { AuthContext } from '../../store/auth-context';
import { CompanyContext } from '../../store/company-context';
import { COLORS, SIZES, FONTS } from '../../constants/theme'

function EmployeeScreen({ route }) {
    const theme = useTheme();

    const companyCtx = useContext(CompanyContext);
    const authCtx = useContext(AuthContext);
    const navigation = useNavigation()

    const [formlsInvalid, setFormlsInvalid] = useState({
        name: false,
        email: false,
        surname: false,
        department: false,
        position: false
    });

    const [showDropDown, setShowDropDown] = useState(false);
    const [showPositionDropDown, setPositionShowDropDown] = useState(false);
    const [showShiftDropDown, setShiftShowDropDown] = useState(false);
    const [showLocationDropDown, setLocationShowDropDown] = useState(false);
    const [showSuperiorDropDown, setSuperiorShowDropDown] = useState(false);

    const edit = route.params?.edit;
    const [name, setName] = useState(edit !== undefined ? edit.employee_name : '');
    const [email, setEmail] = useState(edit !== undefined ? edit.email : '');
    const [surname, setSurname] = useState(edit !== undefined ? edit.surname : '');
    const [departmentId, setDepartmentId] = useState(edit !== undefined ? edit.department_id : '');
    const [departments, setDepartments] = useState([]);
    const [positionId, setPositionId] = useState(edit !== undefined ? edit.position_id : '');
    const [positions, setPositions] = useState([]);
    const [shiftId, setShiftId] = useState(edit !== undefined ? edit.shift_id : '');
    const [shifts, setShifts] = useState([]);
    const [locationIds, setLocationIds] = useState(edit !== undefined && edit.location_ids ? edit.location_ids : []);
    const [locations, setLocations] = useState([]);
    const [superiorUsername, setSuperiorUsername] = useState(edit !== undefined ? edit.superior_username : '');
    const [superiors, setSuperiors] = useState([]);
    const [dialogVisible, setDialogVisible] = useState(false);
        
    useEffect(() => {
        const companyId = authCtx.user.attributes['custom:company_id']
        
        companyCtx.fetchDepartments(companyId)     
        .then((departments) => {
            setDepartments(departments.map(department => {
                return {
                    label: department.department_name,
                    value: department.department_id
                }
            }))
        }) 
        companyCtx.fetchPositions(companyId)      
        .then((positions) => {
            setPositions(positions.map(position => {
                return {
                    label: position.position_name,
                    value: position.position_id
                }
            }))
        })
        companyCtx.fetchShifts(companyId)
        .then((shifts) => {
            setShifts(shifts.map(shift => {
                return {
                    label: shift.shift_name,
                    value: shift.shift_id
                }
            }))
        })
        companyCtx.fetchLocations(companyId)
        .then((locations) => {
            setLocations(locations.map(location => {
                return {
                    label: location.location_name,
                    value: location.location_id
                }
            }))
        })
        companyCtx.fetchEmployees(companyId)
        .then((employees) => {
            setSuperiors(employees.filter(e => edit === undefined ? true : e.username !== edit.username).map(employee => {
                return {
                    label: employee.employee_name + ' ' + employee.surname + ' (' + employee.email + ')',
                    value: employee.username
                }
            }))
        })
    }, [])

    useEffect(() => {
        if (edit !== undefined)
            navigation.setOptions({
                headerRight: () => (
                <IconButton
                    icon="trash"
                    color={COLORS.white}
                    size={24}
                    onPress={() => { setDialogVisible(true) }}
                />
                ),
            });
      }, [navigation]);

    function deleteSubmit() {
        // TODO: Implement the deletion from cognito
        companyCtx.deleteEmployee(companyCtx.company.company_id, edit.username)
        navigation.goBack()
    }

    function submitHandler() {
        const emailTransformed = email.trim().toLowerCase()

        const emailIsValid = emailTransformed.includes('@');
        const nameIsValid = name.trim().length > 0;
        const surnameIsValid = surname.trim().length > 0;
        const departmentIsValid = departmentId !== undefined && departmentId.trim().length > 0;
        const positionIsValid = positionId !== undefined && positionId.trim().length > 0;

        if (!nameIsValid || !surnameIsValid || !emailIsValid || !departmentIsValid || !positionIsValid) {
            setFormlsInvalid({ name: !nameIsValid, surname: !surnameIsValid, email: !emailIsValid, department: !departmentIsValid, position: !positionIsValid })

            return;
        }

        if (!!edit) {
            companyCtx.putEmployee(companyCtx.company.company_id, edit.username, name, surname, departmentId, positionId, shiftId, locationIds, superiorUsername).then(() => {
                navigation.goBack()
            })
        } else {
            authCtx.postEmployeeCognito(companyCtx.company.company_id, emailTransformed).then(user => {
                companyCtx.postEmployee(companyCtx.company.company_id, user["Username"], name, surname, departmentId, positionId, shiftId, locationIds, superiorUsername, emailTransformed, user["UserStatus"]).then(() => {
                    navigation.goBack()
                })
            })
        }
    }

    function updateInputValueHandler(inputType, enteredValue) {
        switch (inputType) {
            case 'email':
                setEmail(enteredValue);
                break;
            case 'name':
                setName(enteredValue);
                break;
            case 'surname':
                setSurname(enteredValue);
                break;
        }
    }

    return (
        <FormContent 
            title={"Employee"}
        >
            <>
                <Input
                    label="Email"
                    onChangeText={updateInputValueHandler.bind(this, 'email')}
                    value={email}
                    error={formlsInvalid.email}
                    editable={edit === undefined}
                    required
                />
                <Input
                    label="Name"
                    onChangeText={updateInputValueHandler.bind(this, 'name')}
                    value={name}
                    error={formlsInvalid.name}
                    required
                />
                <Input
                    label="Surname"
                    onChangeText={updateInputValueHandler.bind(this, 'surname')}
                    value={surname}
                    error={formlsInvalid.surname}
                    required
                />
                <Text style={{ marginBottom: SIZES.base, ...FONTS.body3 }}>
                    Organization
                </Text>
                <DropDown
                    label={"Department*"}
                    mode={"outlined"}
                    visible={showDropDown}
                    showDropDown={() => setShowDropDown(true)}
                    onDismiss={() => setShowDropDown(false)}
                    value={departmentId}
                    setValue={setDepartmentId}
                    list={departments} 
                    inputProps={{
                        style: {
                            marginBottom: 10
                        }
                    }}    
                />
                <DropDown
                    label={"Role*"}
                    mode={"outlined"}
                    visible={showPositionDropDown}
                    showDropDown={() => setPositionShowDropDown(true)}
                    onDismiss={() => setPositionShowDropDown(false)}
                    value={positionId}
                    setValue={setPositionId}
                    list={positions} 
                    inputProps={{
                        style: {
                            marginBottom: 10
                        }
                    }} 
                />
                <DropDown
                    label={"Shift"}
                    mode={"outlined"}
                    visible={showShiftDropDown}
                    showDropDown={() => setShiftShowDropDown(true)}
                    onDismiss={() => setShiftShowDropDown(false)}
                    value={shiftId}
                    setValue={setShiftId}
                    list={shifts} 
                    inputProps={{
                        style: {
                            marginBottom: 10
                        }
                    }} 
                />
                <DropDown
                    label={"Locations"}
                    mode={"outlined"}
                    visible={showLocationDropDown}
                    showDropDown={() => setLocationShowDropDown(true)}
                    onDismiss={() => setLocationShowDropDown(false)}
                    value={locationIds?.join(',')}
                    setValue={(value) => { setLocationIds(value.split(',').filter(x => x))}}
                    list={locations} 
                    multiSelect={true}
                />
                <DropDown
                    label={"Superior"}
                    mode={"outlined"}
                    visible={showSuperiorDropDown}
                    showDropDown={() => setSuperiorShowDropDown(true)}
                    onDismiss={() => setSuperiorShowDropDown(false)}
                    value={superiorUsername}
                    setValue={setSuperiorUsername}
                    list={superiors} 
                />
                <Button
                    onPress={submitHandler}
                    loading={companyCtx.loading}
                >
                    {'Save'}
                </Button>
                <DialogPopup
                    visible={dialogVisible}
                    onConfirm={deleteSubmit}
                    onDismiss={() => { setDialogVisible(false) }}
                    confirmText={'Delete'}
                    onCancel={() => { setDialogVisible(false) }}
                    cancelText={'Cancel'}
                    title={'Delete employee?'}
                >
                </DialogPopup>
            </>
        </FormContent>
    );

}

export default EmployeeScreen;