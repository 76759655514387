import { useContext, useState } from 'react';
import { Alert } from 'react-native';
import { useNavigation } from '@react-navigation/native';

import ForgotPasswordContent from '../components/Auth/ForgotPasswordContent';
import { AuthContext } from '../store/auth-context';

function ForgotPasswordScreen() {
  const authCtx = useContext(AuthContext);
  const navigation = useNavigation();

  async function submitHandler({ email }){
      await authCtx.forgotPassword(email);
      Alert.alert(
        'Check your email!',
        "We've sent an email to " + email + ". Didn't receive it? Check your spam folder or try again."
      );

      navigation.replace('SignIn')
  }
    
  return <ForgotPasswordContent 
    onSubmit={submitHandler} 
  />;
}

export default ForgotPasswordScreen;