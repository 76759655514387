import { useState } from 'react';
import { Alert, StyleSheet, View, Text }  from 'react-native';
import { useTheme } from 'react-native-paper';
import Toast from 'react-native-root-toast';

import CodeForm from './CodeForm';

import * as Animatable from 'react-native-animatable'


function CodeContent({ onCodeConfirmation, onCancel, loading }) {
  const theme = useTheme();

  const [credentialsInvalid, setCredentialsInvalid] = useState({
    code: false,
  });

  function submitHandler(credentials) {
    let { code } = credentials;

    code = code.trim();

    const codeIsValid = code.length > 0;
    
    if (
      !codeIsValid
    ) {
      Toast.show('Invalid input. Please check your entered code.', {
        duration: Toast.durations.LONG,
        position: Toast.positions.BOTTOM,
      });
      setCredentialsInvalid({
        code: !codeIsValid
      });
      return;
    }
    onCodeConfirmation({ code });
  }

  return (
    <View style={{
      backgroundColor: theme.colors.tertiary,
      ...styles.authContent}}>
      <Animatable.View animation="fadeInLeft" delay={500} style={styles.containerHeader}>
        <Text style={styles.message}>Confirm code</Text>
      </Animatable.View>

    <Animatable.View animation="fadeInUp" style={{ backgroundColor: theme.colors.background, ...styles.containerForm}}>
      <CodeForm
        onSubmit={submitHandler}
        loading={loading}
        credentialsInvalid={credentialsInvalid}
      />
    </Animatable.View>
     </View>
  );
}

export default CodeContent;

const styles = StyleSheet.create({
  authContent: {
    flex: 1,
  },
  containerHeader: {
    marginTop: '14%',
    marginBottom: '8%',
    paddingStart: '5%'
  },
  message: {
    fontSize: 28,
    fontWeight: 'bold',
    color: '#FFF'
  },
  containerForm: {
    flex:1,
    borderTopRightRadius: 25,
    borderTopLeftRadius: 25,
    paddingStart: '5%',
    paddingEnd: '5%'
  },
});
