import { useContext, useEffect,  useState } from 'react';
import { useTheme, Button, Dialog, Portal } from 'react-native-paper';

import { StyleSheet, Text, View, Image, ImageBackground, TouchableOpacity,FlatList, ScrollView } from 'react-native';
import { Ionicons } from '@expo/vector-icons'

import { COLORS, SIZES, FONTS } from '../../constants/theme'

function DialogPopup({
    visible = false, 
    onConfirm, 
    onCancel, 
    onDismiss,
    title, 
    message = '', 
    confirmText, 
    cancelText, 
    }) {

    const theme = useTheme();

    return (
        <Portal>
            <Dialog onDismiss={onDismiss} visible={visible} >
                <Dialog.Title style={{ ... FONTS.h2 }}>{title}</Dialog.Title>
                {message !== '' &&
                    <Dialog.Content>
                        <Text style={{ ... FONTS.body3 }}>{message}</Text>
                    </Dialog.Content>
                }
                <Dialog.Actions>
                    <Button onPress={() => onCancel()}>{cancelText}</Button>
                    <Button onPress={() => onConfirm()}>{confirmText}</Button>
                </Dialog.Actions>
            </Dialog>
        </Portal>
    )
} 

export default DialogPopup;