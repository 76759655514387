import { API } from 'aws-amplify';
import moment from 'moment';
import { createContext, useState } from 'react';
import Moment from 'react-moment';

export const CompanyContext = createContext({
  company: null,
  departments: null,
  positions: null,
  shifts: null,
  locations: null,
  employees: null,
  records: null,
  loading: false,
  setLoading: (isLoading) => {},
  fetchCompany: (companyId) => {},
  getCompany: (companyId) => {},
  putCompany: (companyId, recordPhoto, recordEditable) => {},
  fetchDepartments: (companyId) => {},
  getDepartments: (companyId) => {},
  postDepartment: (companyId, name) => {},
  putDepartment: (companyId, id, name) => {},
  deleteDepartment: (companyId, id) => {},
  fetchPositions: (companyId) => {},
  getPosition: (companyId, id) => {},
  getPositions: (companyId) => {},
  postPosition: (companyId, name, permissions) => {},
  putPosition: (companyId, id, name, permissions) => {},
  deletePosition: (companyId, id) => {},
  fetchShifts: (companyId) => {},
  getShift: (companyId, id) => {},
  getShifts: (companyId) => {},
  postShift: (companyId, name, fromTime, toTime, shiftDays, restrictionClockInEnabled, restrictionClockInMinutes) => {},
  putShift: (companyId, id, name, fromTime, toTime, shiftDays, restrictionClockInEnabled, restrictionClockInMinutes) => {},
  deleteShift: (companyId, id, name) => {},
  fetchLocations: (companyId) => {},
  getLocation:  (companyId, id) => {},
  getLocations: (companyId) => {},
  postLocation: (companyId, name, address, radius, latitude, longitude) => {},
  putLocation: (companyId, id, name, address, radius, latitude, longitude) => {},
  deleteLocation: (companyId, id) => {},
  fetchEmployees: (companyId) => {},
  getEmployee: (companyId, id) => {},
  getEmployees: (companyId) => {},
  postEmployee: (companyId, username, name, surname, departmentId, positionId, shiftId, locationIds, superiorUsername, email, userStatus) => {},
  putEmployee: (companyId, username, name, surname, departmentId, positionId, shiftId, locationIds, superiorUsername) => {},
  putEmployeeStatus: (companyId, username, userStatus) => {},
  putEmployeeExpoPushToken: (companyId, username, expoPushToken) => {},
  deleteEmployee: (companyId, id) => {}, 
  fetchRecords: (companyId, usernames) => {},
  getRecords: (companyId, usernames, dateStart, dateEnd, onlyClockIn = false) => {},
  postRecord: (companyId, username, employee, department, position, shift, locations, currentLocation, superior) => {},
  putRecord: (companyId, recordId, dateStart, dateEnd) => {},
  putRecordRequest: (companyId, recordId, dateStart, dateEnd, superior, title, body) => {},
  fetchKiosks: (companyId) => {},
  getKiosks: (companyId) => {},
  postKiosk: (companyId, name) => {},
  putKiosk: (companyId, id, name) => {},
  deleteKiosk: (companyId, id) => {},
  postStripeCheckoutSession: (companyId, stripeCustomerId, priceId, quantity, trialPeriodDays) => {},
  getStripeSubscriptions: (stripeCustomerId) => {},
  postStripeBillingPortal: (stripeCustomerId) => {},
  postOnboarding: (companyName, timeZone, username, email, name, surname, access_token) => {},
});

function CompanyContextProvider({ children }) {
  const [company, setCompany] = useState(null);
  const [departments, setDepartments] = useState(null);
  const [positions, setPositions] = useState(null);
  const [shifts, setShifts] = useState(null);
  const [locations, setLocations] = useState(null);
  const [employees, setEmployees] = useState(null);
  const [records, setRecords] = useState(null);
  const [kiosks, setKiosks] = useState(null);
  const [loading, setLoading] = useState(false);

    async function fetchCompany(companyId){
        var company = await getCompany(companyId)
        setCompany(company)
        
        console.log('Company Context: fetchCompany(' + companyId + ')')

        return company;
    }

    async function getCompany(companyId) {
        setLoading(true)

        console.log('Company Context: getCompany(' + companyId + ')')

        return API.get("company", "/company",
            {
                queryStringParameters:
                {
                    'company_id': companyId
                }
            })
            .then((company) => {
                Moment.globalTimezone = company.time_zone;

                return company

                //TODO: Company subscription
                // if (company.subscription !== undefined && company.subscription !== null) {
                //     var plan = company.subscription['display_items'][0]['plan']
                //     company.planId = plan['id'];
                //     company.productId = plan['product'];
                //     company.subscriptionId = company.subscription['subscription'];

                //     API.get("stripe", "/product",
                //         {
                //             queryStringParameters:
                //             {
                //                 'product_id': company.productId
                //             }
                //         })
                //         .then((product) => {
                //             company.product = product.metadata

                //             resolve(company);
                //         })
                // }
                // else {
                //     company.product = config.subscriptions.free

                //     resolve(company);
                // }
            }).finally(() => {
                setLoading(false)
            })
    }

    async function putCompany(companyId, recordPhoto, recordEditable) {
        setLoading(true)

        console.log('Company Context: putCompany(' + companyId + ', ' + recordPhoto + ', ' + recordEditable + ')')

        API.put("company", "/company", {
            body: {
                company_id: companyId,
                record_photo: recordPhoto,
                record_editable: recordEditable
            }
        }).then((company) => {
            setCompany(company)
        }).finally(() => {
            setLoading(false)
        })
    }

    async function fetchDepartments(companyId)
    {
        return getDepartments(companyId)
        .then((departments) => {
            setDepartments(departments)
            return departments
        })
    }

    async function getDepartments(companyId) {
        setLoading(true)

        console.log('Company Context: getDepartments(' + companyId + ')')
        
        return API.get("uclocker", "/department",
            {
                queryStringParameters:
                {
                    'company_id': companyId
                }
            })
            .then((departments) => {
                return departments
            }).finally(() => {
                setLoading(false)
            })
    }

    async function postDepartment(companyId, name){
        setLoading(true)

        console.log('Company Context: postDepartment(' + companyId + ', ' + name + ')')
        
        API.post("uclocker", "/department", {
            body: {
                company_id: companyId,
                department_name: name
            }
        }).then((department) => {
            setDepartments([...departments, department])
        }).finally(() => {
            setLoading(false)
        })
    }

    async function putDepartment(companyId, id, name){
        setLoading(true)

        console.log('Company Context: putDepartment(' + companyId + ', ' + id + ', ' + name + ')')
        
        API.put("uclocker", "/department", {
            body: {
                company_id: companyId,
                department_id: id,
                department_name: name
            }
        }).then(() => {
            setDepartments(departments.map(department => department.department_id === id ?  { ...department, department_name: name } : department))
        }).finally(() => {
            setLoading(false)
        })
    }

    async function deleteDepartment(companyId, id){
        setLoading(true)

        console.log('Company Context: deleteDepartment(' + companyId + ', ' + id + ')')

        API.del("uclocker", "/department", {
            body: {
                company_id: companyId,
                department_id: id
            }
        }).then(() => {
            setDepartments(departments.filter(department => department.department_id !== id))
        }).finally(() => {
            setLoading(false)
        })
    }

    async function fetchPositions(companyId)
    {
        return getPositions(companyId)
        .then((positions) => {
            setPositions(positions)
            return positions
        }) 
    }

    async function getPosition(companyId, id) {
        setLoading(true)

        console.log('Company Context: getPosition(' + companyId + ', ' + id + ')')

        return API.get("uclocker", "/position",
            {
                queryStringParameters:
                {
                    'company_id': companyId,
                    'position_id': id
                }
            })
            .then((positions) => {
                setLoading(false)

                return positions
            }).finally(() => {
                setLoading(false)
            })
    }

    async function getPositions(companyId) {
        setLoading(true)

        console.log('Company Context: getPositions(' + companyId + ')')

        return API.get("uclocker", "/position",
            {
                queryStringParameters:
                {
                    'company_id': companyId
                }
            })
            .then((positions) => {
                return positions
            }).finally(() => {
                setLoading(false)
            })
    }

    async function postPosition(companyId, name, permissions){
        setLoading(true)

        console.log('Company Context: postPosition(' + companyId + ', ' + name + ', ' + permissions + ')')

        API.post("uclocker", "/position", {
            body: {
                company_id: companyId,
                position_name: name,
                position_permissions: permissions
            }
        }).then((position) => {
            setPositions([...positions, position])

            setLoading(false)
        }).finally(() => {
            setLoading(false)
        })
    }

    async function putPosition(companyId, id, name, permissions){
        setLoading(true)

        console.log('Company Context: putPosition(' + companyId + ', ' + id + ', ' + name + ', ' + permissions + ')')
        
        API.put("uclocker", "/position", {
            body: {
                company_id: companyId,
                position_id: id,
                position_name: name,
                position_permissions: permissions
            }
        }).then(() => {
            setPositions(positions.map(position => position.position_id === id ? { ...position, position_name: name, position_permissions: permissions} : position))

            setLoading(false)
        }).finally(() => {
            setLoading(false)
        })
    }

    async function deletePosition(companyId, id){
        setLoading(true)

        console.log('Company Context: deletePosition(' + companyId + ', ' + id + ')')

        API.del("uclocker", "/position", {
            body: {
                company_id: companyId,
                position_id: id
            }
        }).then(() => {
            setPositions(positions.filter(position => position.position_id !== id))

            setLoading(false)
        }).finally(() => {
            setLoading(false)
        })
    }

    async function fetchLocations(companyId)
    {
        return getLocations(companyId)
        .then((locations) => {
            setLocations(locations)
            return locations
        })
    }

    async function getLocations(companyId) {
        setLoading(true)

        console.log('Company Context: getLocations(' + companyId + ')')

        return API.get("uclocker", "/location",
            {
                queryStringParameters:
                {
                    'company_id': companyId
                }
            })
            .then((locations) => {
                return locations
            }).finally(() => {
                setLoading(false)
            })
    
    }

    async function getLocation(companyId, id) {
        setLoading(true)

        console.log('Company Context: getLocation(' + companyId + ', ' + id + ')')

        return API.get("uclocker", "/location",
            {
                queryStringParameters:
                {
                    'company_id': companyId,
                    'position_id': id
                }
            })
            .then((locations) => {
                return locations
            }).finally(() => {
                setLoading(false)
            })
    }

    async function postLocation(companyId, name, address, radius, latitude, longitude) {
        setLoading(true)

        console.log('Company Context: postLocation(' + companyId + ', ' + name + ', ' + address + ', ' + radius + ', ' + latitude + ', ' + longitude + ')')
        
        API.post("uclocker", "/location", {
            body: {
                company_id: companyId,
                location_name: name,
                address: address,
                radius: radius.toString(),
                latitude: latitude.toString(),
                longitude: longitude.toString()
            }
        }).then((location) => {
            setLocations([...locations, location])
        }).finally(() => {
            setLoading(false)
        })
    }

    async function putLocation(companyId, id, name, address, radius, latitude, longitude) {
        setLoading(true)

        console.log('Company Context: putLocation(' + companyId + ', ' + id + ', ' + name + ', ' + address + ', ' + radius + ', ' + latitude + ', ' + longitude + ')')
        
        API.put("uclocker", "/location", {
            body: {
                company_id: companyId,
                location_id: id,
                location_name: name,
                address: address,
                radius: radius.toString(),
                latitude: latitude.toString(),
                longitude: longitude.toString()
            }
        }).then(() => {
            setLocations(locations.map(location => location.location_id === id ? { ...location, location_name: name, address: address, radius: radius, latitude: latitude, longitude: longitude} : location))
        }).finally(() => {
            setLoading(false)
        })
    }

    async function deleteLocation(companyId, id){
        setLoading(true)

        console.log('Company Context: deleteLocation(' + companyId + ', ' + id + ')')

        API.del("uclocker", "/location", {
            body: {
                company_id: companyId,
                location_id: id
            }
        }).then(() => {
            setLocations(locations.filter(location => location.location_id !== id))
        }).finally(() => {
            setLoading(false)
        })

    }

    async function fetchShifts(companyId)
    {
        return getShifts(companyId)
        .then((shifts) => { 
            setShifts(shifts); 
            return shifts 
        })
    }

    async function getShift(companyId, id) {
        setLoading(true)

        console.log('Company Context: getShift(' + companyId + ', ' + id + ')')

        return API.get("shift", "/shift",
            {
                queryStringParameters:
                {
                    'company_id': companyId,
                    'shift_id': id
                }
            })
            .then((shifts) => {
                
                return shifts
            }).finally(() => {
                setLoading(false)
            })
    }  

    async function getShifts(companyId) {
        setLoading(true)

        console.log('Company Context: getShifts(' + companyId + ')')

        return API.get("shift", "/shift",
            {
                queryStringParameters:
                {
                    'company_id': companyId
                }
            })
            .then((shifts) => {
                return shifts
            }).finally(() => {
                setLoading(false)
            })
    }

    async function postShift(companyId, name, fromTime, toTime, shiftDays, restrictionClockInEnabled, restrictionClockInMinutes) {
        setLoading(true)

        console.log('Company Context: postShift(' + companyId + ', ' + name + ', ' + fromTime + ', ' + toTime + ', ' + shiftDays + ', ' + restrictionClockInEnabled + ', ' + restrictionClockInMinutes +  ')')
        
        API.post("shift", "/shift", {
            body: {
                company_id: companyId,
                shift_name: name,
                shift_time: {
                    from: moment.tz(fromTime, Moment.globalTimezone).utc(),
                    to: moment.tz(toTime, Moment.globalTimezone).utc(),
                },
                shift_days: shiftDays,
                shift_options:
                {
                    restriction: {
                        clock_in: {
                            enabled: restrictionClockInEnabled,
                            minutes: restrictionClockInMinutes
                        }
                    }
                }
            }
        }).then((shift) => {
            console.log(shift)
            setShifts([...shifts, shift])

            setLoading(false)
        }).finally(() => {
            setLoading(false)
        })
    }

    async function putShift(companyId, id, name, fromTime, toTime, shiftDays, restrictionClockInEnabled, restrictionClockInMinutes) {
        setLoading(true)

        console.log('Company Context: putShift(' + companyId + ', ' + id + ', ' + name + ', ' + fromTime + ', ' + toTime + ', ' + shiftDays + ', ' + restrictionClockInEnabled + ', ' + restrictionClockInMinutes +  ')')

        API.put("shift", "/sf/shift", {
            body: {
                company_id: companyId,
                shift_id: id,
                shift_name: name,
                shift_time: {
                    from: moment.tz(fromTime, Moment.globalTimezone).utc(),
                    to: moment.tz(toTime, Moment.globalTimezone).utc(),
                },
                shift_days: shiftDays,
                shift_options:
                {
                    restriction: {
                        clock_in: {
                            enabled: restrictionClockInEnabled,
                            minutes: restrictionClockInMinutes
                        }
                    }
                }
            }
        }).then(() => {
            setShifts(shifts.map(shift => shift.shift_id === id ? 
                { 
                    ...shift, 
                    shift_name: name, 
                    shift_time: { 
                        from: fromTime, 
                        to: toTime 
                    }, 
                    shift_days: shiftDays, 
                    shift_options: 
                    { 
                        restriction: { 
                            clock_in: { 
                                enabled: restrictionClockInEnabled, 
                                minutes: restrictionClockInMinutes  
                            }
                        } 
                    } 
                } 
                : 
                shift
            ))

            setLoading(false)
        }).finally(() => {
            setLoading(false)
        })
    }
    
    async function deleteShift(companyId, id, name) {
        setLoading(true)

        console.log('Company Context: deleteShift(' + companyId + ', ' + id + ', ' + name + ')')

        API.del("shift", "/shift", {
            body: {
                company_id: companyId,
                shift_id: id,
				shift_name: name
            }
        }).then(() => {
            setShifts(shifts.filter(shift => shift.shift_id !== id))

            setLoading(false)
        }).finally(() => {
            setLoading(false)
        })
    }

    async function fetchEmployees(companyId)
    {
        return getEmployees(companyId)
        .then((employees) => { 
            setEmployees(employees); 
            return employees 
        })   
    }

    async function getEmployee(companyId, id) {
        setLoading(true)

        console.log('Company Context: getEmployee(' + companyId + ', ' + id + ')')

        return API.get("employee", "/employee",
            {
                queryStringParameters:
                {
                    'company_id': companyId,
                    'username': id
                }
            })
            .then((employee) => {
                setLoading(false)

                return employee
            }).finally(() => {
                setLoading(false)
            })
    }

    async function getEmployees(companyId) {
        setLoading(true)

        console.log('Company Context: getEmployees(' + companyId + ')')

        return API.get("employee", "/employees",
            {
                queryStringParameters:
                {
                    'company_id': companyId
                }
            })
            .then((employees) => {
                return employees
            }).finally(() => {
                setLoading(false)
            })
    }

    async function postEmployee(companyId, username, name, surname, departmentId, positionId, shiftId, locationIds, superiorUsername, email, userStatus){
        setLoading(true)

        console.log('Company Context: postEmployee(' + companyId + ', ' + username + ', ' + name + ', ' + surname + ', ' + departmentId + ', ' + positionId + ', ' + shiftId + ', ' + locationIds + ', ' + superiorUsername + ', ' + email + ', ' + userStatus + ')')

        API.post("employee", "/employee", {
            body: {
                username: username,
                user_status: userStatus,
                company_id: companyId,                
                email: email,
                name: name,
                surname: surname,
                department_id: departmentId,
                position_id: positionId,
                shift_id: shiftId,
                location_ids: locationIds,
                superior_username: superiorUsername,
                is_root_user: false,
            }
        }).then((employee) => {
            setEmployees([...employees, employee])

            setLoading(false)
        }).finally(() => {
            setLoading(false)
        })
    }

    async function putEmployee(companyId, username, name, surname, departmentId, positionId, shiftId, locationIds, superiorUsername){
        setLoading(true)

        console.log('Company Context: putEmployee(' + companyId + ', ' + username + ', ' + name + ', ' + surname + ', ' + departmentId + ', ' + positionId + ', ' + shiftId + ', ' + locationIds + ', ' + superiorUsername + ')')

        API.put("employee", "/employee", {
            body: {
                username: username,
                company_id: companyId,      
                name: name,
                surname: surname,
                department_id: departmentId,
                position_id: positionId,
                shift_id: shiftId,
                location_ids: locationIds,
                superior_username: superiorUsername,
            }
        }).then(() => {
            setEmployees(employees.map(employee => employee.username === username ? { ...employee, name: name, surname: surname, department_id: departmentId, position_id: positionId, shift_id: shiftId, location_ids: locationIds, superior_username: superiorUsername} : employee))

            setLoading(false)
        }).finally(() => {
            setLoading(false)
        })
    }

    async function putEmployeeStatus(companyId, username, userStatus){
        setLoading(true)

        console.log('Company Context: putEmployeeStatus(' + companyId + ', ' + username + ', ' + userStatus + ')')
        
        API.put("employee", "/employee", {
            body: {
                username: username,
                company_id: companyId,      
                user_status: userStatus,
            }
        }).then(() => {
            setEmployees(employees.map(employee => employee.username === username ? { ...employee, user_status: userStatus} : employee))
            
            setLoading(false)
        }).finally(() => {
            setLoading(false)
        })
    }

    async function putEmployeeExpoPushToken(companyId, username, expoPushToken){
        setLoading(true)

        console.log('Company Context: putEmployeeStatus(' + companyId + ', ' + username + ', ' + expoPushToken + ')')
        
        API.put("employee", "/employee", {
            body: {
                username: username,
                company_id: companyId,      
                expo_push_token: expoPushToken,
            }
        }).then(() => {
            setEmployees(employees?.map(employee => employee.username === username ? { ...employee, expo_push_token: expoPushToken} : employee))
            
            setLoading(false)
        }).finally(() => {
            setLoading(false)
        })
    }

    async function deleteEmployee(companyId, id){
        setLoading(true)

        console.log('Company Context: deleteEmployee(' + companyId + ', ' + id + ')')

        API.del("employee", "/employee", {
            body: {
                company_id: companyId,
                username: id,
                delete: true
            }
        }).then(() => {
            setEmployees(employees.filter(employee => employee.employee_id !== id))

            setLoading(false)
        }).finally(() => {
            setLoading(false)
        })
    }

    async function fetchRecords(companyId, usernames) {
        var dateStartOf = moment.tz(Moment.globalTimezone).startOf('day').utc().format()
        var dateEndOf = moment.tz(Moment.globalTimezone).endOf('day').utc().format()

        var records = await getRecords(companyId, usernames, dateStartOf, dateEndOf)
        setRecords(records)

        return records
    }

    async function postRecord(companyId, username, employee, department, position, shift, locations, currentLocation, superior){
        setLoading(true)

        console.log('Company Context: postRecord(' + companyId + ', ' + username + ', ' + employee + ', ' + department + ', ' + position + ', ' + shift +  ', ' + locations + ', ' + currentLocation + ', ' + superior +')')

        return API.post("record", "/sf/record", {
            body: {
                company_id: companyId,
                employee_id: username,
                employee: employee,
                department: department,
                position: position,
                shift: shift,
                locations: locations,
                location: currentLocation,
                superior: superior
            }
        }).then((record) => {
            setRecords([...records, record])

            return record
        }).finally(() => {
            setLoading(false)
        })
    }

    async function putRecord(companyId, recordId, dateStart, dateEnd) {
        setLoading(true)

        console.log('Company Context: putRecord(' + companyId + ', ' + recordId + ', ' + dateStart + ', ' + dateEnd +')')

        return API.put("record", "/sf/record", {
            body: {
                company_id: companyId,
                record_id: recordId,
                clock_in:  dateStart,
                clock_out: dateEnd
            }
        }).then((record) => {
            return JSON.parse(record.output);  //Up to now, Step function return a wrap around the record object
        }).finally(() => {
            setLoading(false)
        })
    }

    async function putRecordRequest(companyId, recordId, dateStart, dateEnd, employee, superior, title, body) {
        setLoading(true)

        console.log('Company Context: putRecord(' + companyId + ', ' + recordId + ', ' + dateStart + ', ' + dateEnd + ', ' + employee + ', ' + superior + ', ' + title + ', ' + body +')')

        return API.put("record", "/sf_standard/record", {
            body: {
                company_id: companyId,
                record_id: recordId,
                clock_in:  dateStart,
                clock_out: dateEnd,
                employee: employee,
                superior: superior,
                message: {
                    title: title,
                    body: body
                }
            }
        }).then((record) => {
            console.log(record)
            // return JSON.parse(record.output);  //Up to now, Step function return a wrap around the record object
        }).finally(() => {
            setLoading(false)
        })
    }

    async function getRecords(companyId, usernames, dateStart, dateEnd, onlyClockIn = false) {
        setLoading(true)

        console.log('Company Context: getRecords(' + companyId + ', ' + JSON.stringify(usernames) + ', ' + dateStart + ', ' + dateEnd + ', ' + onlyClockIn + ')')

        return API.get("record", "/record",
            {
                queryStringParameters:
                {
                    'company_id': companyId,
                    'employee_ids': JSON.stringify(usernames),
                    'date_start': dateStart,
                    'date_end': dateEnd,
                    'only_clock_in': onlyClockIn
                }
            })
            .then((records) => {
                setLoading(false)

                return records
            }).finally(() => {
                setLoading(false)
            })
    }

    async function fetchKiosks(companyId)
    {
        return getKiosks(companyId)
        .then((kiosks) => {
            setKiosks(kiosks)
            return kiosks
        })
    }

    async function getKiosks(companyId) {
        setLoading(true)

        console.log('Company Context: getKiosks(' + companyId + ')')
        
        return API.get("kiosk", "/kiosk",
            {
                queryStringParameters:
                {
                    'company_id': companyId
                }
            })
            .then((kiosks) => {
                return kiosks
            }).finally(() => {
                setLoading(false)
            })
    }

    async function postKiosk(companyId, name){
        setLoading(true)

        console.log('Company Context: postKiosk(' + companyId + ', ' + name + ')')
        
        API.post("kiosk", "/kiosk", {
            body: {
                company_id: companyId,
                kiosk_name: name
            }
        }).then((kiosk) => {
            setKiosks([...kiosks, kiosk])
        }).finally(() => {
            setLoading(false)
        })
    }

    async function putKiosk(companyId, id, name){
        setLoading(true)

        console.log('Company Context: putKiosk(' + companyId + ', ' + id + ', ' + name + ')')
        
        API.put("kiosk", "/kiosk", {
            body: {
                company_id: companyId,
                kiosk_id: id,
                kiosk_name: name
            }
        }).then(() => {
            setKiosks(kiosks.map(kiosk => kiosk.kiosk_id === id ?  { ...kiosk, kiosk_name: name } : kiosk))
        }).finally(() => {
            setLoading(false)
        })
    }

    async function deleteKiosk(companyId, id){
        setLoading(true)

        console.log('Company Context: deleteKiosk(' + companyId + ', ' + id + ')')

        API.del("kiosk", "/kiosk", {
            body: {
                company_id: companyId,
                kiosk_id: id
            }
        }).then(() => {
            setKiosks(kiosks.filter(kiosk => kiosk.kiosk_id !== id))
        }).finally(() => {
            setLoading(false)
        })
    }

    async function postStripeCheckoutSession(companyId, stripeCustomerId, priceId, quantity, trialPeriodDays){
        setLoading(true)
        
        console.log('Company Context: postStripeCheckoutSession(' + companyId + ', ' + stripeCustomerId + ', ' + priceId +', ' + quantity + ', ' + trialPeriodDays + ')')

        return API.post("stripe", "/checkout/session", {
            body: {
              customer_id: stripeCustomerId,
              company_id: companyId,
              price_id: priceId,
              quantity: quantity,
              trial_period_days: trialPeriodDays
            }
          }).then((session) => {
            return session
          }).finally(() => {
            setLoading(false)
          });
    }

    async function getStripeSubscriptions(stripeCustomerId) {
        setLoading(true)

        console.log('Company Context: getStripeSubscriptions(' + stripeCustomerId + ')')

        return API.get("stripe", "/subscriptions",
            {
                queryStringParameters:
                {
                    'customer_id': stripeCustomerId
                }
            })
            .then((subscriptions) => {
                return subscriptions
            }).finally(() => {
                setLoading(false)
            })
    }

    async function postStripeBillingPortal(stripeCustomerId) {
        setLoading(true)

        console.log('Company Context: postStripeBillingPortal(' + stripeCustomerId + ')')

        return API.post("stripe", "/billing/portal",
            {
                body: {
                    customer_id: stripeCustomerId,
                }
            })
            .then((session) => {
                return session
            }).finally(() => {
                setLoading(false)
            })
    }

    async function postOnboarding(companyName, timeZone, username, email, name, surname, access_token){
        setLoading(true)

        console.log('Company Context: postOnboarding(' + companyName + ', ' + timeZone + ', ' + username + ', ' + email + ', ' + name + ', ' + surname +  ', ' + access_token +')')

        return API.post("onboarding", "/sf/onboarding", {
            body: {
              company_name: companyName,
              time_zone: timeZone,
              record_editable: false,
              record_photo: false,
              username: username,
              email: email,
              name: name,
              surname: surname,
              is_root_user: true,
              access_token: access_token
            }
          }).then(() => {
            return;
        }).finally(() => {
            setLoading(false)
        })
    }

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

  const value = {
    company: company,
    departments: departments,
    positions: positions,
    shifts: shifts,
    locations: locations,
    employees: employees,
    records: records,
    kiosks: kiosks,
    loading: loading,
    setLoading: setLoading,
    fetchCompany: fetchCompany,
    getCompany: getCompany,
    putCompany: putCompany,
    fetchDepartments: fetchDepartments,
    getDepartments: getDepartments,
    postDepartment: postDepartment,
    putDepartment: putDepartment,
    deleteDepartment: deleteDepartment,
    fetchPositions: fetchPositions,
    getPosition: getPosition,
    getPositions: getPositions,
    postPosition: postPosition,
    putPosition: putPosition,
    deletePosition: deletePosition,
    fetchShifts: fetchShifts,
    getShift: getShift,
    getShifts: getShifts,
    postShift: postShift,
    putShift: putShift,
    deleteShift: deleteShift,
    fetchLocations: fetchLocations,
    getLocation: getLocation,
    getLocations: getLocations,
    postLocation: postLocation,
    putLocation: putLocation,
    deleteLocation: deleteLocation,
    fetchEmployees: fetchEmployees,
    getEmployee: getEmployee,
    getEmployees: getEmployees,
    postEmployee: postEmployee,
    putEmployee: putEmployee,
    putEmployeeStatus: putEmployeeStatus,
    putEmployeeExpoPushToken: putEmployeeExpoPushToken,
    deleteEmployee: deleteEmployee,
    fetchRecords: fetchRecords,
    getRecords: getRecords,
    postRecord: postRecord,
    putRecord: putRecord,
    putRecordRequest: putRecordRequest,
    fetchKiosks: fetchKiosks,
    getKiosks: getKiosks,
    postKiosk: postKiosk,
    putKiosk: putKiosk,
    deleteKiosk: deleteKiosk,
    postStripeCheckoutSession: postStripeCheckoutSession,
    getStripeSubscriptions: getStripeSubscriptions,
    postStripeBillingPortal: postStripeBillingPortal,
    postOnboarding: postOnboarding
  };

  return <CompanyContext.Provider value={value}>{children}</CompanyContext.Provider>;
}

export default CompanyContextProvider;
