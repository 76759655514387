import { useContext, useEffect,  } from 'react';
import { useNavigation, useIsFocused } from '@react-navigation/native';

import { List, FAB, Portal,useTheme } from 'react-native-paper';

import EmptyList from '../../components/ui/EmptyList'

import { ScrollView } from 'react-native';
import { AuthContext } from '../../store/auth-context';
import { CompanyContext } from '../../store/company-context';

import { SIZES, FONTS } from '../../constants/theme'


function ShiftListScreen() {
    const theme = useTheme();
    const isFocused = useIsFocused();
    const authCtx = useContext(AuthContext);
    const companyCtx = useContext(CompanyContext);
    const navigation = useNavigation()
    
    useEffect(() => {
      const companyId = authCtx.user.attributes['custom:company_id']
      
      companyCtx.fetchCompany(companyId)
      companyCtx.fetchShifts(companyId)      
    }, [])

  return (
    <>
      {companyCtx.shifts?.length > 0 ?
        <ScrollView
          style={{
            flex: 1,
            backgroundColor: theme.colors.background
          }}>
          {
            companyCtx.shifts?.map((item, index) => {
              return (
                <List.Item
                  key={`shift-${index}`}
                  title={item.shift_name}
                  description={
                    new Date(item.shift_time.from).getHours().toString().padStart(2, '0') + ":" +
                    new Date(item.shift_time.from).getMinutes().toString().padStart(2, '0') + ' - ' +
                    new Date(item.shift_time.to).getHours().toString().padStart(2, '0') + ":" +
                    new Date(item.shift_time.to).getMinutes().toString().padStart(2, '0') + ' | ' +
                    item.shift_days?.map(p => p.charAt(0).toUpperCase() + p.slice(1)).join(', ')}
                  right={props => <List.Icon {...props} icon="pencil" />}
                  onPress={() => { navigation.navigate("Shift", { edit: item }) }}
                  titleStyle={{ ...FONTS.h3 }}
                  descriptionStyle={{ ...FONTS.body4 }}
                />
              )
            })
          }
        </ScrollView>
        :
        <EmptyList />
      }
      <Portal>
        <FAB.Group
          visible={isFocused}
          icon={'plus'}
          actions={[]}
          onStateChange={() => { }}
          onPress={() => {
            navigation.navigate("Shift")
          }}
          fabStyle={{
            marginBottom: SIZES.padding * 3,
            backgroundColor: theme.colors.primary
          }}
        />
      </Portal>
    </>
  );

}

export default ShiftListScreen;