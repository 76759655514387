import { useContext, useEffect, useState } from 'react';
import { useNavigation } from '@react-navigation/native';

import { List, MD3Colors, useTheme } from 'react-native-paper';
import { StyleSheet, View, Text, KeyboardAvoidingView, Platform, ScrollView } from 'react-native';
import QRCode from 'react-native-qrcode-svg';

import Button from '../../components/ui/Button';
import DialogPopup from '../../components/ui/DialogPopup';
import Input from '../../components/ui/Input';
import FormContent from '../../components/ui/FormContent';
import IconButton from '../../components/ui/IconButton';

import { AuthContext } from '../../store/auth-context';
import { CompanyContext } from '../../store/company-context';
import { COLORS, SIZES, FONTS } from '../../constants/theme'

function KioskScreen({ route }) {
    const theme = useTheme();

    const companyCtx = useContext(CompanyContext);
    const navigation = useNavigation()

    const [formlsInvalid, setFormlsInvalid] = useState({
        name: false,
      });
    
    const edit = route.params?.edit;
    const [name, setName] = useState(edit !== undefined ? edit.kiosk_name : '');
    const [dialogVisible, setDialogVisible] = useState(false);

    useEffect(() => {
        if (edit !== undefined)
            navigation.setOptions({
                headerRight: () => (
                <IconButton
                    icon="trash"
                    color={COLORS.white}
                    size={24}
                    onPress={() => { setDialogVisible(true) }}
                />
                ),
            });
      }, [navigation]);

    function deleteSubmit()
    {
        companyCtx.deleteKiosk(companyCtx.company.company_id, edit.kiosk_id).then(() => {
            navigation.goBack()
        })   
    }

    function submitHandler()
    {
        const nameIsValid = name.trim().length > 0;

        if (!nameIsValid) 
        {
            setFormlsInvalid({ name: !nameIsValid })

            return;
        }

        if (!!edit) {
            companyCtx.putKiosk(companyCtx.company.company_id, edit.kiosk_id, name).then(() => {
                navigation.goBack()
            })
        } else {
            companyCtx.postKiosk(companyCtx.company.company_id, name).then(() => {
                navigation.goBack()
            })
        }
    }

    function updateInputValueHandler(inputType, enteredValue) {
        switch (inputType) {
          case 'name':
            setName(enteredValue);
            break;
        }
      }

    return (
        <FormContent 
            title={"Kiosk"}
        >
           <>
                <Input
                    label="Name"
                    onChangeText={updateInputValueHandler.bind(this, 'name')}
                    value={name}
                    error={formlsInvalid.name}
                />
                {!!edit ?
                    <View
                    style={{ 
                      flex: 1,
                      alignItems: 'center', 
                      marginTop: SIZES.base * 3 ,
                      marginBottom: SIZES.base * 3 
                    }}
                  >
                    <QRCode
                    size={200}
                    backgroundColor={theme.colors.background}
                    color={theme.colors.onBackground}
                    value={edit.kiosk_id} />
                    <Text style={{ color: theme.colors.onSurfaceVariant, marginTop: SIZES.base, ...FONTS.h3 }}>
                        Scan this QR Code to connect your kiosk
                    </Text>      
                 </View>
               
                : <></>}
                
                <Button 
                    onPress={submitHandler}
                    loading={companyCtx.loading}
                >
                    {'Save'}
                </Button>
                <DialogPopup
                    visible={dialogVisible}
                    onConfirm={deleteSubmit}
                    onDismiss={() => { setDialogVisible(false) }}
                    confirmText={'Delete'}
                    onCancel={() => { setDialogVisible(false) }}
                    cancelText={'Cancel'}
                    title={'Delete kiosk?'}
                >
                </DialogPopup>
            </>
        </FormContent>
    );

}

export default KioskScreen;
