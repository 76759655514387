import { useState } from 'react';
import { StyleSheet } from 'react-native';
import { TextInput } from 'react-native-paper';

import { Colors } from '../../constants/styles';

/**
 * Creates an input object
 *
 * @mode {string} Type: 'flat' | 'outlined'
 * @secureTextEntry {boolean} If true, the text input obscures the text entered
 * @error {boolean} If true, the input is in error
 */
function Input({
  label,
  onChangeText,
  onFocus,
  editable,
  onTouchStart,
  value,
  mode = 'outlined',
  secureTextEntry = false,
  error = false,
  multiline = false,
  maxLength = 40,
  numberOfLines = 1,
  lineBreakStrategyIOS = 'none',
  required = false,
  customStyle
}) {
  const [secureText, setSecureText] = useState(secureTextEntry);
  return (
    <TextInput
      mode={mode}
      onTouchStart={onTouchStart}
      label={label + (required ? '*' : '')}
      value={value}
      editable={editable}
      secureTextEntry={secureText}
      onFocus={onFocus}
      maxLength={maxLength}
      numberOfLines={numberOfLines}
      multiline={multiline}
      lineBreakStrategyIOS={lineBreakStrategyIOS}
      right={secureTextEntry ?
        secureText ?
          <TextInput.Icon icon="eye" onPress={() => { setSecureText(!secureText) }} />
          : <TextInput.Icon icon="eye-off" onPress={() => { setSecureText(!secureText) }} />
        : null}
      onChangeText={text => onChangeText(text)}
      style={{ ...styles.input, ...customStyle }}
      error={error}
    />
  );
}

export default Input;

const styles = StyleSheet.create({
  input: {
    marginBottom: 10
  },
});
