import { useContext, useEffect, useState } from 'react';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useTheme, DataTable, Avatar } from 'react-native-paper';
import { Ionicons } from '@expo/vector-icons'
import Button from '../../components/ui/Button';
import AlertCard from '../../components/ui/AlertCard';
import NoticeCard from '../../components/ui/NoticeCard';
import CardHeader from '../../components/ui/CardHeader';
import FormContent from '../../components/ui/FormContent';
import { DatePickerModal, } from 'react-native-paper-dates';
import Input from '../../components/ui/Input';
import moment from 'moment'
import Moment from 'react-moment';
import MomentTZ from 'moment-timezone';
import { Storage } from 'aws-amplify';

import { groupBy } from '../../utils/utils'

import { StyleSheet, Text, View, Image, ImageBackground, TouchableOpacity, FlatList, ScrollView, Animated } from 'react-native';

import { CompanyContext } from '../../store/company-context';
import { AuthContext } from '../../store/auth-context';
import { NavigationContext } from '../../store/navigation-context';

import DropDown from '../../components/ui/DropDown';
import IconButton from '../../components/ui/IconButton';

import * as XLSX from 'xlsx';
import * as FileSystem from 'expo-file-system';
import * as Sharing from 'expo-sharing';


import { Colors as styleColors } from '../../constants/styles';
import VictoryCustomTheme from '../../styles/VictoryCustomTheme';
import { COLORS, SIZES, FONTS } from '../../constants/theme'
import dummyData from '../../constants/dummy'

function ReportScreen() {
  const theme = useTheme();
  const navigation = useNavigation();
  const route = useRoute();

  const companyCtx = useContext(CompanyContext);
  const authCtx = useContext(AuthContext);
  const navigationCtx = useContext(NavigationContext);

  const [showEmployeeDropDown, setShowEmployeeDropDown] = useState(false);

  const [fromDate, setFromDate] = useState(new Date(moment.tz(Moment.globalTimezone).startOf('day').utc().add(-7, 'days')));
  const [toDate, setToDate] = useState(new Date(moment.tz(Moment.globalTimezone).endOf('day').utc().format()));
  const [dateVisible, setDateVisible] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState('');
  const [employeePhotos, setEmployeePhotos] = useState({});


  const numberOfItemsPerPageList = [2, 3, 4];
  const [page, setPage] = useState(0);
  const [numberOfItemsPerPage, onItemsPerPageChange] = useState(numberOfItemsPerPageList[0]);
  const from = page * numberOfItemsPerPage;
  const to = Math.min((page + 1) * numberOfItemsPerPage, navigationCtx.reportScreenRecords.length);

  const username = authCtx.user?.attributes.sub
  const companyId = authCtx.user?.attributes['custom:company_id']

  useEffect(() => {
    setSelectedEmployee(username)
    handleSearch(username)
  },[])

  useEffect(() => {
    companyCtx.fetchEmployees(companyId)
      .then((employees) => {
        var authUser = employees.filter(x => x.username === username)
        var managedEmployees = employees.filter(employee => employee.superior_username === username)

        combinedEmployees = authUser[0].is_root_user ? employees : [...authUser, ...managedEmployees]
        
        fixedAllOptions = []
        //Workaround to have an option named All to load every employee
        if (combinedEmployees.length > 1)
        {
          fixedAllOptions = [{
            employee_name: "All",
            surname: "",
            username: combinedEmployees.map(m => m.username).join(',')
          }]
        }

        setEmployees(
          [...fixedAllOptions, ...combinedEmployees].map(employee => {
            return {
              label: employee.employee_name + ' ' + employee.surname,
              value: employee.username
            }
          })
        )
      })
  }, [])

  function handleShare() {
    var employee = companyCtx.employees.filter(employee => employee.username === selectedEmployee)[0]

    var arrayRecords = []
    navigationCtx.reportScreenRecords.map((recordGroup, index) => {
        recordGroup[1].map((record, index) => {
          arrayRecords.push([
            moment(new Date(record.date)).format('DD-MM-YYYY'),
            moment(new Date(MomentTZ.tz(record.datetime, 'UTC'))).format('HH:mm'),
            !!record.clock_out ? moment(new Date(MomentTZ.tz(record.clock_out?.datetime, 'UTC'))).format('HH:mm') : "",
            !!record.clock_out ? moment.duration(record.record_duration, 'seconds').format('mm') : "",
          ])
        })
      })
    
    let wb = XLSX.utils.book_new();
    let ws = XLSX.utils.aoa_to_sheet([
      ['Date', 'Clock-in', 'Clock-out', 'Duration (minutes)'],
      ...arrayRecords
    ]);

    XLSX.utils.book_append_sheet(wb, ws, employee.employee_name + '-' + employee.surname);

    const base64 = XLSX.write(wb, { type: 'base64' });
    const filename = FileSystem.documentDirectory 
    + 'Report-' + employee.employee_name + '-' + employee.surname
    + '-' + moment(new Date(fromDate)).format('DD-MM-YYYY') 
    + '-' + moment(new Date(toDate)).format('DD-MM-YYYY') 
    + '.xlsx';
    FileSystem.writeAsStringAsync(filename, base64, { encoding: FileSystem.EncodingType.Base64 })
      .then(() => {
        Sharing.shareAsync(filename)
      })

  }

  function handleSearch(usernames) {
    var dateStartOf = moment(fromDate).format('YYYY-MM-DD')
    dateStartOf = moment.tz(dateStartOf, Moment.globalTimezone)
    dateStartOf = dateStartOf.startOf('day')
    dateStartOf = dateStartOf.utc().format()

    var dateEndOf = moment(toDate).format('YYYY-MM-DD')
    dateEndOf = moment.tz(dateEndOf, Moment.globalTimezone)
    dateEndOf = dateEndOf.endOf('day')
    dateEndOf = dateEndOf.utc().format()

    const companyId = authCtx.user.attributes['custom:company_id']
    
    companyCtx.getRecords(companyId, usernames.split(','), dateStartOf, dateEndOf, true)
      .then((records) => {
        grouped = groupBy(records, 'date')
        
        sorted = grouped.sort((a,b) => {
          if (new Date(a[0]) > new Date(b[0]))
            return 1;
          else
            return -1
        })
        
        //setRecordsGrouped(sorted)

        navigationCtx.setReportScreenRecords(sorted)
      })

      usernames.split(',').forEach((item) => {
        console.log(item)
        Storage.get("profile.jpg", {
          level: 'protected',
          identityId: item,
          validateObjectExistence: true
        })
          .then((imgUrl) => {
            setEmployeePhotos(prevEmployeePhto => ({ ...prevEmployeePhto, [item]: imgUrl }))
          })
          .catch((error) => {
            console.log("User " + item + " does not have profile picture")
          })
      })
  }

  function renderProfilePicture(record) {
    if (record.employee.username in employeePhotos) {
      return (
        <Avatar.Image
          style={{
            marginRight: 7
          }}
          size={30}
          source={{ uri: employeePhotos[record.employee.username] }} />
      )
    }
    else {
      return (<Avatar.Icon
        size={30}
        style={{
          borderColor: 'white',
          borderWidth: 0,
          marginRight: 7
        }}
        icon="account"
      />)
    }
  }

  function sharedDataTableCells(record) {
    return (
      <>
        <DataTable.Cell>
          <Moment format="HH:mm" element={Text} style={{ color: theme.colors.onBackground }}>{MomentTZ.tz(record.datetime, 'UTC')}
          </Moment>
        </DataTable.Cell>
        {
          !!record.clock_out ?
            <>
              <DataTable.Cell>
                <Moment format="HH:mm" element={Text} style={{ color: theme.colors.onBackground }}>{MomentTZ.tz(record.clock_out?.datetime, 'UTC')}
                </Moment>
              </DataTable.Cell>
              <DataTable.Cell>
                {moment.duration(record.record_duration, 'seconds').format('HH[h]mm[m]')}
              </DataTable.Cell>
              <DataTable.Cell numeric
                style={{
                  flex: 0.2
                }}
              >
                <Ionicons
                  name="chevron-forward"
                  size={18}
                  color={theme.colors.onBackground}
                />
              </DataTable.Cell>
            </>
            :
            <>
              <DataTable.Cell />
              <DataTable.Cell />
            </>
        }
      </>
    )
  }

  return (
    <FormContent
      title={"Report"}
      titleContainerStyle={{
        marginTop: '20%'
      }}
    >
      
      <View
        style={{
          height: '100%'
        }}
      >
        
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'flex-start',
            //backgroundColor: 'blue',
          }}
        >
          <View
            style={{
              flex: 1,
              //backgroundColor: 'red'
            }}
          >
            {employees.length > 1 &&
              <DropDown
                label={"Employee"}
                mode={"outlined"}
                visible={showEmployeeDropDown}
                showDropDown={() => setShowEmployeeDropDown(true)}
                onDismiss={() => { setShowEmployeeDropDown(false); }}
                value={selectedEmployee}
                setValue={(value) => { setSelectedEmployee(value); navigationCtx.setReportScreenRecords([]) }}
                list={employees}
              />
            }

            <TouchableOpacity
              onPressIn={() => { setDateVisible(true) }} //Works for Android
            >
              <Input
                label="Range"
                onTouchStart={() => { setDateVisible(true) }}  //Works for iOS
                value={moment(new Date(fromDate)).format('DD/MM/YYYY') + " - " + moment(new Date(toDate)).format('DD/MM/YYYY')}
                editable={false}
              />
            </TouchableOpacity>
            <DatePickerModal
              locale="en"
              visible={dateVisible}
              onDismiss={() => setDateVisible(false)}
              onConfirm={({ startDate, endDate }) => {  
                setFromDate(new Date(startDate)); 
                setToDate(new Date(endDate)); 
                setDateVisible(false); 
                navigationCtx.setReportScreenRecords([]) 
              }}
              mode="range"
              startDate={fromDate}
              endDate={toDate}
              use24HourClock
            />
            <Button
              onPress={() => handleSearch(selectedEmployee)}
              loading={companyCtx.loading}
            >
              {'Search'}
            </Button>
          </View>
        </View>
        {navigationCtx.reportScreenRecords.length > 0 && selectedEmployee.split(',').length <= 1 &&
          <View
            style={{
              width: "100%",
              alignItems: 'flex-end',
             // backgroundColor: 'green',
            }}
          >
            <IconButton
              icon="share-outline"
              size={30}
              color={theme.colors.onSurfaceVariant}
              onPress={handleShare}
              styles={{
                alignItems: 'center',
                justifyContent: 'center',
              }}
            />
          </View>
        }
        <DataTable
          style={{
            flex: 1,
            //backgroundColor: 'red'
          }}
        >
          {/* HEADER */}
            <View
              style={{
              }}
          >
            <DataTable.Header>
                <DataTable.Title
                  style={{
                    flex: selectedEmployee.split(',').length > 1 ? 2 : 1,
                  }}
                >Date</DataTable.Title>
                <DataTable.Title>Clock-in</DataTable.Title>
                <DataTable.Title>Clock-out</DataTable.Title>
                <DataTable.Title>Duration</DataTable.Title>
                <DataTable.Title numeric
                  style={{
                    flex: 0.2
                  }}
                ></DataTable.Title>
              </DataTable.Header>
            </View>

            {/* BODY */}
          <ScrollView
            style={{
              flex: 1,
              // backgroundColor: COLORS.secondary,
            }}
          >
            {selectedEmployee.split(',').length > 1 ?
              <>
                {
                  navigationCtx.reportScreenRecords.map((recordGroup, index) => {
                    return (
                      recordGroup[1].map((record, index) => {
                        return (
                          <>
                            {index == 0 &&
                              <DataTable.Row
                                key={index+record.date}
                                style={{
                                  borderBottomWidth: 0,
                                  
                                }}
                              >

                                <DataTable.Cell>
                                  {index == 0 &&
                                    <Moment format="DD-MM-YY" element={Text} style={{ color: theme.colors.onBackground }}>{record.date}
                                    </Moment>
                                  }
                                </DataTable.Cell>
                              </DataTable.Row>
                            }
                            <DataTable.Row
                              key={index}
                              onPress={() => navigation.navigate('ReportDetail', { date: record.date, record_id: record.record_id, username: record.employee.username })}
                            >
                              <DataTable.Cell
                                style={{
                                  flex: 2,
                                  //backgroundColor: 'red'
                                }}
                              >
                                <View
                                  style={{
                                    flexDirection: 'row',
                                    alignItems: 'center', 
                                  }}
                                >
                                  {renderProfilePicture(record)}
                                  <Text
                                    style={{
                                      color: theme.colors.onBackground,
                                      //backgroundColor: 'green'
                                    }}
                                    numberOfLines={2}
                                    ellipsizeMode="tail"
                                    flex={1}
                                  >
                                    {record.employee.employee_name + ' ' + record.employee.surname}
                                  </Text>
                                </View>
                              </DataTable.Cell>
                              {sharedDataTableCells(record)}
                            </DataTable.Row>
                          </>
                        )
                      })
                    )
                  })
                }
              </>
              :
              <>
                {
                  navigationCtx.reportScreenRecords.map((recordGroup, index) => {
                    return (
                      recordGroup[1].map((record, index) => {
                        return (
                          <DataTable.Row
                            key={index}
                            onPress={() => navigation.navigate('ReportDetail', { date: record.date, record_id: record.record_id, username: record.employee.username }) }
                          >
                            <DataTable.Cell>
                              {index == 0 &&
                                <Moment format="DD-MM-YY" element={Text} style={{ color: theme.colors.onBackground }}>{record.date}
                                </Moment>
                              }
                            </DataTable.Cell>
                            {sharedDataTableCells(record)}
                          </DataTable.Row>
                        )
                      })
                    )
                  })
                }
              </>
            }
          </ScrollView>

            {/* FOOTER */}
            <View
              style={{
              }}
            >
            <DataTable.Pagination
                page={page}
                numberOfPages={Math.ceil(navigationCtx.reportScreenRecords.length / numberOfItemsPerPage)}
                onPageChange={page => setPage(page)}
                label={`${from + 1}-${to} of ${navigationCtx.reportScreenRecords.length}`}
                numberOfItemsPerPageList={numberOfItemsPerPageList}
                numberOfItemsPerPage={numberOfItemsPerPage}
                onItemsPerPageChange={onItemsPerPageChange}
                selectPageDropdownLabel={'Rows per page'}

              />
            
            </View>
          </DataTable>

        </View>
    </FormContent>
  );
}

export default ReportScreen;

const styles = StyleSheet.create({
  shadow: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.30,
    shadowRadius: 4.65,

    elevation: 8,
  }
});
