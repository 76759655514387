import AsyncStorage from "@react-native-async-storage/async-storage";
import * as Sentry from 'sentry-expo';

export const storeData = async (key, value) => {
    try {
        const jsonValue = JSON.stringify(value)
        await AsyncStorage.setItem(key, jsonValue)
    } catch (error) {
        console.log(error.message)
        Sentry.Native.captureException(error)
    }
}

export const getData = async (key) => {
    try {
        const jsonValue = await AsyncStorage.getItem(key)
        return jsonValue != null ? JSON.parse(jsonValue) : null;
    } catch (error) {
        console.log(error.message)
        Sentry.Native.captureException(error)
    }
}