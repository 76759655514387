import { useContext, useEffect, useState } from 'react';
import { useTheme, Tooltip } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';

import { StyleSheet, Text, View, Image, ImageBackground, TouchableOpacity, FlatList, ScrollView  } from 'react-native';
import { Ionicons } from '@expo/vector-icons'

import { COLORS, SIZES, FONTS } from '../../constants/theme'

import dummyData from '../../constants/dummy'

function FlatListCard({ data, customContainerStyle, onPress }) {
  const theme = useTheme();
  const navigation = useNavigation();

  const renderItem = ({ item, index }) => (
    <Tooltip title={item.tooltip}>
      <View //TouchableOpacity
        style={{
          width: 150,
          paddingVertical: SIZES.padding - 5,
          paddingHorizontal: SIZES.padding - 5,
          marginLeft: index == 0 ? SIZES.padding : 0,
          marginRight: SIZES.radius + 3,
          borderRadius: 10,
          backgroundColor: theme.dark ? theme.colors.surfaceVariant : 'white',
        }}
        // delayPressIn={100}
        // delayPressOut={100}
        //onPress={() => navigation.navigate("Report", { summary: item })}
      >
        {/* Currency */}
        <View style={{ flexDirection: 'row' }}>
          <View>
            <Ionicons
              name={item.icon}
              size={SIZES.icon}
              color={theme.colors.primary}
              style={{
                marginTop: 5,
              }}
            />
          </View>
          <View style={{ marginLeft: SIZES.base }}>
            <Text style={{ color: theme.colors.onSurfaceVariant, ...FONTS.h3 }}>{item.title}</Text>
            <Text style={{ color: theme.colors.onSurfaceVariant, ...FONTS.body3 }}>{item.subTitle}</Text>
          </View>
        </View>

        {/* Value */}
        <View style={{ marginTop: SIZES.radius }}>
          <Text style={{ color: theme.colors.onSurfaceVariant, ...FONTS.h3 }}>{item.summary}</Text>
          <Text style={{ color: item.positive ? COLORS.green : COLORS.red, ...FONTS.h4 }}>{item.code}</Text>
        </View>

      </View>
    </Tooltip>
  )

  return (
    <FlatList
      contentContainerStyle={{ marginTop: SIZES.base }}
      data={data}
      renderItem={renderItem}
      keyExtractor={item => `${item.id}`}
      horizontal
      showsHorizontalScrollIndicator={false}
    />
  )
}

export default FlatListCard;