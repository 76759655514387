import { useContext, useState, useEffect, useRef } from 'react';
import { Avatar, useTheme, Checkbox, List } from 'react-native-paper';
import { useNavigation, useIsFocused } from '@react-navigation/native';

import { StyleSheet, Text, View } from 'react-native';
import { AuthContext } from '../store/auth-context';
import { ThemeContext } from '../store/theme-context';
import { UserContext } from '../store/user-context';
import QRCode from 'react-native-qrcode-svg';
import { Buffer } from "buffer";
import { Storage } from 'aws-amplify';

import { Camera, CameraType } from 'expo-camera';

import { Switch } from 'react-native-paper';
import FormContent from '../components/ui/FormContent';
import { Ionicons } from '@expo/vector-icons';

import { FONTS, SIZES } from '../constants/theme';
import SelfieModal from '../components/ui/SelfieModal';

function ProfileScreen() {
  const theme = useTheme();

  const authCtx = useContext(AuthContext);
  const themeCtx = useContext(ThemeContext);
  const userCtx = useContext(UserContext);

  const cameraRef = useRef();
  const navigation = useNavigation()

  const [isDarkMode, setIsDarkMode] = useState(themeCtx.theme === "dark");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [hasCameraPermission, setHasCameraPermission] = useState(null);
  const [clockReminder, setClockReminder] = useState(userCtx.clockReminder);
  const [loadingStatus, setLoadingStatus] = useState("");
  const [profilePhoto, setProfilePhoto] = useState(null)

  useEffect(() => {
    const username = authCtx.user?.attributes.sub

    Storage.get("front.jpg", { 
      level: 'protected',
      identityId: username,
      validateObjectExistence: true
    })
      .then((imgUrl) => {
        setProfilePhoto(imgUrl)
      })
      .catch((error) => {
        console.log("User " + username + " does not have profile picture")
      })
  }, [isModalVisible])

  const handleThemeSwitch = () => {
    setIsDarkMode(!isDarkMode);
    themeCtx.updateTheme()
  }

  const handleClockReminderSwitch = () => {
    console.log(userCtx.user)
    setClockReminder(!clockReminder);
    userCtx.updateClockReminder()
  }

  async function handlePhotosTaken(photos) {
    userCtx.setLoading(true)

    setLoadingStatus("Uploading photo...")

    for (var key in photos){
      setLoadingStatus("Uploading photo [" + key + "]...")

      const buffer = Buffer.from(photos[key], 'base64')

      Storage.put(key + '.jpg', buffer, {
        level: 'protected',
        contentType: 'image/png',
        identityId: authCtx.user?.attributes.sub
      })
      .then((img) => {
        if(key == 'front')
        {
          setProfilePhoto(img)
        }
      })
      .catch((error) => {
        console.log(error)
      })
    }

    userCtx.setLoading(false) 
    setLoadingStatus("")
  }

  function renderModal() {
    if (isModalVisible) {
      //Verify whether the company requires front photo
      (async () => {
        const { status } = await Camera.requestCameraPermissionsAsync();
        console.log(status)
        setHasCameraPermission(status === 'granted');
      })();
    }

    return (
        <SelfieModal
          isVisible={isModalVisible}
          enableCamera={true}
          hasCameraPermission={hasCameraPermission}
          handlePhotosTaken={handlePhotosTaken}
          cameraRef={cameraRef}
          loadingStatus={loadingStatus}
          setIsModalVisible={(value) => { setIsModalVisible(value) }}
        />
      )

  }

  return (
    <FormContent
      titleContainerStyle={{
        marginTop: '25%'
      }}
    >

      <View
        style={{
          top: -80,
          //backgroundColor: 'purple'
        }}
      >
        <View
          style={{
            flex: 1,
            alignItems: 'center',
            //backgroundColor: 'blue'
          }}
        >
          {profilePhoto ?
            <Avatar.Image
              size={115}
              source={{ uri: profilePhoto }} />
            :
            <>
              <Avatar.Icon
                size={115}
                style={{
                  borderColor: 'white',
                  borderWidth: 4,
                }}
                icon="account">
              </Avatar.Icon>
            </>
          }
          <Ionicons style={{
            zIndex: 1,
            position: 'relative',
            top: -37,
            right: -39,
            marginBottom: -22,
            borderRadius: 23,
            borderWidth: 0,
            padding: 7,
            borderColor: theme.colors.success,
            overflow: "hidden",
            backgroundColor: theme.colors.success
          }}
            name={"camera-outline"}
            color={'white'}
            size={24} 
            onPress={() => {
              setIsModalVisible(true)
            }}
            />
          <Text style={{ color: theme.colors.onBackground, fontWeight: 'bold', ...FONTS.h2 }}>
            {userCtx.user?.employee_name + ' ' + userCtx.user?.surname}
          </Text>
          <Text style={{ color: theme.colors.onBackground, marginTop: SIZES.base, ...FONTS.h4 }}>
            {userCtx.user?.email}
          </Text>
        </View>

        {/* QR CODE */}
        <View
          style={{
            flex: 1,
            alignItems: 'center',
            marginTop: SIZES.base * 2,
            //backgroundColor: 'yellow'
          }}
        >
          <QRCode
            size={130}
            backgroundColor={theme.colors.background}
            color={theme.colors.onBackground}
            value={userCtx.user?.username} />
        </View>

        {/* INFORMATION */}
        <View
          style={{
            flex: 1,
            marginTop: SIZES.base * 2,
            //backgroundColor: 'green'
          }}
        >
          <View
            style={{
              flex: 1,
              flexDirection: 'row',
              justifyContent: 'space-between',
              //backgroundColor: 'green'
            }}
          >
            <View>
              <Text style={{ fontWeight: 'bold', color: theme.colors.onBackground, marginTop: SIZES.base, ...FONTS.h3 }}>
                Role
              </Text>
              <Text style={{ color: theme.colors.onBackground, marginTop: SIZES.base, ...FONTS.h3 }}>
                {userCtx.user?.position?.position_name}
              </Text>
            </View>
            <View>
              <Text style={{ fontWeight: 'bold', color: theme.colors.onBackground, marginTop: SIZES.base, ...FONTS.h3 }}>
                Department
              </Text>
              <Text style={{ color: theme.colors.onBackground, marginTop: SIZES.base, ...FONTS.h3 }}>
                {userCtx.user?.department?.department_name}
              </Text>
            </View>
          </View>
          <View
            style={{
              flex: 1,
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: SIZES.base * 2,
              //backgroundColor: 'green'
            }}
          >
            <View>
              <Text style={{ fontWeight: 'bold', color: theme.colors.onBackground, marginTop: SIZES.base, ...FONTS.h3 }}>
                Shift
              </Text>
              {userCtx.user?.shift ?
                <>
                  <Text style={{ color: theme.colors.onBackground, marginTop: SIZES.base, ...FONTS.h3 }}>
                  {new Date(userCtx.user.shift.shift_time.from).getHours().toString().padStart(2, '0') + ":" +
                      new Date(userCtx.user.shift.shift_time.from).getMinutes().toString().padStart(2, '0') + ' - ' +
                      new Date(userCtx.user.shift.shift_time.to).getHours().toString().padStart(2, '0') + ":" +
                      new Date(userCtx.user.shift.shift_time.to).getMinutes().toString().padStart(2, '0') }
                  </Text>
                  <Text style={{ color: theme.colors.onBackground, marginTop: SIZES.base, ...FONTS.h3 }}>
                    {userCtx.user.shift.shift_days?.map(p => p.charAt(0).toUpperCase() + p.slice(1, 3)).join(', ')}
                  </Text>
                </>
                :
                <Text style={{ color: theme.colors.onBackground, marginTop: SIZES.base, ...FONTS.h3 }}>
                  N/A
                </Text>
              }
            </View>
          </View>
        </View>

       
        {/* MENU */}
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: SIZES.padding * 2,
            //backgroundColor: 'red'
          }}
        >
          <Text style={{ alignSelf: 'center', color: theme.colors.onBackground, ...FONTS.h3 }}>Dark mode</Text>
          <Switch value={isDarkMode} onValueChange={() => handleThemeSwitch()} />
        </View>
        <Checkbox.Item
          style={{
            marginLeft: -16,
            marginTop: SIZES.base,
          }}
          label={'Remind to clock-in/out'}
          status={clockReminder ? 'checked' : 'unchecked'}
          color={theme.colors.secondary}
          onPress={() => { handleClockReminderSwitch() }}
        />
      </View>
      {renderModal()}
    </FormContent>
  );
}

export default ProfileScreen;

const styles = StyleSheet.create({
  rootContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 32,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 8,
  },
});
