import { useContext, useEffect,  useState } from 'react';
import { useTheme } from 'react-native-paper';
import { ScrollView, View, Text } from 'react-native';
import { Ionicons } from '@expo/vector-icons'
import LottieView from "lottie-react-native"

import { COLORS, SIZES, FONTS } from '../../constants/theme'

function EmptyList({ 
    source = require('../../assets/astronaut.json'),
    title = "Add new items +"
}) {
    const theme = useTheme();

    return (
        <View
        style={{
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          paddingBottom:  SIZES.padding * 3,
          backgroundColor: theme.colors.background
        }}
      >
        <LottieView
          source={source}
          autoPlay
          loop={true}
          style={{
            width: '80%',
            aspectRatio: 1,
            backgroundColor: theme.colors.background
          }}
        />
        <Text style={{ color: theme.colors.onBackground, ...FONTS.body2 }}>
          {title}
        </Text>
      </View>
    )
}

export default EmptyList;