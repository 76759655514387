import { useContext, useEffect, useState } from 'react';
import { useTheme } from 'react-native-paper';
import { StyleSheet, Text, View, Image, ImageBackground, TouchableOpacity, FlatList, ScrollView } from 'react-native';
import { Ionicons } from '@expo/vector-icons'
import { Colors as styleColors } from '../../constants/styles';

import { COLORS, SIZES, FONTS } from '../../constants/theme'

function NoticeCard({ customContainerStyle }) {
    const theme = useTheme();

    return (
        <View
            style={{
                marginTop: SIZES.padding,
                marginHorizontal: SIZES.padding,
                padding: 20,
                borderRadius: SIZES.radius,
                backgroundColor: theme.dark ? theme.colors.surface : styleColors.lightBlue['800'],
                ...styles.shadow
            }}
        >
            <Text style={{ color: COLORS.white, ...FONTS.h3 }}>Increase Punctuality</Text>
            <Text style={{ marginTop: SIZES.base, color: COLORS.white, ...FONTS.body4, lineHeight: 18 }}>
            If you find yourself being guilty of consistently being late, 
            then there are things you can shift to adopt the habit of being on time.</Text>
            <TouchableOpacity
                style={{
                    marginTop: SIZES.base
                }}
                onPress={() => console.log("Learn More")}
            >
                <Text
                    style={{
                        textDecorationLine: 'underline',
                        color: COLORS.green, ...FONTS.h3
                    }}
                >
                    Learn More
                </Text>
            </TouchableOpacity>
        </View>
    )
}

export default NoticeCard;

const styles = StyleSheet.create({
    shadow: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 4,
        },
        shadowOpacity: 0.30,
        shadowRadius: 4.65,

        elevation: 8,
    }
});