import { useContext, useState } from 'react';
import { Alert } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import Toast from 'react-native-root-toast';

import CodeContent from '../components/Auth/CodeContent';
import { AuthContext } from '../store/auth-context';

function CodeScreen() {
  const authCtx = useContext(AuthContext);
  const navigation = useNavigation();

  async function codeHandler({ code }) {

    await authCtx.confirmSignUp(code)
      .catch((error) => {
        Toast.show(error.message, {
          duration: Toast.durations.LONG,
          position: Toast.positions.BOTTOM,
        });
      })
  }

  return <CodeContent
    loading={authCtx.loading}
    onCodeConfirmation={codeHandler} 
  />;
}

export default CodeScreen;
