import { useContext, useEffect, useState } from 'react';
import { useTheme, FAB, Provider, Portal, AnimatedFAB, Avatar,ActivityIndicator, Divider } from 'react-native-paper';
import { StyleSheet, View, Text, KeyboardAvoidingView, Platform, ScrollView } from 'react-native';
import { Ionicons } from '@expo/vector-icons'
import * as Animatable from 'react-native-animatable'
import Modal from "react-native-modal";
import MomentTZ from 'moment-timezone';
import Moment from 'react-moment';

import IconButton from './IconButton';
import Button from './Button';

import { Camera, CameraType } from 'expo-camera';
import * as FaceDetector from 'expo-face-detector';

import { AuthContext } from '../../store/auth-context';
import { CompanyContext } from '../../store/company-context';

import { SIZES, FONTS } from '../../constants/theme'
import { Colors } from '../../constants/styles';

function ClockReceiptModal({ isVisible, setIsModalVisible, isStart, record }) {
    const theme = useTheme();
  
    if (!record) {
        return null;
    }

    return (
        <Modal
            isVisible={isVisible}
            onBackdropPress={() => setIsModalVisible(false)}
            animationIn={"zoomIn"}
            animationInTiming={1000}
            animationOut={"zoomOut"}
            animationOutTiming={500}
            hideModalContentWhileAnimating={true}
            useNativeDriver={true}
        >
            <View
                style={{
                    flex: 1,
                    backgroundColor: isStart ? theme.colors.success : theme.colors.error,
                    marginVertical: SIZES.padding * 3,
                    marginHorizontal: SIZES.padding * 1,
                    borderRadius: SIZES.radius,
                }}
            >
                {/* HEADER */}
                <View
                    style={{
                        height: 70,
                        alignItems: 'center',
                    }}>
                    <View
                        style={{
                            flex: 1,
                            width: '100%',
                            alignItems: 'flex-end',
                            paddingHorizontal: SIZES.base,
                            paddingTop: SIZES.base,
                        }}
                    >
                        <IconButton
                            icon="close-circle-outline"
                            size={30}
                            color={'white'}
                            onPress={() => setIsModalVisible(false)}
                        />
                    </View>
                </View>

                {/* BODY */}
                <View
                    style={{
                        flex: 1,
                        paddingVertical: SIZES.padding * 3,
                        paddingBottom: (SIZES.padding * 3) + 70,
                        paddingHorizontal: SIZES.padding * 2
                    }}>

                   

                    {/* TICKET BODY TOP HALF */}
                    <View
                        style={{
                            flex: 1,
                            borderBottomWidth: 1,
                            borderColor: isStart ? theme.colors.success : theme.colors.error,
                            backgroundColor: 'white',
                            borderTopLeftRadius: SIZES.radius,
                            borderTopRightRadius: SIZES.radius,
                        }}
                    >
                        {/* TOP HALF CONTENT */}
                        <View
                            style={{
                                flex: 1,
                                borderTopLeftRadius: SIZES.radius,
                                borderTopRightRadius: SIZES.radius,
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: SIZES.padding
                            }}
                        >
                            <View
                                style={{
                                    width: 50,
                                    height: 50,
                                    top: -45,
                                    alignItems: 'center',
                                    flex:1
                                }}
                            >

                                <Avatar.Icon
                                    size={65}
                                    style={{
                                        borderColor: 'white',
                                        borderWidth: 4,
                                        backgroundColor: isStart ? theme.colors.success : theme.colors.error,
                                    }}
                                    icon="check"
                                />
                            </View>
                            <Text style={{
                                ...FONTS.h2 }}>
                                {isStart ? 'Clocked-in' : 'Clocked-out'}
                            </Text>

                           
                        </View>

                        {/* INVERTED RADIUS */}
                        <View
                            style={{
                                justifyContent: 'space-between',
                                flexDirection: 'row',
                                backgroundColor:  'white',
                            }}
                        >
                            <View
                                style={{
                                    backgroundColor: isStart ? theme.colors.success : theme.colors.error,
                                    height: 15,
                                    width: 15,
                                    overflow: 'hidden',
                                    borderTopRightRadius: 30,
                                }}
                            />
                            <View
                                style={{
                                    backgroundColor: isStart ? theme.colors.success : theme.colors.error,
                                    height: 15,
                                    width: 15,
                                    overflow: 'hidden',
                                    borderTopLeftRadius: 30,
                                }}
                            />
                        </View>

                    </View>

                    {/* TICKET BODY BOTTOM HALF */}
                    <View
                        style={{
                            flex: 2,
                            backgroundColor: 'white',
                            borderBottomLeftRadius: SIZES.radius,
                            borderBottomRightRadius: SIZES.radius,
                        }}
                    >
                        {/* INVERTED RADIUS */}
                        <View
                            style={{
                                justifyContent: 'space-between',
                                flexDirection: 'row',
                                backgroundColor:  'white',
                            }}
                        >
                            <View
                                style={{
                                    backgroundColor: isStart ? theme.colors.success : theme.colors.error,
                                    height: 15,
                                    width: 15,
                                    overflow: 'hidden',
                                    borderBottomRightRadius: 30,
                                }}
                            />
                            <View
                                style={{
                                    backgroundColor: isStart ? theme.colors.success : theme.colors.error,
                                    height: 15,
                                    width: 15,
                                    overflow: 'hidden',
                                    borderBottomLeftRadius: 30,
                                }}
                            />
                        </View>
                        {/* BOTTOM HALF CONTENT */}
                        <View
                            style={{
                                flex: 1,
                                backgroundColor: 'white',
                                borderBottomLeftRadius: SIZES.radius,
                                borderBottomRightRadius: SIZES.radius,
                                padding: SIZES.padding,
                                justifyContent: 'center',
                            }}
                        >
                            {/* CLOCK IN/OUT DATE TIME */}
                            <View>
                                <Text style={{
                                    ...FONTS.h4
                                }}>
                                    {'Data'}
                                </Text>

                                <View
                                    style={{
                                        width: '100%',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Text style={{
                                        ...FONTS.h3
                                    }}>
                                        <Moment format="DD-MM-YY" element={Text}>{record?.date}
                                        </Moment>
                                    </Text>
                                    <Text style={{
                                        ...FONTS.h3
                                    }}>
                                         <Moment format="HH:mm" element={Text}>
                                            {MomentTZ.tz(record.datetime, 'UTC')}
                                        </Moment>
                                    </Text>
                                </View>
                            </View>
                            <Divider
                                style={{
                                    marginVertical: SIZES.base
                                }}
                            />
                            {/* LOCATION */}

                            <View>
                                <Text style={{
                                    ...FONTS.h4
                                }}>
                                    {'Location'}
                                </Text>

                                <View
                                    style={{
                                        width: '100%',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Text style={{
                                        ...FONTS.h3
                                    }}>
                                        {record.locations?.map((item, index) => {
                                            console.log(index)
                                            return (
                                                <Text key={index}>
                                                    {index}
                                                </Text>
                                            )
                                        })
                                        }
                                        {record.location?.status == 2 ? "No restriction" : record.location?.status == 1 ? "Not in location" : "In location"}
                                    </Text>
                                    <Text style={{
                                        ...FONTS.h3
                                    }}>
                                        {/* {record?.location?.status == 2 ? "No restriction" : record?.location?.status == 1 ? "Not in location" : "In location"} */}
                                    </Text>
                                </View>
                            </View>
                            <Divider
                                style={{
                                    marginTop: SIZES.base,
                                    marginBottom: SIZES.padding
                                }}
                            />

                            {/* GREETING MESSAGE */}
                            <View>
                                <Text style={{
                                    alignSelf: 'center',
                                    ...FONTS.h3
                                }}>
                                    {'Have a lovely day!'}
                                </Text>
                            </View>
                        </View>

                    </View>

                </View>
            </View>
        </Modal>
    )
}

export default ClockReceiptModal;