import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { useNavigation } from '@react-navigation/native';

import { List, MD3Colors, useTheme, Checkbox, Text } from 'react-native-paper';

import Button from '../../components/ui/Button';
import DialogPopup from '../../components/ui/DialogPopup';
import Input from '../../components/ui/Input';
import FormContent from '../../components/ui/FormContent';
import IconButton from '../../components/ui/IconButton';

import { AuthContext } from '../../store/auth-context';
import { CompanyContext } from '../../store/company-context';
import { COLORS, SIZES, FONTS } from '../../constants/theme'

function PositionScreen({ route }) {
    const theme = useTheme();

    const companyCtx = useContext(CompanyContext);
    const navigation = useNavigation()

    const [formlsInvalid, setFormlsInvalid] = useState({
        name: false,
    });

    const edit = route.params?.edit;
    const [name, setName] = useState(edit !== undefined ? edit.position_name : '');
    const [permissions, setPermissions] = useState(edit !== undefined ? edit.position_permissions : []);
    const availablePermissions = ["employees", "roles", "departments", "shifts", "locations", "kiosks"];

    const [dialogVisible, setDialogVisible] = useState(false);


    useEffect(() => {
        if (edit !== undefined)
            navigation.setOptions({
                headerRight: () => (
                <IconButton
                    icon="trash"
                    color={COLORS.white}
                    size={24}
                    onPress={() => { setDialogVisible(true) }}
                />
                ),
            });
      }, [navigation]);

    function deleteSubmit() {
        companyCtx.deletePosition(companyCtx.company.company_id, edit.position_id)
        navigation.goBack()
    }

    function submitHandler() {
        const nameIsValid = name.trim().length > 0;

        if (!nameIsValid) {
            setFormlsInvalid({ name: !nameIsValid })

            return;
        }

        if (!!edit) {
            companyCtx.putPosition(companyCtx.company.company_id, edit.position_id, name, permissions).then(() => {
                navigation.goBack()
            })
        } else {
            companyCtx.postPosition(companyCtx.company.company_id, name, permissions).then(() => {
                navigation.goBack()
            })
        }
    }

    function updateInputValueHandler(inputType, enteredValue) {
        switch (inputType) {
            case 'name':
                setName(enteredValue);
                break;
        }
    }

    function handleCheckbox(value) {
        if(permissions.includes(value))
        {
            const selectedIndex = permissions.indexOf(value)
            setPermissions([
                ...permissions.slice(0, selectedIndex),
                ...permissions.slice(selectedIndex + 1)
            ])            
        }
        else
            setPermissions([...permissions, value])
    }

    return (
        <FormContent 
            title={"Role"}
        >
           <>
                <Input
                    label="Name"
                    onChangeText={updateInputValueHandler.bind(this, 'name')}
                    value={name}
                    error={formlsInvalid.name}
                />
                <Text style={{ ...FONTS.body2 }}>
                    Permissions
                </Text>
                {
                    availablePermissions.map((item, index) => {
                        return (
                            <Checkbox.Item
                                key={`permission-${index}`}
                                label={item.charAt(0).toUpperCase() + item.slice(1)}
                                status={ permissions.includes(item) ? 'checked' : 'unchecked' } 
                                color={theme.colors.secondary}
                                onPress={() => { handleCheckbox(item) }}
                            />      
                        )
                    })
                }  
                <Button 
                    onPress={submitHandler}
                    loading={companyCtx.loading}
                >
                    {'Save'}
                </Button>
                <DialogPopup
                    visible={dialogVisible}
                    onConfirm={deleteSubmit}
                    onDismiss={() => { setDialogVisible(false) }}
                    confirmText={'Delete'}
                    onCancel={() => { setDialogVisible(false) }}
                    cancelText={'Cancel'}
                    title={'Delete role?'}
                >
                </DialogPopup>
            </>
        </FormContent>
    );

}

export default PositionScreen;
