import { useContext, useEffect, useState } from 'react';
import Toast from 'react-native-root-toast';
import AuthContent from '../components/Auth/AuthContent';

import { AuthContext } from '../store/auth-context';

function SignUpScreen() {
  const authCtx = useContext(AuthContext);

  // useEffect(() => {
  //   authCtx.setShowSplashScreen(false)
  // },[])

  async function signupHandler({ email, password }) {
    authCtx.signUp(email, password)
      .catch((error) => {
        Toast.show(error.message, {
          duration: Toast.durations.LONG,
          position: Toast.positions.BOTTOM,
        });
      })
  }

  async function federatedHandler(){
    authCtx.federatedSignIn('Google');
  }

  return <AuthContent 
    onAuthenticate={signupHandler} 
    onFederatedSignIn={federatedHandler}
  />;
}

export default SignUpScreen;


// {"codeDeliveryDetails": {"AttributeName": "email", "DeliveryMedium": "EMAIL", "Destination": "a***@a***"}, "user": {"Session": null, "authenticationFlowType": "USER_SRP_AUTH", "client": {"endpoint": "https://cognito-idp.eu-west-1.amazonaws.com/", "fetchOptions": [Object]}, "keyPrefix": "CognitoIdentityServiceProvider.7kshfd1tbpemujgpk99q3jkoca", "pool": {"advancedSecurityDataCollectionFlag": true, "client": [Client], "clientId": "7kshfd1tbpemujgpk99q3jkoca", "storage": [Function MemoryStorage], "userPoolId": "eu-west-1_QNVxEaFOu", "wrapRefreshSessionCallback": [Function anonymous]}, "signInUserSession": null, "storage": [Function MemoryStorage], "userDataKey": "CognitoIdentityServiceProvider.7kshfd1tbpemujgpk99q3jkoca.a@a.a.userData", "username": "a@a.a"}, "userConfirmed": false, "userSub": "84e96cad-d742-498e-9d7f-46dbc063a7b1"}