import { useContext, useState, useEffect } from 'react';
import { LogBox } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useTheme } from 'react-native-paper';

import Button from '../components/ui/Button';
import Input from '../components/ui/Input';
import FormContent from '../components/ui/FormContent';
import IconButton from '../components/ui/IconButton';

import { AuthContext } from '../store/auth-context';
import { CompanyContext } from '../store/company-context';


function ChangePasswordScreen() {
    const theme = useTheme();

    useEffect(() => {
        LogBox.ignoreLogs(['Non-serializable values were found in the navigation state']);
      }, [])

    const companyCtx = useContext(CompanyContext);
    const authCtx = useContext(AuthContext);
    const route = useRoute();


    const [enteredPassword, setEnteredPassword] = useState('');
    const [enteredConfirmPassword, setEnteredConfirmPassword] = useState('');

    const { user } = route.params;

    const [formlsInvalid, setFormlsInvalid] = useState({
        password: false,
        confirmPassword: false
    });

    function submitHandler() {
        const passwordIsValid = enteredPassword.length > 6;
        const passwordsAreEqual = enteredPassword === enteredConfirmPassword;

        if (!passwordIsValid || !passwordsAreEqual) {
            setFormlsInvalid({ password: !passwordIsValid, confirmPassword: !passwordIsValid || !passwordsAreEqual  })

            return;
        }

        authCtx.completeNewPassword(user, enteredConfirmPassword).then(() => {
            companyCtx.putEmployeeStatus(user.challengeParam.userAttributes['custom:company_id'], user["username"], "CONFIRMED")
        })
    }

    function updateInputValueHandler(inputType, enteredValue) {
        switch (inputType) {
            case 'password':
                setEnteredPassword(enteredValue);
                break;
            case 'confirmPassword':
                setEnteredConfirmPassword(enteredValue);
                break;
        }
    }

  return (
    <FormContent 
        title={"New password"}
    >
          <>
              <Input
                  label="Password"
                  onChangeText={updateInputValueHandler.bind(this, 'password')}
                  secureTextEntry
                  value={enteredPassword}
                  error={formlsInvalid.password}
              />
              <Input
                  label="Confirm Password"
                  onChangeText={updateInputValueHandler.bind(
                      this,
                      'confirmPassword'
                  )}
                  secureTextEntry
                  value={enteredConfirmPassword}
                  error={formlsInvalid.confirmPassword}
              />
            <Button 
            style={{
                fontSize: 20,
                fontWeight: 'bold',
                marginBottom: 8,
            }} 
            onPress={submitHandler}
            loading={authCtx.loading}>
                {'Save'}
            </Button>
        </>
    </FormContent>
);
}

export default ChangePasswordScreen;
