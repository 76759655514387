import AsyncStorage from '@react-native-async-storage/async-storage';
import { createContext, useContext, useEffect, useState } from 'react';
import { Auth, Hub, API } from 'aws-amplify';
import LoadingOverlay from '../components/ui/LoadingOverlay';

export const AuthenticationStatusOptions = {
  ONBOARDING: 'OnBoarding',
  USER_NOT_CONFIRMED: 'UserNotConfirmedException',
  AUTHENTICATED: 'Authenticated',
  UNAUTHENTICATED: 'Unauthenticated',
}

export const AuthContext = createContext({
  user: null,
  authenticationStatus: '',
  isAuthenticating: true,
  unverifiedAccount: { email: '', password: '' },
  loading: false,
  showSplashScreen: true,
  signIn: (email, password) => {},
  federatedSignIn: (provider) => {},
  forgotPassword: (email) => {},
  forgotPasswordSubmit: (username, code, new_password) => {},
  signOut: () => {},
  signUp: (email, password) => {},
  confirmSignUp: (code) => {},
  completeNewPassword: (user, password) => {},
  fetchAuthUser: () => {},
  postEmployeeCognito: (companyId, email) => {},
  setShowSplashScreen: (showSplashScreen) => {},
  authenticationStatusOptions: AuthenticationStatusOptions
});

function AuthContextProvider({ children }) {
  const [authenticationStatus, setAuthenticationStatus] = useState(AuthenticationStatusOptions.UNAUTHENTICATED);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [unverifiedAccount, setUnverifiedAccount] = useState({
    email: "",
    password: "",
  });
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showSplashScreen, setShowSplashScreen] = useState(true);

  async function fetchAuthUser() {
    try {
      console.log("fetchAuthUser");
      
      const fetchedUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
      Auth.currentSession()
      //console.log(fetchedUser)
      setUser(fetchedUser);

      var isRootUser = fetchedUser.attributes['custom:is_root_user']
      var companyId = fetchedUser.attributes['custom:company_id']

      console.log('isRootUser: ', isRootUser)
      console.log('companyId: ', companyId)

      if (isRootUser === undefined && companyId === undefined) {
        //It's a new user from Google auth
        //console.log('Its a new user from Google auth - RootUser undefined and Company undefined')
        setAuthenticationStatus(AuthenticationStatusOptions.ONBOARDING)
        setIsAuthenticating(false);
        return;
      } else if (isRootUser !== undefined && Boolean(isRootUser) && companyId === undefined) {
        //It's a new user from Cognito auth
        //console.log('Its a new user from Cognito auth - RootUser true and Company undefined')
        setAuthenticationStatus(AuthenticationStatusOptions.ONBOARDING)
        setIsAuthenticating(false);
        return;
      }

      setIsAuthenticating(false);
      setAuthenticationStatus(AuthenticationStatusOptions.AUTHENTICATED)
    } catch (err) {
      console.log(err)
      setIsAuthenticating(false);
      setUser(null);
    }
  }

  useEffect(() => {
    fetchAuthUser();

    const authListener = Hub.listen(
      "auth",
      async ({ payload: { event, data } }) => {
        console.log("Auth Status Changed Event: ", event);
        console.log("Auth Status Changed Data: ", data);
        switch (event) {
          case "signIn":
            await fetchAuthUser();
            break;
          case "confirmSignUp":
            //await fetchAuthUser();
            break;
          case "signOut":
            console.log('signOut')
            setUser(null);
            setUnverifiedAccount('', '');
            setAuthenticationStatus(AuthenticationStatusOptions.UNAUTHENTICATED)
            break;
          case "signIn_failure":
            if(data.code == "UserNotConfirmedException"){
              console.log("NotAuthorizedException - singIn_failure")
              setAuthenticationStatus(AuthenticationStatusOptions.USER_NOT_CONFIRMED)           
            }
            break;
          case "completeNewPassword":
            break;
          case "completeNewPassword_failure":
            break;
          case "signUp_failure":
            if (user) {
              setUser(null);
            }
            break;
          case "signUp":
            if (!data.userConfirmed){
              setAuthenticationStatus(AuthenticationStatusOptions.USER_NOT_CONFIRMED)
            }

            break;
          case "parsingCallbackUrl":
            break;
          case "codeFlow":
            break;
          case "updateUserAttributes":
            break;
          case "cognitoHostedUI":
            break;
          case "forgotPassword":
            break;
          case "forgotPasswordSubmit":
            break;
          case "forgotPasswordSubmit_failure":
            break;
          case "forgotPassword_failure":
            break;
          case "tokenRefresh":
            Auth.currentSession()
            break;
          case "oAuthSignOut":
            break;
          default:
            console.log("Default authListener")
            await fetchAuthUser();
        }
      }
    );

    // cleanup
    return () => {
      authListener();
    };
  }, []);


  async function signUp(email, password) {
    setLoading(true)

    await Auth.signUp({
      username: email,
      password: password,
      autoSignIn: { 
        email: email,
      },
      attributes: {
        'custom:is_root_user': 'true'
      }
    })
    .finally(() => {
      setLoading(false)
    });
    setUnverifiedAccount({ email, password });
  }

  async function signIn(email, password) {
    setLoading(true)

    setUnverifiedAccount({ email, password });

    return Auth.signIn(
      email, 
      password
    ).then((user) => {
      return user;
    }).finally(() => {
      setLoading(false)
    });
  }

  async function federatedSignIn(provider) {
    await Auth.federatedSignIn({
      provider: provider,
    })
  }
    
  async function signOut(){
    await Auth.signOut();
  }

  async function confirmSignUp(code){
    setLoading(true)

    await Auth.confirmSignUp(unverifiedAccount?.email, code);
    await Auth.signIn(
      unverifiedAccount?.email,
      unverifiedAccount?.password,
    ).finally(() => {
      setLoading(false)
    });
  }

  async function completeNewPassword(user, password){
    setLoading(true)

    await Auth.completeNewPassword(
      user, 
      password
    ).finally(() => {
      setLoading(false)
    });
  }

  async function forgotPassword(email){
    await Auth.forgotPassword(email)
  }

  async function forgotPasswordSubmit(username, code, new_password) {
    await Auth.forgotPasswordSubmit(username, code, new_password)
    await Auth.signIn(
      username,
      new_password,
    );
  }

  async function postEmployeeCognito(companyId, email) {
    return API.post("uclocker", "/cognito", {
      body: {
        email: email,
        company_id: companyId,
      }
    }).then((employee) => {
      //console.log(employee)
      return employee.User
    })
  }

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const value = {
    user: user,
    isAuthenticating: isAuthenticating,
    authenticationStatus: authenticationStatus,
    loading: loading,
    showSplashScreen: showSplashScreen,
    signIn: signIn,
    forgotPassword: forgotPassword,
    forgotPasswordSubmit: forgotPasswordSubmit,
    federatedSignIn: federatedSignIn,
    signOut: signOut,
    signUp: signUp,
    confirmSignUp: confirmSignUp,
    completeNewPassword: completeNewPassword,
    fetchAuthUser: fetchAuthUser,
    postEmployeeCognito: postEmployeeCognito,
    setShowSplashScreen: setShowSplashScreen,
    authenticationStatusOptions: AuthenticationStatusOptions
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthContextProvider;
