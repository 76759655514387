import { useState } from 'react';
import { StyleSheet, View } from 'react-native';

import Button from '../ui/Button';
import Input from '../ui/Input';

function ForgotPasswordForm({ isConfirmPassword, onSubmit, credentialsInvalid }) {
  const [enteredEmail, setEnteredEmail] = useState('');
  const [enteredPassword, setEnteredPassword] = useState('');
  const [enteredConfirmPassword, setEnteredConfirmPassword] = useState('');

  const {
    email: emailIsInvalid,
    password: passwordIsInvalid,
    confirmPassword: passwordsDontMatch,
  } = credentialsInvalid;

  function updateInputValueHandler(inputType, enteredValue) {
    switch (inputType) {
      case 'email':
        setEnteredEmail(enteredValue);
        break;
      case 'password':
        setEnteredPassword(enteredValue);
        break;
      case 'confirmPassword':
        setEnteredConfirmPassword(enteredValue);
        break;
    }
  }

  function submitHandler() {
    onSubmit({
      email: enteredEmail,
      password: enteredPassword,
      confirmPassword: enteredConfirmPassword,
    });
  }

  return (
    <View style={styles.container}>
      
        {!isConfirmPassword && (<Input
          label="Email"
          placeholder="Type your email"
          onChangeText={updateInputValueHandler.bind(this, 'email')}
          value={enteredEmail}
          keyboardType="email-address"
          isInvalid={emailIsInvalid}
        />)}
        {isConfirmPassword && (
          <>
            <Input
              label="Password"
              placeholder="Type your password"
              onChangeText={updateInputValueHandler.bind(this, 'password')}
              secure
              value={enteredPassword}
              isInvalid={passwordIsInvalid}
            />
            <Input
              label="Confirm Password"
              placeholder="Type your password again"
              onChangeText={updateInputValueHandler.bind(
                this,
                'confirmPassword'
              )}
              secure
              value={enteredConfirmPassword}
              isInvalid={passwordsDontMatch}
            />
          </>
        )}
        <View style={styles.buttons}>
          <Button onPress={submitHandler}>
            {'Send email'}
          </Button>
        </View>
      
    </View>
  );
}

export default ForgotPasswordForm;


const styles = StyleSheet.create({
  container: {
    paddingTop: 25
  },
  buttonForgotPassword: {
    marginTop: 20,
    alignSelf: 'center'
  },
});
