import { useNavigation } from '@react-navigation/native';
import { useContext, useEffect, useState } from 'react';
import moment from 'moment'

import { Checkbox, Text, useTheme } from 'react-native-paper';

import Toast from 'react-native-root-toast';

import Slider from '@react-native-community/slider';
import { ScrollView, TouchableOpacity, View } from 'react-native';
import { TimePickerModal, } from 'react-native-paper-dates';

import Button from '../../components/ui/Button';
import DialogPopup from '../../components/ui/DialogPopup';
import FormContent from '../../components/ui/FormContent';
import IconButton from '../../components/ui/IconButton';
import Input from '../../components/ui/Input';

import { COLORS, FONTS, SIZES } from '../../constants/theme';
import { CompanyContext } from '../../store/company-context';
import { NavigationContext } from '../../store/navigation-context';

function ReportEditScreen({ route }) {
    const theme = useTheme();

    const navigation = useNavigation()
    const companyCtx = useContext(CompanyContext);
    const navigationCtx = useContext(NavigationContext);
    
    const [formlsInvalid, setFormlsInvalid] = useState({
        fromTime: false,
        toTime: false,
    });

    const date = route.params?.date;
    const record_id = route.params?.record_id;
    const skipApproval = Boolean(route.params?.skip_approval)
    const record_username = route.params?.record_username
    const superior_username = route.params?.superior_username

    const [record, setRecord] = useState(null);

    const [fromTime, setFromTime] = useState(record?.datetime);
    const [toTime, setToTime] = useState(record?.clock_out?.datetime);
    
    const [fromTimeVisible, setFromTimeVisible] = useState(false);
    const [toTimeVisible, setToTimeVisible] = useState(false);

    const [helperText, setHelperText] = useState('')

    useEffect(() =>{
        navigationCtx.getReportScreenRecords(date, record_id)
        .then((records) => {
            setRecord(records[0])
            setFromTime(records[0].datetime)
            setToTime(records[0].clock_out?.datetime)
        })
      }, [])

    function submitHandler() {
        const fromTimeIsValid = fromTime !== null && fromTime !== "";
        const toTimeIsValid = toTime !== null && toTime !== "";
        
        if (!fromTimeIsValid || (record?.clock_out !== undefined && !toTimeIsValid)) {
            setFormlsInvalid({ fromTime: !fromTimeIsValid, toTime: !toTimeIsValid })
            return;
        } 
        
        if (!isToAndFromValid())
            return;

        const fromTimeUtc = moment.utc(fromTime).format("YYYY-MM-DDTHH:mm:ss.SSSSSS")

        let toTimeUtc = null 
        if(record?.clock_out!== undefined) 
            toTimeUtc = moment.utc(toTime).format("YYYY-MM-DDTHH:mm:ss.SSSSSS")

        console.log(skipApproval)

        if(skipApproval)
        {
            companyCtx.putRecord(companyCtx.company.company_id, record?.record_id, fromTimeUtc, toTimeUtc).then((record) => {
                navigationCtx.updateReportScreenRecords(date, record_id, record)

                Toast.show("Clock time succesfully changed", {
                    duration: Toast.durations.LONG,
                    position: Toast.positions.BOTTOM,
                  });
                navigation.goBack()
            })
        }
        else
        {
            const employee = companyCtx.employees?.filter(e => e.username == record_username)[0]
            const superior = companyCtx.employees?.filter(e => e.username == superior_username)[0]

            const title = employee.employee_name + " " + employee.surname + " - Change record"
            const body = record.date + ": " 
            + moment.utc(record.datetime).format("HH:mm") + " > " + moment.utc(fromTime).format("HH:mm")
            + ((record?.clock_out !== undefined) ? " and " + moment.utc(record.clock_out.datetime).format("HH:mm") + " > " + moment.utc(toTime).format("HH:mm") : "")

            companyCtx.putRecordRequest(companyCtx.company.company_id, record?.record_id, fromTimeUtc, toTimeUtc, employee, superior, title, body).then((record) => {
                Toast.show("Request has been sent to your supervisor for approval", {
                    duration: Toast.durations.LONG,
                    position: Toast.positions.BOTTOM,
                  });
                navigation.goBack()
            })
        }
            
    }

    function isToAndFromValid(){
        setHelperText('')

        const dateFrom = new Date(fromTime)
        let clockIn = new Date()
        clockIn.setHours(dateFrom.getHours())
        clockIn.setMinutes(dateFrom.getMinutes())

        const dateTo = new Date(toTime)
        let clockOut = new Date()
        clockOut.setHours(dateTo.getHours())
        clockOut.setMinutes(dateTo.getMinutes())

        if(clockIn > clockOut){
            setHelperText('Clock-in time can not be after clock-out')
            return false
        }

        return true
    }

    function handleSetFromTime(hours, minutes){
        let time = new Date(fromTime)
        time.setHours(hours)
        time.setMinutes(minutes)
        setFromTime(time); 
        setFromTimeVisible(false)
    }

    function handleSetToTime(hours, minutes){
        let time = new Date(toTime)
        time.setHours(hours)
        time.setMinutes(minutes)
        setToTime(time); 
        setToTimeVisible(false)
    }

    return (
        <FormContent 
            title={"Edit record"}
        >
            <ScrollView
                bounces={false}
            >
                <View
                    style={{
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                    }}
                >
                    <View
                        style={{
                            flex: 1,
                            marginRight: SIZES.base
                        }}
                    >
                        <TouchableOpacity
                            onPressIn={() => { setFromTimeVisible(true) }} //Works for Android
                        >
                            <Input
                                label="Clock-in"
                                onTouchStart={() => { setFromTimeVisible(true) }} //Works for iOS
                                value={new Date(fromTime).getHours().toString().padStart(2, '0') + ":" + new Date(fromTime).getMinutes().toString().padStart(2, '0')}
                                error={formlsInvalid.fromTime}
                                editable={false}
                            />
                        </TouchableOpacity>
                        <TimePickerModal
                            locale={'en'}
                            visible={fromTimeVisible}
                            onDismiss={() => setFromTimeVisible(false)}
                            onConfirm={({ hours, minutes }) => { handleSetFromTime(hours, minutes) }}
                            hours={new Date(fromTime).getHours()}
                            minutes={new Date(fromTime).getMinutes()}
                            use24HourClock
                        />
                    </View>
                    {record?.clock_out &&
                        <View
                            style={{
                                flex: 1,
                                marginLeft: SIZES.base
                            }}
                        >
                            <TouchableOpacity
                                onPressIn={() => { setToTimeVisible(true) }} //Works for Android
                            >
                                <Input
                                    label="Clock-out"
                                    onTouchStart={() => { setToTimeVisible(true) }}   //Works for iOS
                                    value={new Date(toTime).getHours().toString().padStart(2, '0') + ":" + new Date(toTime).getMinutes().toString().padStart(2, '0')}
                                    error={formlsInvalid.toTime}
                                    editable={false}
                                />
                            </TouchableOpacity>
                            <TimePickerModal
                                locale={'en'}
                                visible={toTimeVisible}
                                onDismiss={() => setToTimeVisible(false)}
                                onConfirm={({ hours, minutes }) => { handleSetToTime(hours, minutes) }}
                                hours={new Date(toTime).getHours()}
                                minutes={new Date(toTime).getMinutes()}
                                use24HourClock
                            />
                        </View>
                    }
                </View>
               
                <Button 
                    onPress={submitHandler}
                    loading={companyCtx.loading}
                    helperText={helperText}
                    helperTextType='error'
                >
                    {'Save'}
                </Button>
               
            </ScrollView>
        </FormContent>
    );

}

export default ReportEditScreen;
