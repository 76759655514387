import { useContext, useState } from 'react';
import { Alert } from 'react-native';
import { useNavigation } from '@react-navigation/native';

import ForgotPasswordContent from '../components/Auth/ForgotPasswordContent';
import { AuthContext } from '../store/auth-context';

function ForgotPasswordConfirmScreen({ route }) {
  const authCtx = useContext(AuthContext);
  const navigation = useNavigation();

  async function submitHandler({ password }){
    try {
      await authCtx.forgotPasswordSubmit(
        route.params.email,
        route.params.code,
        password
      )
    } catch (error) {
      if(error.code == 'ExpiredCodeException')
      {
        Alert.alert(
          'Your link has expired',
          'Please request a new code again.'
        )
        navigation.replace('SignIn')
      }
    }
      
  }
    
  return <ForgotPasswordContent 
    isConfirmPassword
    onSubmit={submitHandler} 
  />;
}

export default ForgotPasswordConfirmScreen;