import { useState, useContext, useEffect } from 'react';
import { KeyboardAvoidingView, Platform, ScrollView, View, Text } from 'react-native';
import { useNavigation } from '@react-navigation/native';

import * as Animatable from 'react-native-animatable';
import moment from 'moment'

import { useTheme, List, Avatar } from 'react-native-paper';

import EmptyList from '../components/ui/EmptyList'

import { AuthContext } from '../store/auth-context'
import { UserContext } from '../store/user-context'
import { COLORS, FONTS, SIZES } from '../constants/theme';

import IconButton from '../components/ui/IconButton';

function RequestScreen() {
  const theme = useTheme();

  const authCtx = useContext(AuthContext);
  const userCtx = useContext(UserContext);

  useEffect(() => {
    const companyId = authCtx.user.attributes['custom:company_id']
    const username = authCtx.user?.attributes.sub

    const messageIds = userCtx.messages.filter(m => m.seen == false && m.type === 'request').map(m => m.message_id)
    console.log(messageIds)
    if(messageIds.length > 0)
      userCtx.putMessagesSeen(companyId, messageIds, true)
      .then(() => {
        userCtx.messages.map(m => m.seen = true)
      })
  }, [])

  function handleMessageRequest(messageId, taskToken, message_status) {
    userCtx.putMessageRequest(messageId, taskToken, message_status);

    userCtx.setMessages(userCtx.messages.map(m => m.message_id === messageId ? { ...m, message_status: message_status } : m)) 
  }

  return (
    <>
      {userCtx.messages?.length > 0 ?
        <ScrollView
          bounces={false}
          style={{
            flex: 1,
            backgroundColor: theme.colors.background
          }}>

          {
            userCtx.messages.filter(m => m.type === 'request').sort((a, b) => a.datetime < b.datetime ? 1 : -1).map((request, index) => {
              return (
                <List.Item
                  key={`item-${index}`}
                  title={request.message.title}
                  description={
                    request.message.body 
                  }
                  titleStyle={{ ...FONTS.h3 }}
                  descriptionStyle={{ ...FONTS.h4 }}
                  style={{
                    backgroundColor: request.seen ? theme.colors.background :  theme.colors.surface,
                  }}
                  right={props =>
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginRight: -15,
                        minWidth: 100,
                      }}
                    >
                      {request.message_status == "pending" ?
                        <>
                          <IconButton
                            icon="close-circle-outline"
                            color={theme.colors.onSurface}
                            size={28}
                            onPress={() => { handleMessageRequest(request.message_id, request.message.task_token, "denied"); }}
                          />
                          <IconButton
                            icon="checkmark-circle-sharp"
                            color={theme.colors.primary}
                            size={35}
                            onPress={() => { handleMessageRequest(request.message_id, request.message.task_token, "approved") }}
                          />
                        </>
                        :
                        <Text style={{ color: request.message_status == "approved" ? COLORS.green : COLORS.red, ...FONTS.h3 }}>
                          {request.message_status == "approved" ? "Approved" : "Denied"}
                        </Text>

                      }
                  </View>}
                />
              )
            })
          }
        </ScrollView>
        :
        <EmptyList
          source={require('../assets/empty-box.json')}
          title="No notifications"
        />
      }
    </>
  );
}

export default RequestScreen;