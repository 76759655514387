import { useContext, useEffect,  useState } from 'react';
import { useTheme } from 'react-native-paper';
import { StyleSheet, Text, View, Image, ImageBackground, TouchableOpacity,FlatList, ScrollView } from 'react-native';
import { Ionicons } from '@expo/vector-icons'

import { COLORS, SIZES, FONTS } from '../../constants/theme'

function AlertCard({ customContainerStyle, onPress }) {
    const theme = useTheme();

    return (
        <TouchableOpacity
            style={{
                flexDirection: 'row',
                alignItems: 'center',
                marginHorizontal: SIZES.padding,
                marginBottom: SIZES.padding,
                paddingVertical: SIZES.padding,
                paddingHorizontal: SIZES.radius,
                backgroundColor: COLORS.white,
                borderRadius: SIZES.radius,
                backgroundColor: theme.colors.surfaceVariant,
                ...customContainerStyle,
            }}
            onPress={onPress}
        >
            {/* TODO: Replace it for an image */}
             <Ionicons 
                name="notifications" 
                size={30}
                height={30}
                color={theme.colors.primary}
              /> 
            <View style={{ flex: 1, marginLeft: SIZES.radius }}>
                <Text style={{ color: theme.colors.onSurfaceVariant, ...FONTS.h3 }}>Set clock in/out Alert</Text>
                <Text style={{ color: theme.colors.onSurfaceVariant, ...FONTS.body4 }}>Get notified when is time to clock in/out</Text>
            </View>

            {/* TODO: Replace it for an image */}
            <Ionicons 
                name="chevron-forward" 
                size={30}
                height={30}
                color={theme.colors.primary}
              /> 
        </TouchableOpacity>
    )
}

export default AlertCard;