import { useNavigation } from '@react-navigation/native';
import { useContext, useEffect, useState } from 'react';

import { Checkbox, Text, useTheme } from 'react-native-paper';


import Slider from '@react-native-community/slider';
import { ScrollView, TouchableOpacity, View } from 'react-native';
import { TimePickerModal, } from 'react-native-paper-dates';

import Button from '../../components/ui/Button';
import DialogPopup from '../../components/ui/DialogPopup';
import FormContent from '../../components/ui/FormContent';
import IconButton from '../../components/ui/IconButton';
import Input from '../../components/ui/Input';

import { COLORS, FONTS, SIZES } from '../../constants/theme';
import { CompanyContext } from '../../store/company-context';

function ShiftScreen({ route }) {
    const theme = useTheme();

    const companyCtx = useContext(CompanyContext);
    const navigation = useNavigation()

    const [formlsInvalid, setFormlsInvalid] = useState({
        name: false,
        fromTime: false,
        toTime: false,
        days: false,
    });

    const edit = route.params?.edit;
    const [name, setName] = useState(edit !== undefined ? edit.shift_name : '');
    const [fromTime, setFromTime] = useState(edit !== undefined && edit.shift_time !== undefined ? edit.shift_time.from : new Date().setHours(9, 0));
    const [toTime, setToTime] = useState(edit !== undefined && edit.shift_time !== undefined ? edit.shift_time.to : new Date().setHours(18, 0));
    const [days, setDays] = useState(edit !== undefined ? edit.shift_days : []);
    const availableDays = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
    const [restrictionClockIn, setRestrictionClockIn] = useState(
        edit !== undefined && edit.shift_options !== undefined ? 
            edit.shift_options.restriction.clock_in : 
            {
                enabled: false,
                minutes: 5
            }
    );
    const [fromTimeVisible, setFromTimeVisible] = useState(false);
    const [toTimeVisible, setToTimeVisible] = useState(false);

    const [dialogVisible, setDialogVisible] = useState(false);

    useEffect(() => {
        if (edit !== undefined)
            navigation.setOptions({
                headerRight: () => (
                <IconButton
                    icon="trash"
                    color={COLORS.white}
                    size={24}
                    onPress={() => { setDialogVisible(true) }}
                />
                ),
            });
      }, [navigation]);

    function deleteSubmit() {
        companyCtx.deleteShift(companyCtx.company.company_id, edit.shift_id, edit.shift_name)
        navigation.goBack()
    }

    function submitHandler() {
        const nameIsValid = name.trim().length > 0;
        const daysIsValid = days.length > 0;
        const fromTimeIsValid = fromTime !== null && fromTime !== "";
        const toTimeIsValid = toTime !== null && toTime !== "";

        if (!nameIsValid || !daysIsValid || !fromTimeIsValid || !toTimeIsValid) {
            setFormlsInvalid({ name: !nameIsValid, days: !daysIsValid, fromTime: !fromTimeIsValid, toTime: !toTimeIsValid })

            return;
        } 

        console.log(restrictionClockIn)

        if (!!edit) {
            companyCtx.putShift(companyCtx.company.company_id, edit.shift_id, name, fromTime, toTime, days, restrictionClockIn.enabled, restrictionClockIn.minutes).then(() => {
                navigation.goBack()
            })
        } else {
            companyCtx.postShift(companyCtx.company.company_id, name, fromTime, toTime, days, restrictionClockIn.enabled, restrictionClockIn.minutes).then(() => {
                navigation.goBack()
            })
        }
    }

    function updateInputValueHandler(inputType, enteredValue) {
        switch (inputType) {
            case 'name':
                setName(enteredValue);
                break;
        }
    }

    function handleCheckbox(value) {
        if(days.includes(value))
        {
            const selectedIndex = days.indexOf(value)
            setDays([
                ...days.slice(0, selectedIndex),
                ...days.slice(selectedIndex + 1)
            ])            
        }
        else
            setDays([...days, value])
    }

    return (
        <FormContent 
            title={"Shift"}
        >
            <ScrollView
                bounces={false}
            >
                <Input
                    label="Name"
                    onChangeText={updateInputValueHandler.bind(this, 'name')}
                    value={name}
                    error={formlsInvalid.name}
                />
                <View
                    style={{
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                    }}
                >
                    <View
                        style={{
                            flex: 1,
                            marginRight: SIZES.base
                        }}
                    >
                        <TouchableOpacity
                            onPressIn={() => { setFromTimeVisible(true) }} //Works for Android
                        >
                            <Input
                                label="From"
                                onTouchStart={() => { setFromTimeVisible(true) }} //Works for iOS
                                value={new Date(fromTime).getHours().toString().padStart(2, '0') + ":" + new Date(fromTime).getMinutes().toString().padStart(2, '0')}
                                error={formlsInvalid.fromTime}
                                editable={false}
                            />
                        </TouchableOpacity>
                        <TimePickerModal
                            locale={'en'}
                            visible={fromTimeVisible}
                            onDismiss={() => setFromTimeVisible(false)}
                            onConfirm={({ hours, minutes }) => { setFromTime(new Date().setHours(hours, minutes)); setFromTimeVisible(false) }}
                            hours={new Date(fromTime).getHours()}
                            minutes={new Date(fromTime).getMinutes()}
                            use24HourClock
                        />
                    </View>
                    <View
                        style={{
                            flex: 1,
                            marginLeft: SIZES.base
                        }}
                    >
                        <TouchableOpacity
                            onPressIn={() => { setToTimeVisible(true) }} //Works for Android
                        >
                            <Input
                                label="To"
                                onTouchStart={() => { setToTimeVisible(true) }}   //Works for iOS
                                value={new Date(toTime).getHours().toString().padStart(2, '0') + ":" + new Date(toTime).getMinutes().toString().padStart(2, '0')}
                                error={formlsInvalid.toTime}
                                editable={false}
                            />
                        </TouchableOpacity>
                        <TimePickerModal
                            locale={'en'}
                            visible={toTimeVisible}
                            onDismiss={() => setToTimeVisible(false)}
                            onConfirm={({ hours, minutes }) => { setToTime(new Date().setHours(hours, minutes)); setToTimeVisible(false) }}
                            hours={new Date(toTime).getHours()}
                            minutes={new Date(toTime).getMinutes()}
                            use24HourClock
                        />
                    </View>
                </View>
                <Text style={{ color: theme.colors.onBackground,  ...FONTS.body2 }}>
                    Days
                </Text>
                {
                    availableDays.map((item, index) => {
                        return (
                            <Checkbox.Item
                                key={`days-${index}`}
                                label={item.charAt(0).toUpperCase() + item.slice(1)}
                                status={ days.includes(item) ? 'checked' : 'unchecked' } 
                                color={theme.colors.secondary}
                                onPress={() => { handleCheckbox(item) }}
                            />      
                        )
                    })
                }  
                {/* <Text style={{ ...FONTS.body2 }}>
                    Options
                </Text> */}
                {/* <Checkbox.Item
                    label={'Restrict clock-in/out'}
                    status={ restrictionClockIn.enabled ? 'checked' : 'unchecked' } 
                    color={theme.colors.secondary}
                    onPress={() => { setRestrictionClockIn({ ...restrictionClockIn, enabled: !restrictionClockIn.enabled }) } }
                />   
                <Text style={{ ...FONTS.body3, marginTop: SIZES.base }}>
                    How early can employees clock-in?
                </Text>
                <Text style={{ ...FONTS.body3, marginTop: SIZES.base, alignSelf: 'center' }}>
                    {restrictionClockIn.minutes}
                </Text>
                <Slider
                    style={{width: '100%',  marginTop: SIZES.base, marginBottom: SIZES.base}}
                    minimumValue={5}
                    maximumValue={120}
                    step={5}
                    minimumTrackTintColor={theme.colors.primary}
                    disabled={ restrictionClockIn.enabled ? false : true } 
                    value={ restrictionClockIn.minutes }
                    onValueChange={value => { setRestrictionClockIn({ ...restrictionClockIn, minutes: value }) } } 
                />    */}
                
                <Button 
                    onPress={submitHandler}
                    loading={companyCtx.loading}
                >
                    {'Save'}
                </Button>
                <DialogPopup
                    visible={dialogVisible}
                    onConfirm={deleteSubmit}
                    onDismiss={() => { setDialogVisible(false) }}
                    confirmText={'Delete'}
                    onCancel={() => { setDialogVisible(false) }}
                    cancelText={'Cancel'}
                    title={'Delete shift?'}
                >
                </DialogPopup>
            </ScrollView>
        </FormContent>
    );

}

export default ShiftScreen;
