import { useContext, useState, useEffect, useRef } from 'react';
import { Avatar, useTheme, Checkbox, List } from 'react-native-paper';

import { StyleSheet, Text, View } from 'react-native';
import { AuthContext } from '../store/auth-context';
import { ThemeContext } from '../store/theme-context';
import { UserContext } from '../store/user-context';
import QRCode from 'react-native-qrcode-svg';
import SelfieModal from '../components/ui/SelfieModal';

import { Camera, CameraType } from 'expo-camera';
import * as FaceDetector from 'expo-face-detector';

import { Switch } from 'react-native-paper';
import FormContent from '../components/ui/FormContent';
import { Ionicons } from '@expo/vector-icons';

import { FONTS, SIZES } from '../constants/theme';

function SelfieScreen() {
  const theme = useTheme();

  const authCtx = useContext(AuthContext);
  const themeCtx = useContext(ThemeContext);
  const userCtx = useContext(UserContext);

  const cameraRef = useRef();

  const [hasCameraPermission, setHasCameraPermission] = useState(null);
  const [facesDetected, setFacesDetected] = useState([])

  useEffect(() => {
    (async () => {
      const { status } = await Camera.requestCameraPermissionsAsync();
      setHasCameraPermission(status === 'granted');
    })();
  }, [])

  function handleFacesDetected(faceResult){
    console.log(faceResult)
    setFacesDetected(faceResult.faces)
}

  return (
    <View
      style={{
        marginTop: SIZES.padding * 5,
        marginHorizontal: SIZES.padding,
        borderRadius: SIZES.radius,
        overflow: 'hidden',
        flex: 1,
        // FOR FACE DETECTION
        //borderWidth: 7,
        //borderColor: facesDetected.length > 0 ? theme.colors.success : theme.colors.error
      }}
    >

      {hasCameraPermission ?
        <Camera
          // FOR FACE DETECTION
          onFacesDetected={handleFacesDetected}
          faceDetectorSettings={{
              mode: FaceDetector.FaceDetectorMode.fast,
              detectLandmarks: FaceDetector.FaceDetectorLandmarks.none,
              runClassifications: FaceDetector.FaceDetectorClassifications.none,
              minDetectionInterval: 200,
              tracking: false,
          }}
          type={
            Camera.Constants.Type.front
          }
          playSoundOnCapture={true}
          useCamera2Api={true}
          ref={cameraRef}
          style={{
            flex: 1,
            borderRadius: SIZES.radius
          }}
        >
        </Camera>
        :
        <Text>
          {'You need permission to use the camera'}
        </Text>
      }
    </View>
  );
}

export default SelfieScreen;

const styles = StyleSheet.create({
  rootContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 32,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 8,
  },
});
