import { useState, useContext, useEffect, createRef, forwardRef, useRef } from 'react';
import { KeyboardAvoidingView, Platform, ScrollView, View, Image } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import Moment from 'react-moment';
import MomentTZ from 'moment-timezone';
import moment from 'moment'
import { Storage } from 'aws-amplify';

import { NavigationContext } from '../../store/navigation-context';
import { AuthContext } from '../../store/auth-context';
import { CompanyContext } from '../../store/company-context';

import { useTheme, List, Text, DataTable } from 'react-native-paper';

import FormContent from '../../components/ui/FormContent';
import IconButton from '../../components/ui/IconButton';

import MapView, {  Marker, Circle, PROVIDER_GOOGLE } from 'react-native-maps';

import { COLORS, SIZES, FONTS } from '../../constants/theme'


function ReportDetailScreen({ route }) {
   //0 in location, 1 not in location, 2 no restriction

  const theme = useTheme();
  const navigation = useNavigation()

  const navigationCtx = useContext(NavigationContext);
  const authCtx = useContext(AuthContext);
  const companyCtx = useContext(CompanyContext);

  const date = route.params?.date;
  const record_id = route.params?.record_id;
  const record_username = route.params?.username;

  const [record, setRecord] = useState(null);
  
  const [photoClockIn, setPhotoClockIn] = useState(null);
  const [photoClockOut, setPhotoClockOut] = useState(null);

  useEffect(() =>{
    navigationCtx.getReportScreenRecords(date, record_id)
    .then((records) => {
      setRecord(records[0])
    })
  }, [navigationCtx.reportScreenRecords])

  useEffect(() => {
    if (record !== null) {
      Storage.get(record.record_id + ".jpg", { level: 'protected' })
        .then((imgUrl) => {
          setPhotoClockIn(imgUrl)
        })
      if (record.clock_out) {
        Storage.get(record.clock_out.record_id + ".jpg", { level: 'protected' })
          .then((imgUrl) => {
            setPhotoClockOut(imgUrl)
          })
      }
    }
  }, [])

  useEffect(() => {
    const username = authCtx.user?.attributes.sub
    const isRootUser = authCtx.user?.attributes['custom:is_root_user']
    const auth_employee = companyCtx.employees?.filter(e => e.username == username)[0]
    const record_employee = companyCtx.employees?.filter(e => e.username == record_username)[0]

    const skipApproval = isRootUser !== undefined && Boolean(isRootUser) //Authenticated user is Root
                        || record_employee?.superior_username === username  //Authenticated user is Supervisor of the owner of the record 
                        || (record_username === username && !auth_employee.superior_username) //Authenticated user editing own record and has no supervisor

    const superior_username = auth_employee.superior_username

    console.log("Skip Approval " + skipApproval)
    console.log("Superior " + superior_username)

    navigation.setOptions({
      headerRight: () => (
        <IconButton
          icon="pencil"
          color={COLORS.white}
          size={24}
          onPress={() => { navigation.navigate("ReportEdit", { date: date, record_id: record_id, skip_approval: skipApproval, record_username: record_username, superior_username: superior_username }) }}
        />
      ),
    });
  }, [navigation]);

  return (
    <FormContent
            title={"Report detail"}
    >
      <Text style={{ color: theme.colors.onBackground, ...FONTS.h2 }}>
        <Moment format="DD/MM/YYYY" element={Text}>{record?.date}
        </Moment>
      </Text>
      <Text style={{ fontWeight: "bold", color: theme.colors.onBackground, marginTop: SIZES.base, ...FONTS.h2 }}>
        {record?.employee.employee_name + ' ' + record?.employee.surname}
      </Text>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <View>
          <Text style={{ color: theme.colors.onBackground, marginTop: SIZES.padding, ...FONTS.h3 }}>
            Clock-in
          </Text>
          <Moment
            style={{ fontWeight: "bold", color: theme.colors.onBackground, marginTop: SIZES.base, ...FONTS.h3 }}
            format="HH:mm" element={Text}>{MomentTZ.tz(record?.datetime, 'UTC')}
          </Moment>
        </View>
        {record?.clock_out &&
          <>
            <View>
              <Text style={{ color: theme.colors.onBackground, marginTop: SIZES.padding, ...FONTS.h3 }}>
                Clock-out
              </Text>
              <Moment
                style={{ fontWeight: "bold", color: theme.colors.onBackground, marginTop: SIZES.base, ...FONTS.h3 }}
                format="HH:mm" element={Text}>{MomentTZ.tz(record?.clock_out.datetime, 'UTC')}
              </Moment>
            </View>
            <View>
              <Text style={{ color: theme.colors.onBackground, marginTop: SIZES.padding, ...FONTS.h3 }}>
                Duration
              </Text>
              <Text style={{ fontWeight: "bold", color: theme.colors.onBackground, marginTop: SIZES.base, ...FONTS.h3 }}>
                {moment.duration(record?.record_duration, 'seconds').format('HH[h]mm[m]')}
              </Text>
            </View>
          </>
        }
      </View>
      <Text style={{ marginTop: SIZES.padding, color: theme.colors.onBackground, ...FONTS.h3 }}>
        Clock-in location
      </Text>
      {record?.location.status == 0 ?
        <>
          <Text style={{ fontWeight: "bold", marginTop: SIZES.base, color: theme.colors.onBackground, ...FONTS.h3 }}>
            {record?.location.location.location_name}
          </Text>
        </>
        :
        <Text style={{ fontWeight: "bold", marginTop: SIZES.base, color: theme.colors.onBackground, ...FONTS.h3 }}>
            {record?.location.status == 2 ? "No restriction" : "Not in location"}
        </Text>
      }
      <View
        style={{
          marginTop: SIZES.padding,
          flexDirection: 'row',
          justifyContent: 'space-between',
          height: 180
        }}
      >
        <View
          style={{
            flex: 1.2,
            borderRadius: SIZES.radius,
            marginRight: SIZES.base,
            overflow: 'hidden',
          }}
        >
          {record &&
            <MapView
              region={{
                latitude: parseFloat(record?.location.coords.latitude),
                longitude: parseFloat(record?.location.coords.longitude),
                latitudeDelta: 0.003,
                longitudeDelta: 0.003,
              }}
              zoomControlEnabled={false}
              scrollEnabled={false}
              rotateEnabled={false}
              zoomTapEnabled={false}
              showsIndoors={false}
              latitude={parseFloat(record?.location.coords.latitude)}
              longitude={parseFloat(record?.location.coords.longitude)}
              provider={PROVIDER_GOOGLE}
              style={{
                width: '100%',
                height: '100%',
              }}
            >
              <Marker
                title={"Location"}
                coordinate={{
                  latitude: parseFloat(record?.location.coords.latitude),
                  longitude: parseFloat(record?.location.coords.longitude),
                }}
              ></Marker>
              {record?.location.location &&
                <Circle
                  center={{
                    latitude: parseFloat(record?.location.location.latitude),
                    longitude: parseFloat(record?.location.location.longitude),
                  }}
                  radius={parseFloat(record?.location.location.radius)}
                  fillColor={theme.colors.primary + "90"}
                >
                </Circle>
              }
            </MapView>
          }

        </View>
        <View
          style={{
            flex: 1,
            borderRadius: SIZES.radius,
            overflow: 'hidden',
          }}
        >
          <Image
            style={{ height: '100%', width: '100%', borderRadius: SIZES.radius, }}
            source={{ uri: photoClockIn }}
          />
        </View>
      </View>
      {record?.clock_out &&
        <View
          style={{
            marginTop: SIZES.padding,
          }}
        >
          <Text style={{ color: theme.colors.onBackground, ...FONTS.h3 }}>
            Clock-out location
          </Text>
          {record?.clock_out.location.status == 0 ?
            <>
              <Text style={{ fontWeight: "bold", marginTop: SIZES.base, color: theme.colors.onBackground, ...FONTS.h3 }}>
                {record?.clock_out.location.location.location_name}
              </Text>
            </>
            :
            <Text style={{ fontWeight: "bold", marginTop: SIZES.base, color: theme.colors.onBackground, ...FONTS.h3 }}>
              {record?.clock_out.location.status == 2 ? "No restriction" : "Not in location"}
            </Text>
          }
          <View
            style={{
              marginTop: SIZES.padding,
              flexDirection: 'row',
              justifyContent: 'space-between',
              height: 180
            }}
          >
            <View
              style={{
                flex: 1.2,
                borderRadius: SIZES.radius,
                marginRight: SIZES.base,
                overflow: 'hidden',
              }}
            >
              <MapView
                region={{
                  latitude: parseFloat(record?.clock_out.location.coords.latitude),
                  longitude: parseFloat(record?.clock_out.location.coords.longitude),
                  latitudeDelta: 0.003,
                  longitudeDelta: 0.003,
                }}
                zoomControlEnabled={false}
                scrollEnabled={false}
                rotateEnabled={false}
                zoomTapEnabled={false}
                showsIndoors={false}
                latitude={parseFloat(record?.clock_out.location.coords.latitude)}
                longitude={parseFloat(record?.clock_out.location.coords.longitude)}
                provider={PROVIDER_GOOGLE}
                style={{
                  width: '100%',
                  height: '100%',
                }}
              >
                <Marker
                  title={"Location"}
                  coordinate={{
                    latitude: parseFloat(record?.clock_out.location.coords.latitude),
                    longitude: parseFloat(record?.clock_out.location.coords.longitude),
                  }}
                ></Marker>
                {record?.clock_out.location.location &&
                  <Circle
                    center={{
                      latitude: parseFloat(record?.clock_out.location.location.latitude),
                      longitude: parseFloat(record?.clock_out.location.location.longitude),
                    }}
                    radius={parseFloat(record?.clock_out.location.location.radius)}
                    fillColor={theme.colors.primary + "90"}
                  >
                  </Circle>
                }
              </MapView>
            </View>
            <View
              style={{
                flex: 1,
                borderRadius: SIZES.radius,
                overflow: 'hidden',
              }}
            >
              <Image
                style={{ height: '100%', width: '100%', borderRadius: SIZES.radius, }}
                source={{ uri: photoClockOut }}
              />
            </View>
          </View>
        </View>
      }

      {record?.record_history &&
        <View>
          <Text style={{ color: theme.colors.onBackground, marginTop: SIZES.padding, ...FONTS.h3 }}>
            History
          </Text>

          <View
            style={{
              flexDirection: 'row',
            }}
          >
            {/* CLOCK-IN */}
            <DataTable
              style={{
                flex: 1,
                //backgroundColor: 'red'
              }}
            >
              <DataTable.Header>
                <DataTable.Title>Clock-in</DataTable.Title>
              </DataTable.Header>
              {record?.record_history?.reverse().map((history, index) => {
                return (
                  <DataTable.Row
                    key={index}
                  >
                    <DataTable.Cell>
                      <Moment format="HH:mm" element={Text} style={{ color: theme.colors.onBackground }}>{MomentTZ.tz(history, 'UTC')}
                      </Moment>
                    </DataTable.Cell>
                  </DataTable.Row>
                )
              })}
            </DataTable>

            {/* CLOCK-OUT */}
            {record?.clock_out &&
              <DataTable
                style={{
                  flex: 1,
                  //backgroundColor: 'red'
                }}
              >
                <DataTable.Header>
                  <DataTable.Title>Clock-out</DataTable.Title>
                </DataTable.Header>
                {record?.clock_out?.record_history?.reverse().map((history, index) => {
                  return (
                    <DataTable.Row
                      key={index}
                    >
                      <DataTable.Cell>
                        <Moment format="HH:mm" element={Text} style={{ color: theme.colors.onBackground }}>{MomentTZ.tz(history, 'UTC')}
                        </Moment>
                      </DataTable.Cell>
                    </DataTable.Row>
                  )
                })}
              </DataTable>
            }
          </View>
        </View>
      }


    </FormContent>
  );
}

export default ReportDetailScreen;